import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): ResetPasswordState => {
  return {
    resetPasswordIsLoading: false,
    resetPasswordError: false,
    resetPasswordSuccess: false,
    resetPasswordErrorMessage: '',
  }
}

const state = getDefaultState()

const mutations: MutationTree<ResetPasswordState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setResetPasswordSuccess(state, value) {
    state.resetPasswordSuccess = value
  },
  setResetPasswordIsLoading(state, value) {
    state.resetPasswordIsLoading = value
  },
  setResetPasswordError(state, value) {
    state.resetPasswordError = value
  },
  setResetPasswordErrorMessage(state, value) {
    state.resetPasswordErrorMessage = value
  },
}

const actions: ActionTree<ResetPasswordState, RootState> = {
  callResetPassword({ commit }, payload) {
    commit('setResetPasswordIsLoading', true)
    repository.User.ResetPassword(payload)
      .then(() => {
        commit('setResetPasswordError', false)
        commit('setResetPasswordSuccess', true)
      })
      .catch((err) => {
        commit('setResetPasswordError', true)
        commit('setResetPasswordSuccess', false)
        commit('setResetPasswordErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setResetPasswordIsLoading', false)
      })
  },
}

const ResetPassword: Module<ResetPasswordState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default ResetPassword

interface ResetPasswordState {
  resetPasswordIsLoading: boolean
  resetPasswordError: boolean
  resetPasswordSuccess: boolean
  resetPasswordErrorMessage: string
}
