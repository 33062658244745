import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): ForgotPasswordState => {
  return {
    forgotPasswordIsLoading: false,
    forgotPasswordError: false,
    forgotPasswordSuccess: false,
    forgotPasswordErrorMessage: '',
  }
}

const state = getDefaultState()

const mutations: MutationTree<ForgotPasswordState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setForgotPasswordSuccess(state, value) {
    state.forgotPasswordSuccess = value
  },
  setForgotPasswordIsLoading(state, value) {
    state.forgotPasswordIsLoading = value
  },
  setForgotPasswordError(state, value) {
    state.forgotPasswordError = value
  },
  setForgotPasswordErrorMessage(state, value) {
    state.forgotPasswordErrorMessage = value
  },
}

const actions: ActionTree<ForgotPasswordState, RootState> = {
  callForgotPassword({ commit }, payload) {
    commit('setForgotPasswordIsLoading', true)
    repository.User.ForgotPassword(payload)
      .then(() => {
        commit('setForgotPasswordError', false)
        commit('setForgotPasswordSuccess', true)
        commit('setForgotPasswordErrorMessage', '')
      })
      .catch((err) => {
        commit('setForgotPasswordError', true)
        commit('setForgotPasswordSuccess', false)
        commit('setForgotPasswordErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setForgotPasswordIsLoading', false)
      })
  },
  resetForgotPasswordFlow({ commit }) {
    commit('setForgotPasswordError', false)
    commit('setForgotPasswordSuccess', false)
    commit('setForgotPasswordErrorMessage', '')
  },
}

const ForgotPassword: Module<ForgotPasswordState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default ForgotPassword

interface ForgotPasswordState {
  forgotPasswordIsLoading: boolean
  forgotPasswordError: false
  forgotPasswordSuccess: false
  forgotPasswordErrorMessage: string
}
