import httpClient from './httpClient'

export default {
  CaseArtifact: {
    EditArtifact(payload) {
      return httpClient.post('CaseArtifact/EditArtifact', payload)
    },
    DeleteArtifact(payload) {
      return httpClient.post('CaseArtifact/DeleteArtifact', payload)
    },
  },
}
