import httpClient from './httpClient'

export default {
  Device: {
    GetCustomerDevicesByLocationID(payload) {
      return httpClient.post('/Device/GetLocationDevicesInfo', payload)
    },
    GetDevicesByLocationID(payload) {
      return httpClient.post('/Device/GetDevices', payload)
    },
    GetDevicePlayback(payload) {
      return httpClient.post('/Device/GetDeviceLiveReplay', payload)
    },
    GetBookMarkedDevices(payload) {
      return httpClient.post('/Device/GetBookMarkedDevices', payload)
    },
    BookMarkDevice(payload) {
      return httpClient.post('/Device/BookMarkDevice', payload)
    },
    GetLocationDeviceTags(payload) {
      return httpClient.post('/Device/GetLocationDeviceTags', payload)
    },
    GetDevicesStatus(payload) {
      return httpClient.post('/Device/GetDevicesStatus', payload)
    },
    GetDeviceByID(payload) {
      return httpClient.post('/Device/GetDevice', payload)
    },
    RebootDevice(payload) {
      return httpClient.post('/Device/RebootDevice', payload)
    },
    ConfigDevice(payload) {
      return httpClient.post('/Device/ConfigDevice', payload)
    },
    UpdateDeviceStatus(payload) {
      return httpClient.post('/Device/UpdateDeviceStatus', payload)
    },
    UpdateNVRStatus(payload) {
      return httpClient.post('/Device/UpdateNVRStatus', payload)
    },
    UpdateVideoStreamStatus(payload, config) {
      return httpClient.post('/Device/UpdateVideoStreamStatus', payload, config)
    },
    UpdateVIGILConnectStatus(payload) {
      return httpClient.post('/Device/UpdateVIGILConnectStatus', payload)
    },
    UpdateDeviceDetailedInfo(payload) {
      return httpClient.post('/Device/UpdateDeviceDetailedInfo', payload)
    },
    UpgradeDevice(payload) {
      return httpClient.post('/Device/UpgradeDevice', payload)
    },
    SetDeviceDefaultResolution(payload) {
      return httpClient.post('/Device/SetDeviceDefaultResolution', payload)
    },
    SetDeviceBitrate(payload) {
      return httpClient.post('/Device/SetDeviceBitrate', payload)
    },
    SetDeviceFpsGop(payload) {
      return httpClient.post('/Device/SetDeviceFpsGop', payload)
    },
    SetDeviceAudioEnabled(payload) {
      return httpClient.post('/Device/SetDeviceAudioEnabled', payload)
    },
    CreateSignalingChannel(payload) {
      return httpClient.post('/Device/CreateSignalingChannel', payload)
    },
    CreateNVRSignalingChannels(payload) {
      return httpClient.post('/Device/CreateNVRSignalingChannels', payload)
    },
    ActivateDevice(payload) {
      return httpClient.post('/Device/ActivateDevice', payload)
    },
    DeactivateDevice(payload) {
      return httpClient.post('/Device/DeactivateDevice', payload)
    },
    DeleteDevice(payload) {
      return httpClient.post('/Device/DeleteDevice', payload)
    },
    ChangeDevicesPassword(payload) {
      payload.newPassword = btoa(payload.newPassword)
      return httpClient.post('/Device/ChangeDevicesPassword', payload)
    },
    UpdateSdStorageLimitDays(payload) {
      return httpClient.post('/Device/UpdateSdStorageLimitDays', payload)
    },
    GetDevicesPassword(payload) {
      return httpClient.post('/Device/GetDevicesPassword', payload)
    },
    GetDeviceVideoOnDemandSegment(payload) {
      return httpClient.post('/Device/GetDeviceVideoOnDemandSegment', payload)
    },
    GetFirmwareUpdateStatus(payload) {
      return httpClient.post('/Device/GetFirmwareUpdateStatus', payload)
    },
    GetDeviceFlipMirror(payload) {
      return httpClient.post('/Device/GetDeviceFlipMirror', payload)
    },
    GetDeviceHardwareInfo(payload) {
      return httpClient.post('/Device/GetDeviceHardwareInfo', payload)
    },
    UpdateDeviceTimezoneById(payload) {
      return httpClient.post('/Device/UpdateDeviceTimezoneId', payload)
    },
    async SetDeviceFlipMirror(payload) {
      return httpClient.post('/Device/SetDeviceFlipMirror', payload)
    },
    SetConfigDewarping(payload) {
      return httpClient.post('/Device/ConfigDewarping', payload)
    },
    UpdatePackage(payload) {
      return httpClient.post('/Device/UpdateGeneralSetting', payload)
    },
    DeviceWebRTCRestart(payload) {
      return httpClient.post('/Device/DeviceWebRTCRestart', payload)
    },
    DeviceStartPlayback(payload, config) {
      return httpClient.post('/Device/DeviceStartPlayback', payload, config)
    },
    DeviceStartSmartSearchPlayback(payload, config) {
      return httpClient.post(
        '/Device/DeviceStartSmartSearchPlayback',
        payload,
        config
      )
    },
    DeviceStopPlayback(payload, config) {
      return httpClient.post('/Device/DeviceStopPlayback', payload, config)
    },
    DevicePausePlayback(payload, config) {
      return httpClient.post('/Device/DevicePausePlayback', payload, config)
    },
    AddDevice(payload) {
      return httpClient.post('/Device/AddDevice', payload)
    },
    CheckMACAddress(payload) {
      return httpClient.post('/Device/CheckMACAddress', payload)
    },
    UpdatePlaybackSource(payload) {
      return httpClient.post('/Device/UpdatePlaybackSource', payload)
    },
    PostActivateDevice(payload) {
      return httpClient.post('/Device/PostActivateDevice', payload)
    },
    GetDeviceDefaultSetting(payload, config) {
      return httpClient.post('/Device/GetDeviceDefaultSetting', payload, config)
    },
    GetVCASetting(payload, config) {
      return httpClient.post('/Device/GetVCASetting', payload, config)
    },
    GetVCAEvents(payload) {
      return httpClient.post('/Device/GetVCAEvents', payload)
    },
    GetVCARuleTypes() {
      return httpClient.post('/Device/GetVCARuleTypes')
    },
    GetVcaRuleCustomTypes() {
      return httpClient.post('/Device/GetVcaRuleCustomTypes')
    },
    GetDeviceAdvancedSetting(payload) {
      return httpClient.post('/Device/GetDeviceAdvancedSettings', payload)
    },
    GetVCABurntInAnnotation(payload) {
      return httpClient.post('/Device/GetVCABurntInAnnotation', payload)
    },
    SetVCABurntInAnnotation(payload) {
      return httpClient.post('/Device/SetVCABurntInAnnotation', payload)
    },
    GetVCARuleObjects() {
      return httpClient.post('/Device/GetVCARuleObjects')
    },
    SetDeviceAdvancedSetting(payload) {
      return httpClient.post('/Device/SetDeviceAdvancedSettings', payload)
    },
    SetVCASetting(payload) {
      return httpClient.post('/Device/SetVCASetting', payload)
    },
    DeleteVCASetting(payload) {
      return httpClient.post('/Device/DeleteVCASetting', payload)
    },
    SetDeviceNotificationsGracePeriod(payload) {
      return httpClient.post(
        '/Device/SetDeviceNotificationsGracePeriod',
        payload
      )
    },
    SetDeviceTimezone(payload) {
      return httpClient.post('/Device/SetDeviceTimezone', payload)
    },
    GetWebRTCPeerCount(payload) {
      return httpClient.post('/Device/GetWebRTCPeerCount', payload)
    },
    FormatSDCard(payload, config) {
      return httpClient.post('/Device/FormatSDCard', payload, config)
    },
    FactoryReset(payload) {
      return httpClient.post('/Device/FactoryReset', payload)
    },
    EstimateBandWidth(payload) {
      return httpClient.post('/Device/EstimateBandWidth', payload)
    },
    GetBandWidth(payload) {
      return httpClient.post('/Device/GetBandWidth', payload)
    },
    GetNVRDetails(payload) {
      return httpClient.post('/Device/GetNVRDetails', payload)
    },
    AddNVR(payload) {
      return httpClient.post('/Device/AddNVR', payload)
    },
    ActivateNVRCameras(payload) {
      return httpClient.post('/Device/ActivateNVR', payload)
    },
    DeactivateNVRCameras(payload) {
      return httpClient.post('/Device/DeactivateNVR', payload)
    },
    DeleteNVR(payload) {
      return httpClient.post('/Device/DeleteNVR', payload)
    },
    GetNVRInfo(payload) {
      return httpClient.post('/Device/GetNVRInfo', payload)
    },
    UpdateNVRName(payload) {
      return httpClient.post('/Device/UpdateNVRName', payload)
    },
    UpdateDeviceName(payload) {
      return httpClient.post('/Device/UpdateDeviceName', payload)
    },
    RefreshNVRDetails(payload) {
      return httpClient.post('/Device/RefreshNVRDetails', payload)
    },
    GetRefreshedNVRDetails(payload) {
      return httpClient.post('/Device/GetRefreshedNVRDetails', payload)
    },
    UpdateNVRCameraStatus(payload) {
      return httpClient.post('/Device/UpdateNVRCameraStatus', payload)
    },
    GetNVRPluginDownloadURL() {
      return httpClient.post('/Device/GetNVRPluginDownloadURL')
    },
    GetDeviceNoFootage(payload) {
      return httpClient.post('/Device/GetDeviceNoFootage', payload)
    },
    GetSingleVCAEvent(payload) {
      return httpClient.post('/Device/GetVcaEvent', payload)
    },
    SetVcaRuleTemplate(payload) {
      return httpClient.post('/Device/SetVcaRuleTemplate', payload)
    },
    ApplyVcaRuleTemplate(payload) {
      return httpClient.post('/Device/ApplyVcaRuleTemplate', payload)
    },
    GetVcaRuleTemplates(payload) {
      return httpClient.post('/Device/GetVcaRuleTemplates', payload)
    },
    DeleteVcaRuleTemplate(payload) {
      return httpClient.post('/Device/DeleteVcaRuleTemplate', payload)
    },
  },
}
