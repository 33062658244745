
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'

export default defineComponent({
  name: 'InternalUserMobileNav',
  components: {
    AccountMenu,
  },
  props: {
    showMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    routeToDealers() {
      this.$router.push('/dealers')
    },
  },
})
