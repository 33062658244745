
import { defineComponent } from 'vue'
import { isMobile } from 'mobile-device-detect'
import { mapActions, mapGetters, mapState } from 'vuex'
import CCEntryForm from '../CCEntryForm.vue'
import DealerTechCC from './DealerTechCC.vue'
import ACHEntryForm from '../ACHEntryForm.vue'

export default defineComponent({
  name: 'DealerCCRequiredDialog',
  components: {
    CCEntryForm,
    DealerTechCC,
    ACHEntryForm,
  },
  data() {
    return {
      dialog: false,
      localLogoutLoading: false,
      radios: '0',
      paymentLoading: false,
    }
  },
  computed: {
    ...mapGetters('Security', [
      'dealerRequiresCC',
      'hasBankAccount',
      'hasAcceptedAllTerms',
      'isDealerUser',
      'hasSeenDealerOnboarding',
      'isDealerAdmin',
      'dealerIsBillable',
    ]),
    ...mapGetters('Environment', ['hasChargify']),
    ...mapState('Security', ['isLoggedIn', 'user']),
    ...mapState('Payment', [
      'addPaymentLoading',
      'achAuthorized',
      'isUpdatePaymentMethod',
    ]),
    isMobile() {
      return isMobile
    },
    titleText() {
      if (this.isUpdatePaymentMethod) {
        return this.$t('dealer.chargify.addPaymentToContinue')
      } else {
        return this.$t('dealer.chargify.addPaymentToContinueWelcome', [
          this.user.firstName,
        ])
      }
    },
    subTitleText() {
      if (this.user.isCardExpired) {
        return this.$t('dealer.chargify.paymentMethodIssue')
      } else {
        return this.$t('dealer.chargify.addPaymentToAccessVigil')
      }
    },
    showDealerCCPrompt() {
      return (
        this.hasChargify &&
        this.isLoggedIn &&
        this.isDealerUser &&
        this.dealerRequiresCC &&
        !this.hasBankAccount &&
        this.hasAcceptedAllTerms &&
        // removing for now, as new dealers will not see the onboarding until they navigate to /customers
        //this.hasSeenDealerOnboarding &&
        this.dealerIsBillable
      )
    },
    computedWidth() {
      if (this.isDealerAdmin) {
        return '600'
      } else {
        return '500'
      }
    },
    addPaymentDisabled() {
      return (
        this.localLogoutLoading || (!this.achAuthorized && this.radios == '1')
      )
    },
  },
  methods: {
    ...mapActions('Security', ['logout']),
    ...mapActions('Payment', ['callUpdatePaymentMethod', 'goToPaymentTab']),
    showDialog() {
      if (this.showDealerCCPrompt) {
        this.dialog = true
      } else {
        this.dialog = false
      }
    },
    closeDialog() {
      this.radios = '0'
      this.dialog = false
      this.callUpdatePaymentMethod(false)
      if (this.$route.name === 'Administration') {
        this.goToPaymentTab(true)
      } else {
        this.$router.push({ name: 'Administration', params: { tab: 2 } })
      }
    },
    changePayment() {
      if (this.radios == '0') {
        this.$refs.CCEntryFormRef?.reloadCard()
      } else if (this.radios == '1') {
        this.$refs.ACHEntryFormRef?.reloadCard()
      }
    },
    addPaymentClick() {
      // leaving for now, might need this
      //this.callUpdatePaymentMethod(false)
      if (this.radios == '0') {
        this.$refs.CCEntryFormRef.addPaymentClick()
      } else {
        this.$refs.ACHEntryFormRef.addPaymentClick()
      }
    },
    async declineAndLogout() {
      this.localLogoutLoading = true
      await this.logout()
      this.closeDialog()
      this.localLogoutLoading = false
      this.$router.push('/logout')
    },
    cancel() {
      this.callUpdatePaymentMethod(false)
      this.closeDialog()
    },
  },
  mounted() {
    this.showDialog()
  },
  watch: {
    user() {
      this.showDialog()
    },
    hasSeenDealerOnboarding() {
      this.showDialog()
    },
    showDealerCCPrompt() {
      this.showDialog()
    },
    isUpdatePaymentMethod() {
      if (this.isUpdatePaymentMethod) {
        this.dialog = true
      }
    },
  },
})
