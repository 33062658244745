import httpClient from './httpClient'

export default {
  Dealer: {
    GetUsersByCustomer(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Dealer/GetUsersByCustomer', payload)
    },
    GetUsers(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Dealer/GetUsers', payload)
    },
    GetUsersByDealer(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Dealer/GetUsersByDealer', payload)
    },
    GetDealers(payload) {
      return httpClient.post('/Dealer/GetDealers', payload)
    },
    Add(payload) {
      return httpClient.post('/Dealer/Add', payload)
    },
    Update(payload) {
      return httpClient.post('/Dealer/Update', payload)
    },
    SetPrimary(payload) {
      return httpClient.post('/Dealer/SetPrimary', payload)
    },
    GetBillingTypes() {
      return httpClient.post('/Dealer/GetBillingTypes')
    },
    UpdateBillingType(payload) {
      return httpClient.post('/Dealer/UpdateBillingType', payload)
    },
    GetDiscountTypes() {
      return httpClient.post('/Dealer/GetDiscountTypes ')
    },
    AddCard(payload) {
      return httpClient.post('/Dealer/CreatePaymentProfile', payload)
    },
    GetPaymentProfile(payload) {
      return httpClient.post('/Dealer/GetPaymentProfile', payload)
    },
    GetDealerDeviceList(payload) {
      return httpClient.post('/Dealer/StatusSummary', payload)
    },
    UpdateAdditionalBillingEmails(payload) {
      return httpClient.post('/Dealer/UpdateAdditionalBillingEmails', payload)
    },
  },
}
