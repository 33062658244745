import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): BookmarkedCamerasState => {
  return {
    bookmarkedCameras: [],
    bookmarkedCamerasIsLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<BookmarkedCamerasState, RootState> = {
  bookmarkedCameras: (state) => {
    return state.bookmarkedCameras
  },
}

const mutations: MutationTree<BookmarkedCamerasState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setBookmarkedCameras(state, value) {
    state.bookmarkedCameras = value
  },
  setBookmarkedCamerasIsLoading(state, value) {
    state.bookmarkedCamerasIsLoading = value
  },
}

const actions: ActionTree<BookmarkedCamerasState, RootState> = {
  async getBookmarkedCameras({ commit }, payload) {
    commit('setBookmarkedCamerasIsLoading', true)
    return repository.Device.GetBookMarkedDevices(payload)
      .then((res) => {
        commit('setBookmarkedCameras', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setBookmarkedCamerasIsLoading', false)
      })
  },
}
const BookmarkedCameras: Module<BookmarkedCamerasState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default BookmarkedCameras

interface BookmarkedCamerasState {
  bookmarkedCameras: BookmarkedCamera[]
  bookmarkedCamerasIsLoading: boolean
}

interface BookmarkedCamera {
  cameraId: number
}
