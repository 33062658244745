
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import {
  ENTERED_2FA_CODE,
  TWO_FACTOR_PAGE_VIEWED,
  REMEMBER_THIS_DEVICE,
} from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'TwoFactorForm',
  props: {
    enteredEmail: {
      type: String,
      required: true,
    },
    enteredPassword: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      twoFactorForm: '',
      twoFactor: '',
      rememberDevice: false,
    }
  },
  computed: {
    ...mapState('Security', [
      'twoFactorErrorMessage',
      'twoFactorSuccess',
      'twoFactorIsLoading',
      'encryptedVerificationCode',
      'user',
      'getTwoFactorCodeIsLoading',
    ]),
    verifyBtnActive() {
      if (this.twoFactor.length == 6) {
        return true
      } else {
        return false
      }
    },
    rememberDeviceLabel() {
      return this.$t('loginView.rememberDevice')
    },
  },
  methods: {
    ...mapActions('Security', [
      'callSubmitTwoFactor',
      'clearTwoFactorError',
      'getTwoFactorCode',
    ]),
    submitTwoFactor() {
      if (this.twoFactor.length == 6) {
        this.clearTwoFactorError()
        let payload = {
          RememberDevice: this.rememberDevice,
          VerificationCode: this.twoFactor,
          EmailAddress: this.enteredEmail,
          Password: this.enteredPassword,
          EncryptedVerificationCode: this.encryptedVerificationCode,
        }
        if (this.rememberDevice) {
          this.$mixpanelTrack(REMEMBER_THIS_DEVICE)
        }
        this.callSubmitTwoFactor(payload).then(() => {
          if (this.twoFactorSuccess) {
            this.$mixpanelUserTrack(ENTERED_2FA_CODE, {
              'Login Successful': true,
            })
            let redirectURL = this.$route.query.redirect
            if (redirectURL != null) {
              if (this.$route.query.view != null) {
                this.$router.push({
                  path: redirectURL.slice(1),
                  query: { view: this.$route.query.view },
                })
                return
              } else {
                this.$router.push(redirectURL)
                return
              }
            }
            if (this.user.isDealerUser) {
              this.$router.push('/dashboard')
            } else if (this.user.isInternalUser) {
              this.$router.push('/dealers')
            } else {
              this.$router.push('/dashboard')
            }
          } else {
            this.twoFactor = ''
            this.$mixpanelTrack(ENTERED_2FA_CODE, {
              'Login Successful': false,
            })
          }
        })
      }
    },
    resendCode() {
      this.clearTwoFactorError()
      this.getTwoFactorCode({
        Password: this.enteredPassword,
        EmailAddress: this.enteredEmail,
      })
    },
  },
  mounted() {
    this.$mixpanelTrack(TWO_FACTOR_PAGE_VIEWED)
  },
})
