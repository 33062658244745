import repository from '@/repository'

const getDefaultState = () => {
  return {
    addCustomerIsLoading: false,
    addCustomerError: false,
    addCustomerErrorMessage: '',
    newCustomerId: 0,
    customerListIsLoading: false,
    customerList: [],
    locationDeviceList: [],
    locationDeviceListIsLoading: false,
    deleteCustomerIsLoading: false,
    deleteCustomerSuccess: true,
    deleteCustomerErrorMessage: '',
  }
}

const state = getDefaultState()

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAddCustomerIsLoading(state, value) {
    state.addCustomerIsLoading = value
  },
  setAddCustomerError(state, value) {
    state.addCustomerError = value
  },
  setAddCustomerErrorMessage(state, value) {
    state.addCustomerErrorMessage = value
  },
  setNewCustomerId(state, value) {
    state.newCustomerId = value
  },
  setCustomersIsLoading(state, value) {
    state.customerListIsLoading = value
  },
  setCustomerList(state, value) {
    state.customerList = value
  },
  setCustomerDeviceListIsLoading(state, value) {
    state.locationDeviceListIsLoading = value
  },
  setCustomerDeviceList(state, value) {
    state.locationDeviceList = value
  },
  setDeleteCustomerIsLoading(state, value) {
    state.deleteCustomerIsLoading = value
  },
  setDeleteCustomerSuccess(state, value) {
    state.deleteCustomerSuccess = value
  },
  setDeleteCustomerErrorMessage(state, value) {
    state.deleteCustomerErrorMessage = value
  },
  deleteCustomer(state, value) {
    state.customerList = state.customerList.filter((customer) => {
      return customer.customerId != value.customerId
    })
  },
}

const actions = {
  getCustomerList({ commit }, payload) {
    commit('setCustomerList', [])
    commit('setCustomersIsLoading', true)
    return repository.Customer.GetCustomers(payload)
      .then((res) => {
        commit('setCustomerList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomersIsLoading', false)
      })
  },
  getCustomerDeviceList({ commit }, payload) {
    commit('setCustomerDeviceListIsLoading', true)
    return repository.Device.GetDevicesByCustomerID(payload)
      .then((res) => {
        commit('setCustomerDeviceList', res.data)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomerDeviceListIsLoading', false)
      })
  },
  addCustomer({ commit }, payload) {
    commit('setAddCustomerIsLoading', true)
    return repository.Customer.AddCustomer(payload)
      .then((res) => {
        commit('setNewCustomerId', res.data.locationId)
        commit('setAddCustomerError', false)
      })
      .catch((err) => {
        commit('setAddCustomerError', true)
        commit('setAddCustomerErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddCustomerIsLoading', false)
      })
  },
  async deleteCustomer({ commit }, payload) {
    commit('setDeleteCustomerIsLoading', true)
    return repository.InternalAdmin.DeleteCustomer(payload)
      .then(() => {
        commit('setDeleteCustomerSuccess', true)
        commit('deleteCustomer', payload)
      })
      .catch((err) => {
        commit(
          'setDeleteCustomerErrorMessage',
          err.response.data.messageLocalizable
        )
        commit('setDeleteCustomerSuccess', false)
      })
      .finally(() => {
        commit('setDeleteCustomerIsLoading', false)
      })
  },
  callDeleteCustomerErrorMessage({ commit }, payload) {
    commit('setDeleteCustomerErrorMessage', payload || '')
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
