import httpClient from './httpClient'

export default {
  Notification: {
    GetNotificationSubscriptions() {
      return httpClient.post('/Notification/GetNotificationSubscriptions', {})
    },
    UpdateNotificationSubscription(payload) {
      return httpClient.post(
        '/Notification/UpdateNotificationSubscription',
        payload
      )
    },
    UpdateNotificationLogRead(payload) {
      return httpClient.post('/Notification/UpdateNotificationLogRead', payload)
    },
    GetNotificationLogsPaged(payload) {
      return httpClient.post('/Notification/GetNotifications', payload)
    },
    DeleteNotification(payload) {
      return httpClient.post('/Notification/DeleteNotification', payload)
    },
    DeleteNotifications(payload) {
      return httpClient.post('/Notification/DeleteNotifications', payload)
    },
  },
}
