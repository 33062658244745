
import { defineComponent } from 'vue'
import InternalUserDesktopNav from './InternalUserDesktopNav.vue'
import InternalUserMobileNav from './InternalUserMobileNav.vue'

export default defineComponent({
  name: 'InternalUserNav',
  components: {
    InternalUserDesktopNav,
    InternalUserMobileNav,
  },
  props: {
    showMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
})
