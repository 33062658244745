import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): ConnectivityState => {
  return {
    uploadingSpeed: {},
    estimateBandWidthLoading: false,
    isTestingUpload: false,
    estimateBandWidthError: false,
    testingUploadError: false,
    uploadSpeedList: {},
    downloadSpeedList: [],
  }
}

const state = getDefaultState()

const getters: GetterTree<ConnectivityState, RootState> = {
  uploadingSpeed: (state) => {
    return state.uploadingSpeed
  },
  estimateBandWidthError: (state) => {
    return state.estimateBandWidthError
  },
  testingUploadError: (state) => {
    return state.testingUploadError
  },
  currentUploadSpeedList: (state) => (serialNumber: number) => {
    return state.uploadSpeedList[serialNumber]
      ? state.uploadSpeedList[serialNumber]
      : []
  },
  downloadSpeedList: (state) => {
    return state.downloadSpeedList
  },
}

const mutations: MutationTree<ConnectivityState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setUploadingSpeed(state, value) {
    state.uploadingSpeed = value
  },
  setEstimateBandWidthLoading(state, value) {
    state.estimateBandWidthLoading = value
  },
  setIsTestingUpload(state, value) {
    state.isTestingUpload = value
  },
  setEstimateBandWidthError(state, value) {
    state.estimateBandWidthError = value
  },
  setTestingUploadError(state, value) {
    state.testingUploadError = value
  },
  setUploadSpeedList(state, payload) {
    const serialNumber = payload.serialNumber
    state.uploadSpeedList[serialNumber] = payload.uploadSpeedList
  },
  setDownloadSpeedList(state, value) {
    state.downloadSpeedList = value
  },
}

const actions: ActionTree<ConnectivityState, RootState> = {
  updateUploadSpeedList({ commit }, payload) {
    commit('setUploadSpeedList', payload)
  },
  updateDownloadSpeedList({ commit }, payload) {
    commit('setDownloadSpeedList', payload.downloadSpeedList)
  },
  async estimateBandWidth({ commit }, payload) {
    commit('setEstimateBandWidthLoading', true)
    commit('setEstimateBandWidthError', false)
    return repository.Device.EstimateBandWidth(payload)
      .then(() => {})
      .catch(() => {
        commit('setEstimateBandWidthError', true)
      })
      .finally(() => {
        commit('setEstimateBandWidthLoading', false)
      })
  },
  async getBandWidth({ commit }, payload) {
    commit('setIsTestingUpload', true)
    commit('setTestingUploadError', false)
    return repository.Device.GetBandWidth(payload)
      .then((res) => {
        commit('setUploadingSpeed', res.data)
      })
      .catch(() => {
        commit('setTestingUploadError', true)
      })
      .finally(() => {
        commit('setIsTestingUpload', false)
      })
  },
}
const Connectivity: Module<ConnectivityState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Connectivity

interface ConnectivityState {
  uploadingSpeed: UploadingSpeed
  estimateBandWidthLoading: false
  isTestingUpload: false
  estimateBandWidthError: false
  testingUploadError: false
  uploadSpeedList: UploadSpeedList
  downloadSpeedList: any[]
}

interface UploadingSpeed {
  uploadBandwidth?: string | any
}

interface UploadSpeedList {
  serialNumber?: number[]
}
