
import { defineComponent } from 'vue'
import PrivacyPolicyComponent from '@/components/global/termsDialog/PrivacyPolicyComponent.vue'
export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    PrivacyPolicyComponent,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
