import httpClient from './httpClient'

export default {
  Timeline: {
    GetTimeline(payload, config) {
      return httpClient.post('/Timeline/GetTimeline', payload, config)
    },
    GetExistance(payload, config) {
      return httpClient.post('/Timeline/IsExist', payload, config)
    },
    GetSmartSearchTimeline(payload, config) {
      return httpClient.post(
        '/Timeline/GetSmartSearchTimeline',
        payload,
        config
      )
    },
    GetSmartSearchTimelineWithVcaEvents(payload, config) {
      return httpClient.post(
        '/Timeline/GetSmartSearchTimelineWithVcaEvents',
        payload,
        config
      )
    },
    VideoSearchMetadata(payload, config) {
      return httpClient.post('/Timeline/VideoSearchMetadata', payload, config)
    },
  },
}
