
import { defineComponent } from 'vue'
import { getNewNotifications, getAllNotifications } from '@/utils/webSockets'
import { DateTime } from 'luxon'
import { mapState, mapGetters, mapActions } from 'vuex'
import SingleNotification from './SingleNotification.vue'
import {
  CASE_COMMENT_ADDED,
  CAMERA_MOTION_DETECTED,
  DEVICE_STATUS_UPDATED,
  DIGITAL_INPUT,
  VCA_EVENT,
} from '@/constants/notificationTypes.js'

export default defineComponent({
  name: 'NotificationsIcon',
  data() {
    return {
      showNotifications: false,
      notificationsPoll: null,
      currentTime: '',
      hasShownPreviewNotifications: false,
    }
  },
  components: {
    SingleNotification,
  },
  methods: {
    ...mapActions('Notifications', [
      'callSetReadAllNotifications',
      'callSetReadNotification',
      'showNotificationsSettings',
      'showAllNotifications',
    ]),
    toggleNotificationsList() {
      this.showNotifications = !this.showNotifications
    },
    markItemAsRead(item) {
      this.callSetReadNotification(item.notificationLogId)
      const eventType = item.eventTypeId
      switch (eventType) {
        case CASE_COMMENT_ADDED:
          this.$router.push({
            name: 'Case',
            params: { id: item.eventObjectId },
          })
          break
        case CAMERA_MOTION_DETECTED:
        case DEVICE_STATUS_UPDATED:
        case DIGITAL_INPUT:
          this.$router.push({
            name: 'SingleCamera',
            params: { id: item.eventObjectId },
          })
          break
        case VCA_EVENT:
          this.$router.push({
            name: 'SingleCamera',
            params: { id: item.eventObjectId },
            query: { eventId: JSON.parse(item.notificationPayload).Id },
          })
          break
        default:
          break
      }
    },
    async markAllAsRead() {
      await this.callSetReadAllNotifications({ MarkAllAsRead: true })
      this.showNotifications = false
      if (this.$route.name === 'NotificationsList') {
        this.showAllNotifications({ viewAll: true })
      }
    },
    goToNotifications() {
      this.showNotifications = false
      if (this.$route.name != 'NotificationsList') {
        this.$router.push({
          name: 'NotificationsList',
        })
      } else {
        this.showAllNotifications({ viewAll: true })
      }
    },
    goToSettings() {
      this.showNotifications = false
      if (this.$route.name != 'Profile') {
        this.$router.push({
          name: 'Profile',
          params: {
            notificationsSettingsGeneral: true,
          },
        })
      } else {
        this.showNotificationsSettings({ general: true })
      }
    },
    loopGetNewNotifications() {
      this.notificationsPoll = setInterval(() => {
        let payload = {
          CreatedOn: this.currentTime,
          RequestUserId: this.user.userId,
        }
        getNewNotifications(payload)
        this.currentTime = DateTime.utc()
      }, 30000)
    },
    getPreviewNotifications() {
      if (this.connected && !this.hasShownPreviewNotifications) {
        this.currentTime = DateTime.utc()
        getAllNotifications({
          RequestUserId: this.user.userId,
          MaxReturnCount: 15,
        })
        this.loopGetNewNotifications()
        this.hasShownPreviewNotifications = true
      }
    },
  },
  watch: {
    connected() {
      this.getPreviewNotifications()
    },
    isLoggedIn() {
      if (!this.isLoggedIn) {
        clearInterval(this.notificationsPoll)
      }
    },
    showNotifications() {
      this.$nextTick(() => {
        this.$vuetify.goTo('.notifications-list', {
          container: '.notifications-menu',
          duration: 0,
        })
      })
    },
  },
  created() {
    this.getPreviewNotifications()
  },
  beforeDestroy() {
    clearInterval(this.notificationsPoll)
    this.hasShownPreviewNotifications = false
  },
  computed: {
    ...mapState('WebSocket', ['connected']),
    ...mapState('Security', ['isLoggedIn', 'user']),
    ...mapGetters('Security', ['display24HourTime']),
    ...mapGetters('Notifications', [
      'notificationCount',
      'notificationListTop15',
    ]),
    notificationCntText() {
      return this.notificationCount > 999 ? '999+' : this.notificationCount
    },
  },
})
