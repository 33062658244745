/* eslint-disable no-unused-vars */
export default {
  methods: {
    $mixpanelTrack(event, eventPayload) {
      //no-op
    },
    $mixpanelUserTrack(event, eventPayload) {
      //no-op
    },
  },
}
