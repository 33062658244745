import repository from '@/repository'
import * as apiEnums from '@/constants/apiEnums'

export default {
  methods: {
    $dealerViewedCameraSettings(deviceId) {
      let payload = {
        objectId: deviceId,
        objectTypeId: apiEnums.DEVICE_TYPE,
        auditTypeId: apiEnums.CAMERA_SETTINGS_VIEWED,
      }
      repository.Audit.SendAudit(payload)
    },
    $singleCameraViewed(deviceId) {
      let payload = {
        objectId: deviceId,
        objectTypeId: apiEnums.DEVICE_TYPE,
        auditTypeId: apiEnums.SINGLE_CAMERA_VIEWED,
      }
      repository.Audit.SendAudit(payload)
    },
    $cameraSettingsChanged(deviceId, eventPayload) {
      let payload = {
        objectId: deviceId,
        objectTypeId: apiEnums.DEVICE_TYPE,
        auditTypeId: apiEnums.CAMERA_SETTINGS_CHANGED,
        remarks: JSON.stringify(eventPayload),
      }
      repository.Audit.SendAudit(payload)
    },
    $videoUserEdited(userId, eventPayload) {
      let payload = {
        objectId: userId,
        objectTypeId: apiEnums.USER_TYPE,
        auditTypeId: apiEnums.EDIT_EVENT,
        remarks: JSON.stringify(eventPayload),
      }
      repository.Audit.SendAudit(payload)
    },
    $videoUserAdded(userId, eventPayload) {
      let payload = {
        objectId: userId,
        objectTypeId: apiEnums.USER_TYPE,
        auditTypeId: apiEnums.ADD_EVENT,
        remarks: JSON.stringify(eventPayload),
      }
      repository.Audit.SendAudit(payload)
    },
  },
}
