
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default defineComponent({
  name: 'ToastMessage',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('ToastMessage', [
      'showToastMessage',
      'toastType',
      'toastMessage',
    ]),
  },
  watch: {
    toastType() {
      if (this.toastType == 'green') {
        setTimeout(() => {
          if (this.toastType !== 'red') {
            this.clearToast()
          }
        }, 3500)
      } else if (this.toastType == 'red') {
        setTimeout(() => {
          this.clearToast()
        }, 3500)
      }
    },
  },
  methods: {
    ...mapMutations('ToastMessage', ['clearToast']),
    closeToast() {
      this.clearToast()
    },
  },
})
