import httpClient from './httpClient'

export default {
  Video: {
    DownloadVideoClip(payload) {
      return httpClient.post('/Video/DownloadVideoClip', payload)
    },
    GetProgress(payload) {
      return httpClient.post('/Video/GetProgress', payload)
    },
  },
}
