import { Case, CaseArtifacts, IncidentTypes } from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): CaseState => {
  return {
    case: null,
    caseIsLoading: false,
    createCaseLoading: false,
    currentCaseID: 0,
    currentCaseName: '',
    caseClipError: false,
    allCases: [],
    getAllCasesLoading: false,
    incidentTypes: [],
    recentlyViewedCases: [],
    recentlyViewedCasesLoading: false,
    locationCaseCounts: [],
    locationCaseCountsLoading: false,
    addClipToCaseLoading: false,
    viewedCase: {},
    updateCaseInfoLoading: false,
    updateCaseInfoSuccess: false,
    updateCaseInfoErrorMessage: '',
    addCommentLoading: false,
    addCommentSuccess: false,
    currentCase: { caseId: 0 },
    caseDownloading: false,
    caseDownloadingID: 0,
    caseDownloadReady: false,
    caseReadyToDownload: '',
    artifactLoading: false,
    updateArtifactIsLoading: false,
    updateArtifactError: false,
    updateArtifactErrorMessage: '',
    updateArtifactSuccess: false,
    removeArtifactIsLoading: false,
    removeArtifactErrorMessage: '',
    removeArtifactSuccess: false,
    deleteCaseIsLoading: false,
    deleteCaseErrorMessage: '',
    deleteCaseSuccess: false,
    clipCases: [],
    clipCasesIsLoading: false,
    closeCreateClipMode: false,
    sharedEmailList: [],
    shareCaseIsLoading: false,
    shareCaseError: false,
    shareCaseIsDeleting: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<CaseState, RootState> = {
  currentCase: (state) => (clipCaseId: number) => {
    const newCase = state.allCases.find((clipCase) => {
      return clipCase.caseId === clipCaseId
    })
    if (typeof newCase === 'undefined') return { caseId: 0 }
    else return newCase
  },
  isCaseErrorStatus: () => (status: string) => {
    return status == 'Error' || status == 'Message'
  },
}

const mutations: MutationTree<CaseState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCreateCaseLoading(state, value) {
    state.createCaseLoading = value
  },
  setCurrentCaseID(state, value) {
    state.currentCaseID = value
  },
  setCurrentCaseName(state, value) {
    state.currentCaseName = value
  },
  setCurrentCaseClipError(state, value) {
    state.caseClipError = value
  },
  setAllCases(state, value) {
    state.allCases = value
  },
  pushNewCase(state, value) {
    state.allCases.push(value)
  },
  setGetAllCasesLoading(state, value) {
    state.getAllCasesLoading = value
  },
  setIncidentTypes(state, value) {
    state.incidentTypes = value
  },
  setRecentlyViewedCases(state, value) {
    state.recentlyViewedCases = value
  },
  setGetRecentlyViewedCasesLoading(state, value) {
    state.recentlyViewedCasesLoading = value
  },
  setLocationCaseCounts(state, value) {
    state.locationCaseCounts = value
  },
  setLocationCaseCountsLoading(state, value) {
    state.locationCaseCountsLoading = value
  },
  setAddClipToCaseLoading(state, value) {
    state.addClipToCaseLoading = value
  },
  setViewedCase(state, value) {
    state.viewedCase = value
  },
  setUpdateCaseInfoLoading(state, value) {
    state.updateCaseInfoLoading = value
  },
  setUpdateCaseInfoSuccess(state, value) {
    state.updateCaseInfoSuccess = value
  },
  setUpdateCaseInfoErrorMessage(state, value) {
    state.updateCaseInfoErrorMessage = value
  },
  updateCaseComment(state, value) {
    state.currentCase.comments.unshift(value)
  },
  setAddCommentLoading(state, value) {
    state.addCommentLoading = value
  },
  setAddCommentSuccess(state, value) {
    state.addCommentSuccess = value
  },
  setCase(state, value) {
    state.currentCase = value
  },
  setCaseDownloading(state, value) {
    state.caseDownloading = value
  },
  setCaseDownloadingID(state, value) {
    state.caseDownloadingID = value
  },
  setCaseDownloadReady(state, value) {
    state.caseDownloadReady = value
  },
  setCaseReadyToDownload(state, value) {
    state.caseReadyToDownload = value
  },
  setAddArtifactLoading(state, value) {
    state.artifactLoading = value
  },
  setUpdateArtifactIsLoading(state, value) {
    state.updateArtifactIsLoading = value
  },
  setUpdateArtifact(state, value) {
    // update case here
    const caseArtifactID = value.ArtifactId
    const currentArtifact = state.currentCase.caseArtifacts.find(
      ({ caseArtifactId }) => caseArtifactId === caseArtifactID
    )
    currentArtifact.details = value.Details
    currentArtifact.artifactName = value.ArtifactName
  },
  setUpdateArtifactSuccess(state, value) {
    state.updateArtifactSuccess = value
  },
  setUpdateArtifactError(state, value) {
    state.updateArtifactError = value
  },
  setUpdateArtifactErrorMessage(state, value) {
    state.updateArtifactErrorMessage = value
  },
  setRemoveArtifact(state, value) {
    const caseArtifactID = value.CaseArtifactId
    const artifactIndex = state.currentCase.caseArtifacts
      .map((artifact) => {
        return artifact.caseArtifactId
      })
      .indexOf(caseArtifactID)
    state.currentCase.caseArtifacts.splice(artifactIndex, 1)
  },
  setRemoveArtifactIsLoading(state, value) {
    state.removeArtifactIsLoading = value
  },
  setRemoveArtifactErrorMessage(state, value) {
    state.removeArtifactErrorMessage = value
  },
  setRemoveArtifactSuccess(state, value) {
    state.removeArtifactSuccess = value
  },
  setDeleteCaseIsLoading(state, value) {
    state.deleteCaseIsLoading = value
  },
  setDeleteCaseErrorMessage(state, value) {
    state.deleteCaseErrorMessage = value
  },
  setDeleteCaseSuccess(state, value) {
    state.deleteCaseSuccess = value
  },
  setClipCases(state, value) {
    state.clipCases = value
  },
  setClipCasesIsLoading(state, value) {
    state.clipCasesIsLoading = value
  },
  setCloseCreateClipMode(state, value) {
    state.closeCreateClipMode = value
  },
  updateArtifactStatus(state, value) {
    const selectedArtifact = value.index
    const currentArtifact = state.currentCase.caseArtifacts[selectedArtifact]
    currentArtifact.status = value.status
  },
  updateCaseArtifact(state, value) {
    state.currentCase.caseArtifacts = state.currentCase.caseArtifacts.map(
      (item) => (item.caseArtifactId === value.caseArtifactId ? value : item)
    )
  },
  setSharedEmailList(state, value) {
    state.sharedEmailList = value
  },
  setShareCaseIsloading(state, value) {
    state.shareCaseIsLoading = value
  },
  setShareCaseError(state, value) {
    state.shareCaseError = value
  },
  removeEmailShare(state, value) {
    const newEmailList = state.sharedEmailList.filter(
      (email) => email.id != value.id
    )
    state.sharedEmailList = newEmailList
  },
  setShareCaseIsDeleting(state, value) {
    state.shareCaseIsDeleting = value
  },
  setCaseIsLoading(state, value) {
    state.caseIsLoading = value
  },
}
const actions: ActionTree<CaseState, RootState> = {
  async getCase({ commit }, payload) {
    commit('setCaseIsLoading', true)
    commit('setCase', { caseId: 0 })
    return repository.Case.GetCase(payload)
      .then((res) => {
        commit('setCase', res.data)
        commit('setViewedCase', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setCaseIsLoading', false)
      })
  },
  async monitorCaseUploadStatus({ commit, getters }, payload) {
    return repository.Case.GetCase(payload.parm)
      .then((res) => {
        const caseArtifacts = res.data.caseArtifacts
        let selectedIndex = payload.index
        caseArtifacts.forEach((item: CaseArtifacts, index: number) => {
          if (item.caseArtifactId === payload.selectedArtifactId) {
            selectedIndex = index
          }
        })
        const status = res.data.caseArtifacts[selectedIndex].status
        const isUpdateData =
          status === 'Done' || getters.isCaseErrorStatus(status)
        if (isUpdateData) {
          commit('setCase', res.data)
          commit('setViewedCase', res.data.items)
        }
      })
      .catch(() => {})
      .finally(() => {})
  },
  async retryArtifact({ commit }, payload) {
    commit('setClipCasesIsLoading', true)
    commit('updateArtifactStatus', payload)
    return repository.Case.RetryArtifact(payload.parm)
      .then((res) => {
        commit('updateCaseArtifact', res.data)
        commit('setClipCasesIsLoading', false)
      })
      .catch(() => {
        payload.status = 'Error'
        commit('updateArtifactStatus', payload)
      })
      .finally(() => {})
  },
  async getAllCases({ commit }, payload) {
    commit('setGetAllCasesLoading', true)

    return repository.Case.GetCases(payload)
      .then((res) => {
        commit('setAllCases', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setGetAllCasesLoading', false)
      })
  },
  async getClipCases({ commit }, payload) {
    commit('setClipCasesIsLoading', true)
    return repository.Case.GetCases(payload)
      .then((res) => {
        commit('setClipCases', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setClipCasesIsLoading', false)
      })
  },
  async getRecentlyViewedCases({ commit }) {
    commit('setGetRecentlyViewedCasesLoading', true)

    const payload = {}

    return repository.RecentlyViewed.GetCases(payload)
      .then((res) => {
        commit('setRecentlyViewedCases', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setGetRecentlyViewedCasesLoading', false)
      })
  },
  async createCase({ commit }, payload) {
    commit('setAddArtifactLoading', true)

    return repository.Case.CreateCase(payload)
      .then((res) => {
        commit('setCurrentCaseID', res.data.caseId)
        commit('setCurrentCaseName', res.data.caseName)
        commit('pushNewCase', res.data)
      })
      .catch(() => {
        commit('setCurrentCaseClipError', true)
        commit('setCurrentCaseID', 0)
        commit('setCurrentCaseName', '')
      })
      .finally(() => {
        commit('setAddArtifactLoading', false)
      })
  },
  async getIncidentTypes({ commit }) {
    return repository.Case.GetCaseIncidentTypes()
      .then((res) => {
        commit('setIncidentTypes', res.data.items)
      })
      .catch(() => {})
      .finally(() => {})
  },
  async getCaseCountsByLocation({ commit }, payload) {
    payload.Status = 'Open'
    commit('setLocationCaseCountsLoading', true)
    return repository.Case.GetCountsByLocation(payload)
      .then((res) => {
        commit('setLocationCaseCounts', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setLocationCaseCountsLoading', false)
      })
  },
  async addClipToCase({ commit }, payload) {
    commit('setAddArtifactLoading', true)

    return repository.Case.CreateArtifact(payload)
      .then((res) => {
        commit('setCurrentCaseID', res.data.caseId)
        commit('setCurrentCaseName', res.data.caseName)
        commit('setCurrentCaseClipError', false)
      })
      .catch(() => {
        commit('setCurrentCaseClipError', true)
        commit('setCurrentCaseID', 0)
        commit('setCurrentCaseName', '')
      })
      .finally(() => {
        commit('setAddArtifactLoading', false)
      })
  },
  async updateCase({ commit }, payload) {
    commit('setUpdateCaseInfoLoading', true)

    return repository.Case.Update(payload)
      .then((res) => {
        commit('setUpdateCaseInfoSuccess', true)
        commit('setCase', res.data)
      })
      .catch((err) => {
        commit('setUpdateCaseInfoSuccess', false)
        commit('setUpdateCaseInfoErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUpdateCaseInfoLoading', false)
      })
  },
  async addComment({ commit }, payload) {
    commit('setAddCommentLoading', true)

    return repository.Comment.Add(payload)
      .then((res) => {
        commit('setAddCommentSuccess', true)
        commit('updateCaseComment', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setAddCommentLoading', false)
      })
  },
  callDownloadCase({ commit }, payload) {
    commit('setCaseDownloadingID', payload)
    commit('setCaseDownloading', true)
  },
  callCancelDownload({ commit }) {
    commit('setCaseDownloadingID', 0)
    commit('setCaseDownloading', false)
  },
  clickDownloadCase({ commit }) {
    commit('setCaseDownloading', false)
  },
  clickCancelDownload({ commit }) {
    commit('setCaseDownloading', false)
    commit('setCaseDownloadingID', 0)
  },
  callDownloadReady({ commit }, payload) {
    // find some state here to take in payload and feed into DownloadBanner to auto-download
    commit('setDownloadReady', true)
    // check payload here, find right object to put in string name
    commit('setCaseReadyToDownload', payload.fileName)
    commit('setCaseDownloading', false)
  },
  async callUpdateArtifact({ commit }, payload) {
    commit('setUpdateArtifactIsLoading', true)
    return repository.CaseArtifact.EditArtifact(payload)
      .then(() => {
        commit('setUpdateArtifact', payload)
        commit('setUpdateArtifactSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateArtifactError', true)
        commit('setUpdateArtifactErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUpdateArtifactIsLoading', false)
      })
  },
  async callRemoveArtifact({ commit }, payload) {
    commit('setRemoveArtifactIsLoading', true)
    return repository.CaseArtifact.DeleteArtifact(payload)
      .then(() => {
        commit('setRemoveArtifact', payload)
        commit('setRemoveArtifactSuccess', true)
      })
      .catch((err) => {
        commit('setRemoveArtifactSuccess', false)
        commit('setRemoveArtifactErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setRemoveArtifactIsLoading', false)
      })
  },
  async callDeleteCase({ commit }, payload) {
    commit('setDeleteCaseIsLoading', true)
    return repository.Case.DeleteCase(payload)
      .then(() => {
        commit('setDeleteCaseSuccess', true)
      })
      .catch((err) => {
        commit('setDeleteCaseSuccess', false)
        commit('setDeleteCaseErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setDeleteCaseIsLoading', false)
      })
  },
  clearRemoveArtifactError({ commit }) {
    commit('setRemoveArtifactErrorMessage', '')
    commit('setRemoveArtifactSuccess', false)
  },
  callCloseCreateClipMode({ commit }, payload) {
    commit('setCloseCreateClipMode', payload)
  },
  async createUpdateCaseShare({ commit }, payload) {
    commit('setShareCaseIsloading', true)
    commit('setShareCaseError', false)
    return repository.Case.CreateOrUpdateCaseShare(payload)
      .then((res) => {
        commit('setSharedEmailList', res.data)
      })
      .catch(() => {
        commit('setShareCaseError', true)
      })
      .finally(() => {
        commit('setShareCaseIsloading', false)
      })
  },
  async removeShare({ commit }, payload) {
    commit('setShareCaseIsDeleting', true)
    commit('setShareCaseError', false)
    return repository.Case.DeleteCaseShare(payload)
      .then(() => {
        commit('removeEmailShare', payload)
      })
      .catch(() => {
        commit('setShareCaseError', true)
      })
      .finally(() => {
        commit('setShareCaseIsDeleting', false)
      })
  },
  async getExternalUserShares({ commit }, payload) {
    return repository.Case.GetExternalUserByCase(payload).then((res) => {
      commit('setSharedEmailList', res.data)
    })
  },
}

const Case: Module<CaseState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Case

interface CaseState {
  case: Case
  createCaseLoading: boolean
  currentCaseID: number
  currentCaseName: string
  caseClipError: boolean
  allCases: Case[]
  getAllCasesLoading: boolean
  incidentTypes: IncidentTypes[]
  recentlyViewedCases: Case[]
  recentlyViewedCasesLoading: boolean
  locationCaseCounts: []
  locationCaseCountsLoading: boolean
  addClipToCaseLoading: boolean
  viewedCase: any
  updateCaseInfoLoading: boolean
  updateCaseInfoSuccess: boolean
  updateCaseInfoErrorMessage: string
  addCommentLoading: boolean
  addCommentSuccess: boolean
  currentCase: Case
  caseDownloading: boolean
  caseDownloadingID: number
  caseDownloadReady: boolean
  caseReadyToDownload: string
  artifactLoading: boolean
  updateArtifactIsLoading: boolean
  updateArtifactError: boolean
  updateArtifactErrorMessage: string
  updateArtifactSuccess: boolean
  removeArtifactIsLoading: boolean
  removeArtifactErrorMessage: string
  removeArtifactSuccess: boolean
  deleteCaseIsLoading: boolean
  deleteCaseErrorMessage: string
  deleteCaseSuccess: boolean
  clipCases: []
  clipCasesIsLoading: boolean
  closeCreateClipMode: boolean
  sharedEmailList: SharedEmail[]
  shareCaseIsLoading: boolean
  shareCaseError: boolean
  caseIsLoading: boolean
  shareCaseIsDeleting: boolean
}

interface SharedEmail {
  emailAddress: string
  id: number
}
