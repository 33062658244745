import httpClient from './httpClient'
export default {
  Location: {
    GetLocation(payload) {
      return httpClient.post('/Location/GetLocation', payload)
    },
    GetLocations(payload) {
      return httpClient.post('/Location/GetLocations', payload)
    },
    GetLocationList(payload) {
      return httpClient.post('/Location/GetLocationList', payload)
    },
    AddLocation(payload) {
      return httpClient.post('/Location/AddLocation', payload)
    },
    GetCustomerLocationList(payload) {
      return httpClient.post('/Location/GetCustomerLocationList', payload)
    },
    GetCustomerLocations(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Location/GetCustomerLocations', payload)
    },
    AssignCustomerLocations(payload) {
      return httpClient.post('/Location/AssignCustomerLocations', payload)
    },
    GetAssignedUserLocations(payload) {
      return httpClient.post('/Location/GetAssignedUserLocations', payload)
    },
    EditCustomerLocation(payload) {
      return httpClient.post('/Location/EditLocation', payload)
    },
  },
}
