import store from '@/store'
export default [
  {
    path: '/locations',
    name: 'LocationList',
    component: () =>
      import(
        /*webpackChunkName: "LocationList" */ '@/views/locations/LocationList.vue'
      ),
    meta: {
      title: 'Locations',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: true,
      showFooter: true,
      dealerAndCustomer: true,
    },
    beforeEnter: (to, from, next) => {
      let isDealerUser = store.state.Security.user.isDealerUser
      let dealerViewingUser = store.state.Security.dealerViewingUser
      if (isDealerUser && !dealerViewingUser) {
        next('/dashboard')
      } else {
        next()
      }
    },
  },
  {
    path: '/locations/:id',
    name: 'LocationDetail',
    component: () =>
      import(
        /*webpackChunkName: "LocationDetail" */ '@/views/locations/LocationDetail.vue'
      ),
    meta: {
      title: 'Location Detail',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: true,
      showFooter: true,
      dealerAndCustomer: true,
    },
    beforeEnter: (to, from, next) => {
      let isDealerUser = store.state.Security.user.isDealerUser
      let dealerViewingUser = store.state.Security.dealerViewingUser
      if (isDealerUser && !dealerViewingUser) {
        next('/dashboard')
      } else {
        next()
      }
    },
  },
  {
    path: '/camera/:id',
    name: 'SingleCamera',
    component: () =>
      import(
        /*webpackChunkName: "SingleCamera" */ '@/views/singleCamera/SingleCamera.vue'
      ),
    meta: {
      title: 'Camera',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: false,
      showFooter: false,
      dealerAndCustomer: true,
    },
    beforeEnter: (to, from, next) => {
      let isDealerUser = store.state.Security.user.isDealerUser
      let dealerViewingUser = store.state.Security.dealerViewingUser
      if (isDealerUser && !dealerViewingUser) {
        next('/dashboard')
      } else {
        next()
      }
    },
  },
  {
    path: '/library',
    name: 'Library',
    component: () =>
      import(/*webpackChunkName: "Library" */ '@/views/library/Library.vue'),
    props: true,
    meta: {
      title: 'Library',
      allowAnonymous: false,
      customerOnly: true,
      showHeader: true,
      showFooter: true,
      internalOnly: false,
    },
  },
  {
    path: '/library/case/:id',
    name: 'Case',
    component: () =>
      import(/*webpackChunkName: "SingleCase" */ '@/views/case/Case.vue'),
    meta: {
      title: 'Case',
      allowAnonymous: false,
      customerOnly: true,
      showHeader: true,
      showFooter: true,
      internalOnly: false,
    },
  },
  {
    path: '/admin/users/:id',
    name: 'VigilUserDetail',
    component: () =>
      import(
        /*webpackChunkName: "VigilUserDetail" */ '@/views/vigilUserDetail/VigilUserDetail.vue'
      ),
    meta: {
      title: 'User Detail',
      customerOnly: true,
      allowAnonymous: false,
      showHeader: true,
      showFooter: true,
      customerUserDetail: true,
      dealerUserDetail: false,
      superAdminUserDetail: false,
      superAdminCustomerUserDetail: false,
      superAdminDealerUserDetail: false,
      internalOnly: false,
    },
  },
  {
    path: '/views',
    name: 'MultiView',
    component: () =>
      import(
        /*webpackChunkName: "MultiView" */ '@/views/multiView/ViewContainer.vue'
      ),
    meta: {
      title: 'Views',
      customerOnly: true,
      allowAnonymous: false,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/views/:id',
    name: 'SingleMultiView',
    component: () =>
      import(
        /*webpackChunkName: "MultiView" */ '@/views/multiView/SingleMultiView.vue'
      ),
    meta: {
      title: 'View',
      customerOnly: true,
      allowAnonymous: false,
      showHeader: false,
      showFooter: false,
    },
  },
]
