import {
  DealerUser,
  Permission,
  RolePermission,
  Roles,
  User,
  UserAllLocation,
  UserAssignedLocation,
  Location,
} from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): VigilUserAdminState => {
  return {
    usersList: [],
    usersListLoading: false,
    dealerUsersList: [],
    dealerUsersListLoading: false,
    rolesList: [],
    getRolesError: '',
    addUserIsLoading: false,
    addUserError: false,
    addUserErrorMessage: '',
    createdUserId: 0,
    userDeleteIsLoading: false,
    userDeleteSuccess: false,
    userDeleteErrorMessage: '',
    userEnableDisableIsLoading: false,
    userEnableDisableSuccess: false,
    userEnableDisableErrorMessage: '',
    rolePermissionsList: [],
    rolePermissionsListIsLoading: false,
    dealerUserEnabledIsLoading: false,
    dealerUserEnabledSuccess: false,
    dealerUserEnabledErrorMessage: '',
    userAssignedLocations: [],
    userAllLocations: [],
    userAssignedLocationsIsLoading: false,
    userAssignedToAllLocations: false,
    assignUserLocationsError: false,
    assignUserLocationsIsLoading: false,
    assignUserLocationsErrorMessage: '',
    updateUserInfoIsLoading: false,
    updateUserInfoErrorMessage: '',
    updateUserInfoSuccess: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<VigilUserAdminState, RootState> = {
  dealerUsersEnabledCount: (state) => {
    if (state.dealerUsersList.length == 0) {
      return ''
    } else {
      let count = 0
      state.dealerUsersList.forEach((dealer: DealerUser) => {
        if (dealer.hasCustomerAccess) count++
      })
      return count
    }
  },
}

const mutations: MutationTree<VigilUserAdminState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setUsersList(state, value) {
    state.usersList = value
  },
  setUsersListLoading(state, value) {
    state.usersListLoading = value
  },
  setDealerUsersList(state, value) {
    state.dealerUsersList = value
  },
  setDealerUsersListLoading(state, value) {
    state.dealerUsersListLoading = value
  },
  updateDealerUserEnabled(state, value) {
    const userID = value.UserId
    const currentDealer = state.dealerUsersList.find(
      ({ userId }) => userId === userID
    )
    currentDealer.hasCustomerAccess = value.IsEnabled
  },
  updateAssignedLocation(state, value) {
    const locationID = value.locationId
    const currentLocation = state.userAllLocations.find(
      ({ locationId }) => locationId === locationID
    )
    if (typeof currentLocation !== 'undefined')
      currentLocation.assignedToUser = !value.assignedToUser
  },
  updateAssignLocationsArray(state, value) {
    state.userAllLocations.forEach((loc, index) => {
      // problem here, value[index] comes back as undefined sometimes, as the index 'number' is greater than the value.length
      loc.assignedToUser = !value[index].assignedToUser
    })
  },
  updateAssignedAllLocations(state) {
    state.userAllLocations.forEach((loc) => {
      loc.assignedToUser = true
    })
  },
  updateAssignedToNoLocations(state) {
    state.userAllLocations.forEach((loc) => {
      loc.assignedToUser = false
    })
  },
  updateDealerUserEnabledIsLoading(state, value) {
    state.dealerUserEnabledIsLoading = value
  },
  setDealerUserEnabledSuccess(state, value) {
    state.dealerUserEnabledSuccess = value
  },
  setDealerUserEnabledErrorMessage(state, value) {
    state.dealerUserEnabledErrorMessage = value
  },
  setRolesList(state, value) {
    state.rolesList = value
  },
  setGetRolesError(state, value) {
    state.getRolesError = value
  },
  setAddUserIsLoading(state, value) {
    state.addUserIsLoading = value
  },
  setAddUserError(state, value) {
    state.addUserError = value
  },
  setAddUserErrorMessage(state, value) {
    state.addUserErrorMessage = value
  },
  setCreatedUserId(state, value) {
    state.createdUserId = value
  },
  setUserDeleteIsLoading(state, value) {
    state.userDeleteIsLoading = value
  },
  setUserDeleteSuccess(state, value) {
    state.userDeleteSuccess = value
  },
  setUserDeleteErrorMessage(state, value) {
    state.userDeleteErrorMessage = value
  },
  setUserEnableDisableIsLoading(state, value) {
    state.userEnableDisableIsLoading = value
  },
  setUserEnableDisableSuccess(state, value) {
    state.userEnableDisableSuccess = value
  },
  setUserEnableDisableErrorMessage(state, value) {
    state.userEnableDisableErrorMessage = value
  },
  setRolePermissionList(state, value) {
    state.rolePermissionsList = value
  },
  setRolePermissionListLoading(state, value) {
    state.rolePermissionsListIsLoading = value
  },
  setUserAssignedLocations(state, value) {
    state.userAssignedLocations = value
  },
  setUserAssignedLocationsIsLoading(state, value) {
    state.userAssignedLocationsIsLoading = value
  },
  setUserAssignedToAllLocations(state, value) {
    state.userAssignedToAllLocations = value
  },
  setUserAllLocations(state, value) {
    state.userAllLocations = value
  },
  setAssignUserLocationsLoading(state, value) {
    state.assignUserLocationsIsLoading = value
  },
  setAssignUserLocationsError(state, value) {
    state.assignUserLocationsError = value
  },
  setAssignUserLocationsErrorMessage(state, value) {
    state.assignUserLocationsErrorMessage = value
  },
  setUpdateUserInfoIsLoading(state, value) {
    state.updateUserInfoIsLoading = value
  },
  setUpdateUserInfoErrorMessage(state, value) {
    state.updateUserInfoErrorMessage = value
  },
  setUpdateUserInfoSuccess(state, value) {
    state.updateUserInfoSuccess = value
  },
  setPrimaryContact(state, value) {
    state.usersList.forEach((element) => {
      element.isPrimaryContact = false
      if (element.userId == value) {
        element.isPrimaryContact = true
      }
    })
  },
  setUpdateUserEnableDisable(state, value) {
    state.usersList.forEach((user) => {
      if (value.userId === user.userId) {
        user.isActive = !value.isActive
        if (user.isActive) {
          user.status = 'Enabled'
        } else {
          user.status = 'Disabled'
        }
      }
    })
  },
  setDeleteUser(state, value) {
    state.usersList = state.usersList.filter((user) => {
      return user.userId !== value.userId
    })
  },
}

const actions: ActionTree<VigilUserAdminState, RootState> = {
  async setPrimary({ commit }, payload) {
    commit('updateDealerUserEnabledIsLoading', true)
    return repository.Customer.SetPrimary(payload)
      .then(() => {
        commit('setPrimaryContact', payload.UserId)
        commit('setDealerUserEnabledSuccess', true)
      })
      .catch(() => {
        commit('setDealerUserEnabledSuccess', false)
      })
      .finally(() => {
        commit('updateDealerUserEnabledIsLoading', false)
      })
  },
  async getVigilAdminUsers({ commit }, payload) {
    commit('setUsersListLoading', true)
    commit('setUsersList', [])
    return repository.Customer.GetUsers(payload)
      .then((res) => {
        commit('setUsersList', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setUsersListLoading', false)
      })
  },
  async addLocation({ commit }, payload) {
    commit('setAddLocationIsLoading', true)
    return repository.Location.AddLocation(payload)
      .then((res) => {
        commit('setNewLocationId', res.data.locationId)
        commit('setAddLocationError', false)
      })
      .catch((err) => {
        commit('setAddLocationError', true)
        commit('setAddLocationErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddLocationIsLoading', false)
      })
  },
  async getDealerUsers({ commit }, payload) {
    commit('setDealerUsersListLoading', true)
    return repository.Customer.GetDealerUsersByCustomer(payload)
      .then((res) => {
        commit('setDealerUsersList', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setDealerUsersListLoading', false)
      })
  },
  async enableDealerUser({ commit }, payload) {
    commit('updateDealerUserEnabledIsLoading', true)
    return repository.Customer.EnableDisableDealerUsersByCustomer(payload)
      .then(() => {
        commit('setDealerUserEnabledSuccess', true)
        commit('updateDealerUserEnabled', payload)
      })
      .catch((err) => {
        commit('setDealerUserEnabledSuccess', false)
        commit('setDealerUserEnabledErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('updateDealerUserEnabledIsLoading', false)
      })
  },
  callUpdateAssignedLocation({ commit }, payload) {
    commit('updateAssignedLocation', payload)
  },
  callUpdateAssignedLocationsArray({ commit }, payload) {
    commit('updateAssignLocationsArray', payload)
  },
  callUpdateAssignAllLocations({ commit }, payload) {
    if (payload.assignToAll) commit('updateAssignedAllLocations')
    else commit('updateAssignedToNoLocations')
  },
  clearEnableDealerUserError({ commit }) {
    commit('setDealerUserEnabledSuccess', true)
    commit('setDealerUserEnabledErrorMessage', '')
  },
  async getRoles({ commit }, payload) {
    return repository.Role.GetRoles(payload)
      .then((res) => {
        commit('setRolesList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.message)
      })
  },
  async addUser({ commit }, payload) {
    commit('setAddUserIsLoading', true)
    return repository.User.Add(payload)
      .then((res) => {
        commit('setCreatedUserId', res.data.userId)
      })
      .catch((err) => {
        commit('setAddUserError', true)
        commit('setAddUserErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddUserIsLoading', false)
      })
  },
  clearAddUserError({ commit }) {
    commit('setAddUserError', false)
  },
  async deleteUser({ commit }, payload) {
    commit('setUserDeleteIsLoading', true)
    const apiPayload = {
      UserId: payload.userId,
    }
    return repository.User.DeleteUser(apiPayload)
      .then(() => {
        commit('setUserDeleteSuccess', true)
        commit('setDeleteUser', payload)
      })
      .catch((err) => {
        commit('setUserDeleteSuccess', false)
        commit('setUserDeleteErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserDeleteIsLoading', false)
      })
  },
  clearDeleteUserError({ commit }) {
    commit('setUserDeleteErrorMessage', '')
    commit('setUserDeleteSuccess', false)
  },
  async enableDisableUser({ commit }, payload) {
    commit('setUserEnableDisableIsLoading', true)
    const apiPayload = {
      UserId: payload.userId,
      IsEnabled: !payload.isActive,
    }
    return repository.User.EnableDisableUser(apiPayload)
      .then((res) => {
        if (res.status == 200) {
          commit('setUserEnableDisableSuccess', true)
          commit('setUpdateUserEnableDisable', payload)
        }
      })
      .catch((err) => {
        commit('setUserEnableDisableSuccess', false)
        commit('setUserEnableDisableErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserEnableDisableIsLoading', false)
      })
  },
  clearEnableDisableUserError({ commit }) {
    commit('setUserEnableDisableErrorMessage', '')
    commit('setUserEnableDisableSuccess', false)
  },
  async getRolesPermissions({ commit }, payload) {
    commit('setRolePermissionListLoading', true)
    return repository.Role.GetRolesPermissionsUsers(payload)
      .then((res) => {
        const roleListData = res.data.items
        roleListData.forEach((role: RolePermission) => {
          role.roleType = 'default'
          let numberOfPermissionsAssigned = 0
          role.permissions.forEach((permission: Permission) => {
            if (permission.isAvailable) {
              numberOfPermissionsAssigned += 1
            }
          })
          role.numberOfPermissionsAssigned = numberOfPermissionsAssigned
          role.numberOfPeopleAssigned = role.users.length
          role.users.forEach((user) => {
            if (user.isActive) {
              user.status = 'Enabled'
            } else {
              user.status = 'Disabled'
            }
          })
        })
        commit('setRolePermissionList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.message)
      })
      .finally(() => {
        commit('setRolePermissionListLoading', false)
      })
  },
  async getUserAssignedLocations({ commit }, payload) {
    commit('setUserAssignedLocationsIsLoading', true)
    return repository.Location.GetAssignedUserLocations(payload)
      .then((res) => {
        const assignedLocations = []
        res.data.locations.forEach((location: Location) => {
          if (location.assignedToUser) {
            assignedLocations.push(location)
          }
        })
        commit('setUserAssignedLocations', assignedLocations)
        commit('setUserAssignedToAllLocations', res.data.assignToAllLocations)
        commit('setUserAllLocations', res.data.locations)
      })
      .catch(() => {
        commit('setUserAssignedLocations', [])
        commit('setUserAssignedToAllLocations', false)
        commit('setUserAllLocations', [])
      })
      .finally(() => {
        commit('setUserAssignedLocationsIsLoading', false)
      })
  },
  updateUserAssignedLocations({ commit }, payload) {
    commit('setUserAssignedLocations', payload)
  },
  async assignUserLocations({ commit }, payload) {
    commit('setAssignUserLocationsLoading', true)
    commit('setUserAssignedToAllLocations', payload.AssignAllLocations)
    if (payload.locations) commit('setUserAssignedLocations', payload.locations)
    return repository.Location.AssignCustomerLocations(payload)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        commit('setAssignUserLocationsLoading', false)
      })
    // commit('setAssignUserLocationsLoading', false)
  },
  async updateUserInfo({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.User.Update(payload)
      .then((res) => {
        if (res.status == 200) {
          commit('setUpdateUserInfoSuccess', true)
        }
      })
      .catch((err) => {
        commit('setUpdateUserInfoSuccess', false)
        commit('setUpdateUserInfoErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  async callResetPassword(_, payload) {
    return repository.User.ResetUserPassword(payload)
  },
}
const VigilUserAdmin: Module<VigilUserAdminState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default VigilUserAdmin

interface VigilUserAdminState {
  usersList: User[]
  usersListLoading: boolean
  dealerUsersList: DealerUser[]
  dealerUsersListLoading: boolean
  rolesList: Roles[]
  getRolesError: string
  addUserIsLoading: boolean
  addUserError: boolean
  addUserErrorMessage: string
  createdUserId: number
  userDeleteIsLoading: boolean
  userDeleteSuccess: boolean
  userDeleteErrorMessage: string
  userEnableDisableIsLoading: boolean
  userEnableDisableSuccess: boolean
  userEnableDisableErrorMessage: string
  rolePermissionsList: RolePermission[]
  rolePermissionsListIsLoading: boolean
  dealerUserEnabledIsLoading: boolean
  dealerUserEnabledSuccess: boolean
  dealerUserEnabledErrorMessage: string
  userAssignedLocations: UserAssignedLocation[]
  userAllLocations: UserAllLocation[]
  userAssignedLocationsIsLoading: boolean
  userAssignedToAllLocations: boolean
  assignUserLocationsError: boolean
  assignUserLocationsIsLoading: boolean
  assignUserLocationsErrorMessage: string
  updateUserInfoIsLoading: boolean
  updateUserInfoErrorMessage: string
  updateUserInfoSuccess: boolean
}
