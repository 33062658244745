
import { defineComponent } from 'vue'

import CustomerUserNav from './navigation/CustomerUserNav.vue'
import DealerUserNav from './navigation/DealerUserNav.vue'
import InternalUserNav from './navigation/InternalUserNav.vue'
import DealerBanner from './DealerBanner.vue'
import DealerCCBanner from '@/components/banners/DealerCCBanner.vue'
import DownloadCaseBanner from './DownloadCaseBanner.vue'

import { mapState, mapGetters } from 'vuex'
import * as Permissions from '@/constants/permissions.js'

export default defineComponent({
  name: 'Header',
  components: {
    DealerBanner,
    DealerCCBanner,
    DownloadCaseBanner,
    CustomerUserNav,
    DealerUserNav,
    InternalUserNav,
  },
  data() {
    return {
      tab: '',
    }
  },
  computed: {
    ...mapState('Security', ['user', 'dealerViewingUser', 'isLoggedIn']),
    ...mapGetters('Security', ['hasPermission']),
    hasCreateEditInternalUsersPermission() {
      return this.hasPermission(Permissions.CREATE_EDIT_INTERNAL_USERS)
    },
    showCaseLibrary() {
      return (
        this.hasPermission(Permissions.VIEW_CASE) ||
        this.hasPermission(Permissions.CREATE_EDIT_CASE)
      )
    },
    showAdmin() {
      return this.hasPermission(Permissions.CREATE_EDIT_CUSTOMER_USERS)
    },
    showDealerMyUsers() {
      return this.hasPermission(Permissions.CREATE_EDIT_DEALER_USERS)
    },
    navTabsOptional() {
      const route = this.$route.name
      switch (route) {
        case 'Profile':
          return true
        case 'NotificationsList':
          return true
        case 'Notification Settings':
          return true
        case 'Help':
          return true
        default:
          return false
      }
    },
  },
})
