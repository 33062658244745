
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import TermsComponent from './TermsComponent.vue'
import AudioComponent from './AudioComponent.vue'
import { EULA_VIEWED, EULA_ACCEPTED } from '@/constants/mixpanelEvents'
import { isMobile } from 'mobile-device-detect'

export default defineComponent({
  name: 'TermsDialog',
  components: {
    TermsComponent,
    AudioComponent,
  },
  data() {
    return {
      audioAgreed: false,
      currentTab: 0,
      dialog: false,
      showAudio: true,
      showTerms: true,
      termsAgreed: false,
      userClickOpen: false,
    }
  },
  computed: {
    ...mapState('Security', ['updateUserSuccess', 'user', 'isLoggedIn']),
    actionButtonText() {
      if (this.termsAgreed && this.audioAgreed) {
        return this.$t('global.submit')
      } else if (this.currentTab == 1 && !this.audioAgreed) {
        return this.$t('global.submit')
      } else if (this.currentTab == 0 && !this.termsAgreed && !this.showAudio) {
        return this.$t('global.submit')
      } else {
        return this.$t('global.next')
      }
    },
    actionButtonDisabled() {
      if (this.currentTab == 0 && this.termsAgreed) {
        return false
      } else if (this.currentTab == 1 && this.audioAgreed) {
        return false
      } else {
        return true
      }
    },
    isMobile() {
      return isMobile
    },
  },
  methods: {
    ...mapActions('Security', ['acceptTerms', 'logout']),
    switchDoc(value) {
      this.currentTab = value
    },
    setTermsAgreed(value) {
      this.termsAgreed = value
    },
    setAudioAgreed(value) {
      this.audioAgreed = value
    },
    showDialog() {
      this.currentTab = 3
      this.termsAgreed = false
      this.audioAgreed = false
      this.userClickOpen = false

      if (
        (!this.user.hasAcceptedTerms ||
          !this.user.hasAcceptedEndUserLicenseAgreement ||
          !this.user.hasAcceptedAudio) &&
        this.isLoggedIn
      ) {
        this.dialog = true
        this.setAcceptedDocs()
      }
    },
    setAcceptedDocs() {
      if (this.user.hasAcceptedTerms && !this.user.hasAcceptedAudio) {
        this.termsAgreed = true
        this.showTerms = false
        this.showAudio = true
        this.currentTab = 1
      } else if (!this.user.hasAcceptedTerms && this.user.hasAcceptedAudio) {
        this.audioAgreed = true
        this.showTerms = true
        this.showAudio = false
        this.currentTab = 0
      } else {
        this.showTerms = true
        this.showAudio = true
        this.currentTab = 0
        this.termsAgreed = false
        this.audioAgreed = false
      }
    },
    async decline() {
      this.dialog = false
      if (this.userClickOpen) {
        return
      }
      this.$mixpanelUserTrack(EULA_ACCEPTED, {
        EULA_ACCEPTED: false,
      })
      if (this.$refs.termsComponent) this.$refs.termsComponent.scrollTop()
      window.scrollTo(0, 0)
      await this.logout()
      this.$router.push('/login')
    },
    submitOrNext() {
      if (this.termsAgreed && this.audioAgreed) {
        this.submit()
      } else {
        this.currentTab = 1
      }
    },
    submit() {
      let payload = {
        UserId: this.user.userId,
        hasAcceptedTerms: this.termsAgreed,
        hasAcceptedEndUserLicenseAgreement: this.termsAgreed,
      }
      if (this.user.hasAcceptedTerms) {
        payload.hasAcceptedAudio = this.audioAgreed
      } else if (this.user.hasAcceptedAudio) {
        payload.hasAcceptedTerms = this.termsAgreed
      } else {
        payload.hasAcceptedTerms = this.termsAgreed
        payload.hasAcceptedAudio = this.audioAgreed
      }

      this.acceptTerms(payload).then(() => {
        if (this.updateUserSuccess) {
          this.dialog = false
          window.scrollTo(0, 0)
        }
      })
    },
    userClick() {
      this.$mixpanelUserTrack(EULA_VIEWED)
      this.dialog = true
      this.userClickOpen = true
      this.showTerms = true
      this.showAudio = true
      this.audioAgreed = false
      this.currentTab = 0
      this.termsAgreed = false
    },
  },
  mounted() {
    this.showDialog()
  },
  watch: {
    user() {
      this.showDialog()
    },
    isLoggedIn() {
      // this watch seems to resolve the odd terms dialog not showing issue
      this.showDialog()
    },
  },
})
