import httpClient from './httpClient'

export default {
  RecentlyViewed: {
    GetLocations(payload) {
      return httpClient.post('/RecentlyViewed/GetLocations', payload)
    },
    GetCases(payload) {
      return httpClient.post('RecentlyViewed/GetCases', payload)
    },
  },
}
