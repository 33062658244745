
import { defineComponent } from 'vue'
import CookieBanner from './components/CookieBanner.vue'
import UserForm from './UserForm.vue'
import TwoFactorForm from './TwoFactorForm.vue'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import { LOGIN_PAGE_VIEWED } from '@/constants/mixpanelEvents'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'Login',
  components: {
    CookieBanner,
    UserForm,
    TwoFactorForm,
    TwoColumnLayout,
  },
  data() {
    return {
      loginStep: 1,
      enteredEmail: '',
      enteredPassword: '',
    }
  },
  computed: {
    ...mapState('Security', ['isLoggedIn', 'user']),
    copyrightDate() {
      let d = new Date()
      return d.getFullYear()
    },
  },
  methods: {
    ...mapActions('Security', ['checkSystemStatus', 'getOwnUserNoLoading']),
    nextStep(payload) {
      this.enteredEmail = payload.email
      this.enteredPassword = payload.password
      this.loginStep = 2
    },
  },
  created() {
    this.loginStep = 1
    this.$mixpanelTrack(LOGIN_PAGE_VIEWED)
    this.checkSystemStatus()
    this.getOwnUserNoLoading().then(() => {
      if (this.isLoggedIn) {
        if (this.user.isInternalUser) {
          this.$router.push('/dealers')
        } else {
          this.$router.push('/dashboard')
        }
      }
    })
  },
})
