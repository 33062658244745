
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CookieBanner',
  data() {
    return {
      showBanner: true,
    }
  },
  methods: {
    closeBanner() {
      this.showBanner = false
    },
  },
})
