import { throttle } from 'lodash'

export default {
  data() {
    return {
      firstNameRules: [
        (v) => !!v || this.$t('validationText.firstNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.firstNameLength'),
      ],
      lastNameRules: [
        (v) => !!v || this.$t('validationText.lastNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.lastNameLength'),
      ],
      adminFirstNameRules: [
        (v) => !!v || this.$t('validationText.firstNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.firstNameLength'),
      ],
      adminLastNameRules: [
        (v) => !!v || this.$t('validationText.lastNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.lastNameLength'),
      ],
      emailRules: [
        (v) => !!v || this.$t('validationText.emailAddressRequired'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('validationText.emailMustBeValid'),
      ],
      adminEmailRules: [
        (v) => !!v || this.$t('validationText.emailAddressRequired'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('validationText.emailMustBeValid'),
      ],
      deviceEventEmailRules: [
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('validationText.emailMustBeValid'),
      ],
      phoneRules: [
        (v) => !!v || this.$t('validationText.phoneNumberRequired'),
        (v) =>
          /^\d{7,}$/.test(v.replace(/[\s()+\-\.]/gi, '')) ||
          this.$t('validationText.phoneNumberValid'),
      ],
      adminPhoneRules: [
        (v) => !!v || this.$t('validationText.phoneNumberRequired'),
        (v) =>
          /^\d{7,}$/.test(v.replace(/[\s()+\-\.]/gi, '')) ||
          this.$t('validationText.phoneNumberValid'),
      ],
      companyPhoneRules: [
        (v) => !!v || this.$t('validationText.phoneNumberRequired'),
        (v) =>
          /^\d{7,}$/.test(v.replace(/[\s()+\-\.]/gi, '')) ||
          this.$t('validationText.phoneNumberValid'),
      ],
      primaryContactPhoneRules: [
        (v) => !!v || this.$t('validationText.phoneNumberRequired'),
        (v) =>
          /^\d{7,}$/.test(v.replace(/[\s()+\-\.]/gi, '')) ||
          this.$t('validationText.phoneNumberValid'),
      ],
      userRoleRules: [(v) => !!v || this.$t('validationText.userRoleRequired')],
      customerNameRules: [
        (v) => !!v || this.$t('validationText.nameIsRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.customerNameLength'),
      ],
      dealerNameRules: [
        (v) => !!v || this.$t('validationText.nameIsRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.dealerNameLength'),
      ],
      billingTypeRules: [
        (v) =>
          !v ||
          (v && Object.keys(v).length > 0) ||
          this.$t('validationText.billingTypeIsRequired'),
      ],
      locationNameRules: [
        (v) => !!v || this.$t('validationText.nameIsRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.locationNameLength'),
      ],
      firstAddressRules: [
        (v) => !!v || this.$t('validationText.addressIsRequired'),
        (v) => (v && v.length <= 50) || this.$t('validationText.addressLength'),
      ],
      secondAddressRules: [
        (v) =>
          !v ||
          (v && v.length <= 50) ||
          this.$t('validationText.addressLength'),
      ],
      streetRules: [
        (v) => !!v || this.$t('validationText.streetRequired'),
        (v) => (v && v.length <= 50) || this.$t('validationText.lessThanFifty'),
      ],
      numberRules: [
        (v) => !!v || this.$t('validationText.numberRequired'),
        (v) => (v && v.length <= 50) || this.$t('validationText.lessThanFifty'),
      ],
      countyRules: [
        (v) =>
          !v || (v && v.length <= 50) || this.$t('validationText.countyLength'),
      ],
      cityRules: [
        (v) => !!v || this.$t('validationText.cityIsRequired'),
        (v) => (v && v.length <= 50) || this.$t('validationText.cityLength'),
      ],
      townOrCityRules: [
        (v) => !!v || this.$t('validationText.townOrCityIsRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.townOrCityLength'),
      ],
      municipalityRules: [
        (v) => !!v || this.$t('validationText.municipalityIsRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.municipalityLength'),
      ],
      provinceRules: [
        (v) => !!v || this.$t('validationText.provinceIsRequired'),
      ],
      postalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) => /^\d{5}$/.test(v) || this.$t('validationText.postalCodeValid'),
      ],
      ukPostalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) =>
          /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/.test(v) ||
          this.$t('validationText.postalCodeValid'),
      ],
      dkPostalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) => /^\d{4}$/.test(v) || this.$t('validationText.postalCodeValid'),
      ],
      fiPostalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) => /^\d{5}$/.test(v) || this.$t('validationText.postalCodeValid'),
      ],
      noPostalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) => /^\d{4}$/.test(v) || this.$t('validationText.postalCodeValid'),
      ],
      sePostalCodeRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) =>
          /^\d{3}\s?\d{2}$/.test(v) ||
          this.$t('validationText.postalCodeValid'),
      ],
      zipCodeRules: [
        (v) => !!v || this.$t('validationText.zipCodeRequired'),
        (v) => /^\d{5}$/.test(v) || this.$t('validationText.zipCodeValid'),
      ],
      zipCodeCANRules: [
        (v) => !!v || this.$t('validationText.postalCodeRequired'),
        (v) =>
          /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}\d{1}[A-Z]{1}\d{1}$/.test(v) ||
          this.$t('validationText.postalCodeValid'),
      ],
      selectedStateRules: [
        (v) => !!v || this.$t('validationText.stateRequired'),
      ],
      primaryContactFirstNameRules: [
        (v) => !!v || this.$t('validationText.firstNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.firstNameLength'),
      ],
      primaryContactLastNameRules: [
        (v) => !!v || this.$t('validationText.lastNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.lastNameLength'),
      ],
      primaryContactEmailRules: [
        (v) => !!v || this.$t('validationText.emailAddressRequired'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('validationText.emailMustBeValid'),
      ],
      caseNameRules: [
        (v) => !!v || this.$t('validationText.caseNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.caseNameLength'),
        (v) =>
          !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(v) ||
          this.$t('validationText.caseNameCharacters'),
      ],
      caseDescriptionRules: [
        throttle(
          (v) =>
            !v ||
            (v && v.length <= 8000) ||
            this.$t('validationText.caseDetailLength'),
          500
        ),
      ],
      selectedIncidentTypeRules: [
        (v) => !!v || this.$t('validationText.incidentTypeRequired'),
      ],
      selectedExistingCaseRules: [
        (v) => !!v || this.$t('validationText.caseMustBeSelected'),
      ],
      screenshotNameRules: [
        (v) => !!v || this.$t('validationText.screenshotNameRequired'),
        (v) =>
          (v && v.length <= 50) ||
          this.$t('validationText.screenshotNameLength'),
        (v) =>
          !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(v) ||
          this.$t('validationText.screenshotNameCharacters'),
      ],
      screenshotDetailsRules: [
        (v) =>
          !v ||
          (v && v.length <= 500) ||
          this.$t('validationText.screenshotDetailsLength'),
      ],
      clipNameRules: [
        (v) => !!v || this.$t('validationText.clipNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.clipNameLength'),
        (v) =>
          !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(v) ||
          this.$t('validationText.clipNameCharacters'),
      ],
      clipDetailsRules: [
        (v) =>
          !v ||
          (v && v.length <= 500) ||
          this.$t('validationText.clipDetailsLength'),
      ],
      deviceNameRules: [
        (v) => !!v || this.$t('validationText.cameraNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.cameraNameLength'),
      ],
      macRules: [
        (v) => !!v || this.$t('validationText.cameraMACRequired'),
        (v) => /[A-Fa-f0-9]{12}/.test(v) || this.$t('validationText.macValid'),
        (v) => (v && v.length <= 12) || this.$t('validationText.macLength'),
      ],
      verificationCodeRules: [
        (v) => !!v || this.$t('validationText.verificationCodeRequired'),
        (v) =>
          /[A-Fa-f0-9]{6}/.test(v) ||
          this.$t('validationText.verificationCodeValid'),
        (v) =>
          (v && v.length <= 6) ||
          this.$t('validationText.verificationCodeLength'),
      ],
      deviceDescriptionRules: [
        (v) =>
          !v ||
          (v && v.length <= 100) ||
          this.$t('validationText.cameraDescriptionLength'),
      ],
      cameraPackageOptionsRules: [
        (v) => !!v || this.$t('validationText.cameraPackageRequired'),
      ],
      timeZoneOptionsRules: [
        (v) => !!v || this.$t('validationText.timezoneRequired'),
      ],
      nvrAliasRules: [(v) => !!v || this.$t('validationText.nvrAliasRequired')],
      vigilConnectAliasRules: [
        (v) => !!v || this.$t('validationText.vigilConnectAliasRequired'),
      ],
      vigilUserNameRules: [
        (v) => !!v || this.$t('validationText.userNameRequired'),
      ],
      vigilPasswordRules: [
        (v) => !!v || this.$t('validationText.passwordRequired'),
      ],
      editedNameRules: [
        (v) => !!v || this.$t('validationText.deviceNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.deviceNameLength'),
      ],
      editedNVRNameRules: [
        (v) => !!v || this.$t('validationText.nvrNameRequired'),
        (v) => (v && v.length <= 50) || this.$t('validationText.nvrNameLength'),
      ],
      editedRuleSetName: [
        (v) => !!v || this.$t('validationText.ruleSetNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.ruleSetNameLength'),
      ],
      viewNameRules: [
        (v) => !!v || this.$t('validationText.viewNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.viewNameLength'),
      ],
      shareCaseEmailRules: [
        (v) =>
          /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
            v
          ) || this.$t('validationText.shareEmailMustBeValid'),
      ],
      limitStorageRules: [
        (v) => /^[1-9]\d*$/.test(v) || this.$t('validationText.daysValid'),
        (v) => {
          if (this.cameraPackageSelect == null) {
            return v <= 365 || this.$t('validationText.limitStorageValid')
          }
          return (
            (this.cameraPackageSelect &&
              v <= this.cameraPackageSelect.retention) ||
            this.$t('validationText.limitStorageValid')
          )
        },
      ],
      alertNameRules: [
        (v) => !!v || this.$t('validationText.alertNameRequired'),
        (v) =>
          (v && v.length <= 50) || this.$t('validationText.alertNameLength'),
      ],
      analyticsTypeRequired: [
        (v) => !!v || this.$t('validationText.analyticsTypeRequired'),
      ],
      alertScheduleRequired: [
        (v) => !!v || this.$t('validationText.alertScheduleRequired'),
      ],
      objectTypeRequired: [
        (v) => !!v || this.$t('validationText.objectTypeRequired'),
      ],
      severityLevelRequired: [
        (v) => !!v || this.$t('validationText.severityLevelRequired'),
      ],
      triggerRequired: [
        (v) => !!v || this.$t('validationText.triggerTypeRequired'),
      ],
      triggerNumberRequired: [
        (v) => !!v || this.$t('validationText.triggerNumberRequired'),
      ],
      advancedRuleNameRules: [
        (v) => !!v || this.$t('validationText.advancedRuleNameRequired'),
        (v) =>
          (v && v.length <= 50) ||
          this.$t('validationText.advancedRuleNameLength'),
        (v) =>
          /^[\w\-\.\@\ ]*$/.test(v) ||
          this.$t('validationText.advancedRuleNameValid'),
      ],
    }
  },
}
