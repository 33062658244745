import httpClient from './httpClient'

export default {
  MultiView: {
    GetAllUserProfiles(payload) {
      return httpClient.post('/DeviceMultipleView/GetAllUserProfiles', payload)
    },
    GetUserProfileById(payload) {
      return httpClient.post('/DeviceMultipleView/GetUserProfileById', payload)
    },
    CreateOrUpdate(payload) {
      return httpClient.post('/DeviceMultipleView/CreateOrUpdate', payload)
    },
    Delete(payload) {
      return httpClient.post('/DeviceMultipleView/Delete', payload)
    },
  },
}
