
import { defineComponent } from 'vue'
import {
  CASE_COMMENT_ADDED,
  CAMERA_MOTION_DETECTED,
  DEVICE_STATUS_UPDATED,
  DIGITAL_INPUT,
  VCA_EVENT,
} from '@/constants/notificationTypes.js'

export default defineComponent({
  name: 'NotificationTypeIcons',
  props: {
    notiType: {
      type: Number,
      required: true,
    },
    unread: {
      type: Boolean,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    caseCommentAdded() {
      return CASE_COMMENT_ADDED
    },
    cameraMotionDetected() {
      return CAMERA_MOTION_DETECTED
    },
    deviceStatusUpdated() {
      return DEVICE_STATUS_UPDATED
    },
    digitalInput() {
      return DIGITAL_INPUT
    },
    vcaEvent() {
      return VCA_EVENT
    },
  },
})
