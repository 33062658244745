
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'

export default defineComponent({
  name: 'DealerUserDesktopNav',
  props: {
    dealerViewingUser: {
      type: Boolean,
      required: true,
    },
    showDealerMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AccountMenu,
  },
  methods: {
    routeToDashboard() {
      this.$router.push('/dashboard')
    },
  },
  computed: {
    tabsModel: {
      /* eslint-disable vue/return-in-computed-property */
      // TODO: fix this computed prop
      set() {},
      get() {
        if (this.navTabsOptional) return ''
      },
    },
    /* eslint-enable vue/return-in-computed-property */
  },
})
