import httpClient from './httpClient'

export default {
  Comment: {
    Add(payload) {
      return httpClient.post('Comment/Add', payload)
    },
    Get(payload) {
      return httpClient.post('Comment/Get', payload)
    },
    Update(payload) {
      return httpClient.post('Comment/Update', payload)
    },
    Delete(payload) {
      return httpClient.post('Comment/Delete', payload)
    },
  },
}
