
import { defineComponent } from 'vue'
import {
  BROWSER_COMPAT_CLICK,
  PRIVACY_POLICY_CLICK,
} from '@/constants/mixpanelEvents'
export default defineComponent({
  methods: {
    openTermsDialog() {
      this.$emit('openTermsDialog')
    },
    openBrowserCompatDialog() {
      this.$mixpanelUserTrack(BROWSER_COMPAT_CLICK)
      this.$emit('openBrowserCompatDialog')
    },
    privacyPolicyClick() {
      this.$mixpanelUserTrack(PRIVACY_POLICY_CLICK)
    },
  },
  computed: {
    currentYear() {
      let d = new Date()
      return d.getFullYear()
    },
  },
})
