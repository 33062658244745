import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): ThumbnailState => {
  return {
    thumbnailLoading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<ThumbnailState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setThumbnailLoading(state, value) {
    state.thumbnailLoading = value
  },
}

const actions: ActionTree<ThumbnailState, RootState> = {
  async getLatestThumbnail({ commit }, payload) {
    commit('setThumbnailLoading', true)
    return repository.Thumbnail.GetLatestThumbnail(payload)
      .catch(() => {})
      .finally(() => {
        commit('setThumbnailLoading', false)
      })
  },
  async getThumbnail({ commit }, payload) {
    commit('setThumbnailLoading', true)
    return repository.Thumbnail.GetThumbnail(payload)
      .catch(() => {})
      .finally(() => {
        commit('setThumbnailLoading', false)
      })
  },
  async getPagedThumbnails({ commit }, payload) {
    commit('setThumbnailLoading', true)
    return repository.Thumbnail.GetPagedThumbnails(payload)
      .catch(() => {})
      .finally(() => {
        commit('setThumbnailLoading', false)
      })
  },
}

const Thumbnail: Module<ThumbnailState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Thumbnail

interface ThumbnailState {
  thumbnailLoading: boolean
}
