const getDefaultState = () => {
  return {
    messages: [],
    connected: false,
    error: null,
    downloadReady: false,
    downloadPayload: '',
    downloadError: false,
  }
}

const state = getDefaultState()

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  addMessage(state, value) {
    state.messages.push(value)
  },
  deleteMessage(state, value) {
    state.messages = state.messages.filter((m) => m.id !== value.id)
  },
  clearMessages(state) {
    state.messages = []
  },
  setConnection(state, value) {
    state.connected = value
  },
  setError(state, value) {
    state.error = value
  },
  setDownloadReady(state, value) {
    state.downloadReady = value
  },
  setDownloadPayload(state, value) {
    state.downloadPayload = value
  },
  setDownloadError(state, value) {
    state.downloadError = value
  },
}

const actions = {
  callAddMessage({ commit }, payload) {
    commit('addMessage', payload)
  },
  callDeleteMessage({ commit }, payload) {
    commit('deleteMessage', payload)
  },
  connectionOpened({ commit }) {
    commit('setConnection', true)
  },
  connectionClosed({ commit }) {
    commit('setConnection', false)
  },
  connectionError({ commit }, error) {
    commit('setError', error)
  },
  callClearMessages({ commit }) {
    commit('clearMessages')
  },
  callDownloadReady({ commit }, payload) {
    commit('setDownloadReady', true)
    commit('setDownloadPayload', payload)
  },
  callDownloadError({ commit }) {
    commit('setDownloadReady', false)
    commit('setDownloadError', true)
  },
  resetDownload({ commit }) {
    commit('setDownloadReady', false)
    commit('setDownloadPayload', '')
    commit('setDownloadError', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
