// Customer Users
export const ADD_CASE_COMMENTS = 'Add Case Comments'
export const CREATE_EDIT_CASE = 'Create/Edit Case'
export const CREATE_EDIT_SCHEDULES = 'Create/Edit Schedules'
export const DELETE_CASE_CLIP = 'Delete Case/Clip'
export const EDIT_CAMERA_SETTINGS = 'Edit Camera Settings'
export const ENABLE_DISABLE_DEALER_USERS = 'Enable/Disable Dealer Users'
export const SHARE_CASE = 'Share Case'
export const VIEW_CASE = 'View Case'
export const VIEW_CUSTOMERS = 'View Customers'
export const VIEW_VIDEO = 'View Video'

// Dealer Users
export const CREATE_EDIT_CUSTOMERS = 'Create/Edit Customers'
export const CREATE_EDIT_CUSTOMER_LOCATIONS = 'Create/Edit Customer Locations'
export const CREATE_EDIT_CUSTOMER_USERS = 'Create/Edit Customer Users'
export const CREATE_EDIT_DEALER_USERS = 'Create/Edit Dealer Users'
export const CREATE_EDIT_DEVICES = 'Create/Edit Devices'
export const LOGIN_TO_CUSTOMER_APP = 'Login to Customer App'
export const VIEW_CUSTOMER_LOCATIONS = 'View Customer Locations'
export const VIEW_CUSTOMER_USERS = 'View Customer Users'
export const VIEW_DEVICES = 'View Devices'

// Super Admin
export const CREATE_EDIT_DEALERS = 'Create/Edit Dealers'
export const SUPER_CREATE_EDIT_CUSTOMERS = 'Create/Edit Customers'
export const CREATE_EDIT_INTERNAL_USERS = 'Create/Edit Internal Users'
export const VIEW_DEALERS = 'View Dealers'
