export default [
  {
    path: '/shared-cases',
    name: 'SharedCases',
    component: () =>
      import(
        /*webpackChunkName: "SharedCases" */ '@/views/sharedCases/LandingPage.vue'
      ),
    props: true,
    meta: {
      title: 'Shared Cases',
      allowAnonymous: true,
      customerOnly: false,
      showHeader: true,
      showFooter: true,
      externalOnly: true,
      internalOnly: false,
    },
  },
  {
    path: '/shared-cases/:id',
    name: 'SharedCase',
    component: () =>
      import(/*webpackChunkName: "SharedCases" */ '@/views/case/Case.vue'),
    props: true,
    meta: {
      title: 'Case',
      allowAnonymous: true,
      customerOnly: false,
      showHeader: true,
      showFooter: true,
      externalOnly: true,
      internalOnly: false,
    },
  },
]
