import Vue from 'vue'
import VueKonva from 'vue-konva'
import ToastMessage from '@/components/global/ToastMessage.vue'
import VClamp from 'vue-clamp'
import browserDetect from 'vue-browser-detect-plugin'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import CountryFlag from 'vue-country-flag'
import VuePaycard from 'vue-paycard'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './utils/filters'
import mixpanelMixin from './mixins/mixpanel'
import auditMixin from './mixins/audit'
import i18n from './i18n'

import IdleVue from 'idle-vue'

const USER_INACTIVITY_TIMEOUT = 3600 * 1000 // 1 hour

Vue.use(VuePaycard)
Vue.use(VueTelInputVuetify, { vuetify })
Vue.use(VueKonva)
Vue.use(IdleVue, {
  idleTime: USER_INACTIVITY_TIMEOUT, // 1 hour
  eventEmitter: new Vue(),
  startAtIdle: false,
  events: ['mousedown'],
})
// @ts-ignore
Vue.use(browserDetect)

Vue.component('VueSlider', VueSlider)
Vue.component('country-flag', CountryFlag)
Vue.component('v-clamp', VClamp)
Vue.component('ToastMessage', ToastMessage)
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('vue-timepicker', VueTimepicker)

Vue.mixin(mixpanelMixin)
Vue.mixin(auditMixin)

Vue.config.productionTip = true

const logoutUser = () => {
  if (!router.currentRoute.meta.allowAnonymous) {
    router.push({
      name: 'Logout',
      query: { redirect: router.currentRoute.path },
    })
  } else if (router.currentRoute.meta.externalOnly) {
    if (router.currentRoute.name == 'SharedCases') {
      store.dispatch('ExternalCases/logout').then(() => {
        store.dispatch('ExternalCases/clearSharedCases')
      })
    } else {
      //@ts-ignore
      if (store.state.ExternalCases.shareQueryString == '') {
        router.push({
          name: 'SharedCases',
        })
      } else {
        store.dispatch('ExternalCases/logout').then(() => {
          router.push({
            name: 'SharedCases',
            //@ts-ignore
            query: { h: store.state.ExternalCases.shareQueryString },
          })
        })
      }
    }
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  //@ts-ignore
  onIdle: logoutUser,
  render: (h) => h(App),
}).$mount('#app')
