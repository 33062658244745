
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import SignUpForm from './SignUpForm.vue'
import ResendWelcomeEmail from './ResendWelcomeEmail.vue'
import { SIGNUP_PAGE_VIEWED, CREATE_ACCOUNT } from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'SignUpContainer',
  components: {
    TwoColumnLayout,
    SignUpForm,
    ResendWelcomeEmail,
  },
  data() {
    return {
      signUpToken: '',
      showResendEmail: false,
    }
  },
  computed: {
    ...mapState('SignUp', [
      'signUpSuccess',
      'signUpTokenIsValid',
      'signUpTokenIsValidLoading',
      'signUpEmailAddress',
    ]),
    ...mapState('Security', ['user']),
    email() {
      return this.signUpEmailAddress
    },
  },
  methods: {
    ...mapActions('SignUp', ['checkSignUpToken', 'sendWelcomeEmail']),
    callSendWelcomeEmail() {
      this.sendWelcomeEmail({
        VerificationToken: this.signUpToken,
      })
    },
  },
  watch: {
    signUpSuccess() {
      if (this.signUpSuccess) {
        this.$mixpanelTrack(CREATE_ACCOUNT, {
          'Account Created': true,
        })
        this.$router.push('/login')
      }
    },
  },
  created() {
    this.$mixpanelTrack(SIGNUP_PAGE_VIEWED)
    this.signUpToken = this.$router.currentRoute.query.id

    this.checkSignUpToken({
      VerificationToken: this.signUpToken,
    }).then(() => {
      if (!this.signUpTokenIsValid) {
        this.showResendEmail = true
      }
    })
  },
})
