import repository from '@/repository'

const getDefaultState = () => {
  return {
    recentCases: [],
    recentCasesIsLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  recentCases: (state) => {
    return state.recentCases
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setRecentCases(state, value) {
    state.recentCases = value
  },
  setRecentCasesIsLoading(state, value) {
    state.recentCasesIsLoading = value
  },
}

const actions = {
  getRecentCases({ commit }, payload) {
    commit('setRecentCasesIsLoading', true)
    return repository.Case.GetRecentCases(payload)
      .then((res) => {
        commit('setRecentCases', res.data.items)
        commit('Case/setAllCases', res.data.items, { root: true })
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setRecentCasesIsLoading', false)
      })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
