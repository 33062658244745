import httpClient from './httpClient'
export default {
  Customer: {
    GetCustomers(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Customer/GetCustomers', payload)
    },
    Add(payload) {
      return httpClient.post('/Customer/Add', payload)
    },
    Update(payload) {
      return httpClient.post('/Customer/Update', payload)
    },
    UpdatePrimaryUserContact(payload) {
      return httpClient.post('/Customer/UpdatePrimaryUserContact', payload)
    },
    SetPrimary(payload) {
      return httpClient.post('/Customer/SetPrimary', payload)
    },
    GetUsers(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/Customer/GetUsers', payload)
    },
    GetDealerUsersByCustomer(payload) {
      return httpClient.post('/Customer/GetDealerUsersByCustomer', payload)
    },
    EnableDisableDealerUsersByCustomer(payload) {
      return httpClient.post(
        '/Customer/EnableDisableDealerUsersByCustomer',
        payload
      )
    },
    GetStorageUsage(payload) {
      return httpClient.post('Customer/GetStorageUsage', payload)
    },
  },
}
