import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = () => {
  return {
    caseSearch: '',
    selectedCaseStatusFilter: [],
    selectedLocationFilter: [],
    selectedIncidentTypeFilter: '',
    selectedDateFilter: 0,
    customDateFilter: [],
  }
}

const state = getDefaultState()

const mutations: MutationTree<LibraryState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCaseStatusFilter(state, value) {
    state.selectedCaseStatusFilter = value
  },
  setLocationFilter(state, value) {
    state.selectedLocationFilter = value
  },
  setIncidentTypeFilter(state, value) {
    state.selectedIncidentTypeFilter = value
  },
  setDateFilter(state, value) {
    state.selectedDateFilter = value
  },
  setCustomDateFilter(state, value) {
    state.customDateFilter = value
  },
}

const actions: ActionTree<LibraryState, RootState> = {
  resetFilters({ commit }) {
    commit('setDefaultCaseStatus')
    commit('setAllCaseStatusSelected', true)
  },
  updateCaseStatusFilter({ commit }, status) {
    commit('setCaseStatusFilter', status)
  },
  updateCaseLocationFilter({ commit }, payload) {
    commit('setLocationFilter', payload)
  },
  updateIncidentTypeFilter({ commit }, payload) {
    commit('setIncidentTypeFilter', payload)
  },
  updateDateFilter({ commit }, payload) {
    commit('setDateFilter', payload)
  },
  updateCustomDateFilter({ commit }, payload) {
    commit('setCustomDateFilter', payload)
  },
}

const Library: Module<LibraryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Library

interface LibraryState {
  caseSearch: string
  selectedCaseStatusFilter: CaseStatusFilter[]
  selectedLocationFilter: LocationFilter[]
  selectedIncidentTypeFilter: string
  selectedDateFilter: number
  customDateFilter: DateFilter[]
}

interface CaseStatusFilter {
  status: string
}
interface DateFilter {
  date: Date
}
interface LocationFilter {
  locationId: number
}
