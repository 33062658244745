
import { defineComponent } from 'vue'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import { mapActions, mapState } from 'vuex'
export default defineComponent({
  name: 'Outage',
  components: {
    TwoColumnLayout,
  },
  computed: {
    ...mapState('Security', [
      'checkSystemStatusLoading',
      'systemStatusIsOnline',
    ]),
  },
  methods: {
    ...mapActions('Security', ['checkSystemStatus', 'callSetSystemStatus']),
    async checkStatus() {
      await this.checkSystemStatus()
    },
  },
  created() {
    this.callSetSystemStatus(false)
  },
  watch: {
    systemStatusIsOnline() {
      if (this.systemStatusIsOnline) {
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      }
    },
  },
})
