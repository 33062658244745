import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function isLocalizableString(instance) {
  return (
    typeof instance == 'object' &&
    'key' in instance &&
    'parameters' in instance &&
    Array.isArray(instance.parameters)
  )
}

//Resource map used by automation testers to display i18n keys instead of values to be used in selectors
const enableResourceMap = localStorage.getItem('showResourceKey') == 'true'
//Install decorated $t that can handle LocalizableString structure from webapi
Vue.use({
  install() {
    Vue.prototype.$$t = Vue.prototype.$t
    Vue.prototype.$t = function (key, parameters) {
      if (enableResourceMap) return key
      let fallback = key
      if (isLocalizableString(key)) {
        fallback = key.fallback
        parameters = key.parameters
        key = key.key
      }

      const lookup = this.$$t(key, parameters)
      return lookup.endsWith(key) ? fallback : lookup
    }
  },
})

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /([A-Za-z0-9-_,\s]+)(\..*)?\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}

const enablePrefixing = localStorage.getItem('prefixI18n') == 'true'

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  postTranslation: enablePrefixing ? prefixTranslations : undefined,
})

function prefixTranslations(message) {
  if (enableResourceMap) return message
  return `_${message}`
}
