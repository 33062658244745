import httpClient from './httpClient'

export default {
  Schedule: {
    Schedules(payload) {
      return httpClient.post('/Schedule/Schedules', payload)
    },
    UpdateSchedule(payload) {
      return httpClient.post('/Schedule/UpdateSchedule', payload)
    },
    NonStandardScheduleDefinitions(payload) {
      return httpClient.post(
        '/Schedule/NonStandardScheduleDefinitions',
        payload
      )
    },
    UpdateNonStandardScheduleDefinition(payload) {
      return httpClient.post(
        '/Schedule/UpdateNonStandardScheduleDefinition',
        payload
      )
    },
    UpdateScheduleStatus(payload) {
      return httpClient.post('/Schedule/UpdateScheduleStatus', payload)
    },
  },
}
