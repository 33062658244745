
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TermsComponent',
  props: {
    termsAgreed: {
      type: Boolean,
      required: true,
    },
    termsPage: {
      type: Boolean,
      required: false,
    },
    userClickOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    acceptTerms() {
      this.$emit('acceptTerms', !this.termsAgreed)
    },
    scrollTop() {
      this.$vuetify.goTo('.terms-component', {
        container: '.text-card',
        duration: 0,
      })
    },
  },
  mounted() {
    this.scrollTop()
  },
})
