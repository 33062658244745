import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): ToastMessageState => {
  return {
    toastMessage: '',
    toastMessageLineTwo: '',
    toastDownloadLink: '',
    showToastMessage: false,
    toastType: 'info',
    toastLink: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<ToastMessageState, RootState> = {
  toastMessage: (state) => {
    return state.toastMessage
  },
  showToastMessage: (state) => {
    return state.showToastMessage
  },
  toastType: (state) => {
    return state.toastType
  },
}

const mutations: MutationTree<ToastMessageState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  clearToast(state) {
    state.toastMessage = ''
    state.showToastMessage = false
    state.toastType = 'info'
    state.toastLink = ''
    state.toastMessageLineTwo = ''
    state.toastDownloadLink = ''
  },
  closeToast(state) {
    state.showToastMessage = false
  },
  setToast(state, value) {
    state.toastType = value.toastType
    state.toastMessage = value.toastMessage
    state.showToastMessage = true
    if (value.toastLink) {
      state.toastLink = value.toastLink
    } else {
      state.toastLink = ''
    }
    if (value.toastMessageLineTwo) {
      state.toastMessageLineTwo = value.toastMessageLineTwo
    } else {
      state.toastMessageLineTwo = ''
    }
    if (value.toastDownloadLink) {
      state.toastDownloadLink = value.toastDownloadLink
    } else {
      state.toastDownloadLink = ''
    }
  },
}

const actions: ActionTree<ToastMessageState, RootState> = {
  callClearToast({ commit }) {
    commit('clearToast')
  },
  callCloseToast({ commit }) {
    commit('closeToast')
    commit('clearToast')
  },
  callSuccessToast({ commit }, payload) {
    payload.toastType = 'success'
    commit('setToast', payload)
    setTimeout(() => {
      commit('closeToast')
    }, 15000)
  },
  callFailureToast({ commit }, payload) {
    payload.toastType = 'error'
    commit('setToast', payload)
    setTimeout(() => {
      commit('closeToast')
    }, 15000)
  },
  callInfoToast({ commit }, payload) {
    payload.toastType = 'info'
    commit('setToast', payload)
    setTimeout(() => {
      commit('closeToast')
    }, 15000)
  },
}

const ToastMessage: Module<ToastMessageState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default ToastMessage

interface ToastMessageState {
  toastMessage: string
  toastMessageLineTwo: string
  toastDownloadLink: string
  showToastMessage: boolean
  toastType: string
  toastLink: string
}
