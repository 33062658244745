import repository from '@/repository'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): SignUpState => {
  return {
    signUpIsLoading: false,
    signUpError: false,
    signUpSuccess: false,
    signUpErrorMessage: '',
    signUpTokenIsValid: false,
    signUpTokenIsValidLoading: false,
    signUpEmailAddress: '',
  }
}

const state = getDefaultState()

const mutations: MutationTree<SignUpState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setSignUpSuccess(state, value) {
    state.signUpSuccess = value
  },
  setSignUpIsLoading(state, value) {
    state.signUpIsLoading = value
  },
  setSignUpError(state, value) {
    state.signUpError = value
  },
  setSignUpErrorMessage(state, value) {
    state.signUpErrorMessage = value
  },
  setSignUpTokenIsValid(state, value) {
    state.signUpTokenIsValid = value
  },
  setSignUpTokenIsValidLoading(state, value) {
    state.signUpTokenIsValidLoading = value
  },
  setSignUpEmailAddress(state, value) {
    state.signUpEmailAddress = value
  },
}

const actions: ActionTree<SignUpState, RootState> = {
  async callSignUp({ commit }, payload) {
    commit('setSignUpIsLoading', true)
    return repository.User.ResetPassword(payload)
      .then(() => {
        commit('setSignUpError', false)
        commit('setSignUpSuccess', true)
      })
      .catch((err) => {
        commit('setSignUpError', true)
        commit('setSignUpSuccess', false)
        commit('setSignUpErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setSignUpIsLoading', false)
      })
  },
  async checkSignUpToken({ commit }, payload) {
    commit('setSignUpTokenIsValidLoading', true)
    commit('setSignUpEmailAddress', '')
    return repository.User.ValidateVerificationToken(payload)
      .then((res) => {
        commit('setSignUpTokenIsValid', res.data.isValid)
        commit('setSignUpEmailAddress', res.data.emailAddress)
      })
      .catch(() => {})
      .finally(() => {
        commit('setSignUpTokenIsValidLoading', false)
      })
  },
  sendWelcomeEmail(_, payload) {
    return repository.User.SendWelcomeEmail(payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

interface SignUpState {
  signUpIsLoading: boolean
  signUpError: boolean
  signUpSuccess: boolean
  signUpErrorMessage: string
  signUpTokenIsValid: boolean
  signUpTokenIsValidLoading: boolean
  signUpEmailAddress: string
}
