import httpClient from './httpClient'

export default {
  Thumbnail: {
    GetThumbnail(payload) {
      return httpClient.post('/Thumbnail/GetThumbnail', payload)
    },
    GetPagedThumbnails(payload) {
      return httpClient.post('/Thumbnail/GetPagedThumbnails', payload)
    },
    GetLatestThumbnail(payload) {
      return httpClient.post('/Thumbnail/GetLatestThumbnail', payload)
    },
  },
}
