import { render, staticRenderFns } from "./TermsOfUse.vue?vue&type=template&id=568ffcb5&"
import script from "./TermsOfUse.vue?vue&type=script&lang=ts&"
export * from "./TermsOfUse.vue?vue&type=script&lang=ts&"
import style0 from "./TermsOfUse.vue?vue&type=style&index=0&id=568ffcb5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VCol,VRow,VToolbarTitle})
