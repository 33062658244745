
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'DealerBanner',
  computed: {
    ...mapState('Security', [
      'dealerViewingUser',
      'dealerViewingLocation',
      'dealerViewingUserFromDashboard',
      'dealerCustomerId',
      'dealerLocationId',
      'user',
    ]),
  },
  methods: {
    ...mapActions('Security', ['callSetDealerViewingUser']),
    backToDealer() {
      let payload = {
        dealerViewingUser: false,
        dealerCustomerId: 0,
        dealerLocationId: 0,
        dealerViewingLocation: '',
        dealerViewingUserFromDashboard: false,
      }
      if (this.dealerViewingUserFromDashboard) {
        this.$router.push({ name: 'Dashboard' })
      } else if (this.dealerLocationId > 0) {
        this.$router.push({
          name: 'CustomerLocation',
          params: {
            locationId: this.dealerLocationId,
            id: this.dealerCustomerId,
          },
        })
      } else if (this.dealerCustomerId == 0) {
        this.$router.push('/dashboard')
      } else {
        this.$router.push('/customers/' + parseInt(this.dealerCustomerId))
      }
      this.callSetDealerViewingUser(payload)
    },
  },
})
