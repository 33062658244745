import httpClient from './httpClient'

export default {
  Case: {
    CreateArtifact(payload) {
      return httpClient.post('/Case/CreateArtifact', payload)
    },
    GetCases(payload) {
      return httpClient.post('/Case/GetCases', payload)
    },
    GetCase(payload) {
      return httpClient.post('/Case/GetCase', payload)
    },
    GetRecentActivity(payload) {
      return httpClient.post('/Case/GetRecentActivity', payload)
    },
    GetRecentCases(payload) {
      return httpClient.post('/Case/GetRecentCases', payload)
    },
    CreateCase(payload) {
      return httpClient.post('/Case/CreateCase', payload)
    },
    GetCaseIncidentTypes() {
      return httpClient.post('/Case/GetCaseIncidentTypes')
    },
    GetCountsByLocation(payload) {
      return httpClient.post('/Case/GetCountsByLocation', payload)
    },
    AddClip(payload) {
      return httpClient.post('/Case/AddClip', payload)
    },
    Update(payload) {
      return httpClient.post('Case/Update', payload)
    },
    RetryArtifact(payload) {
      return httpClient.post('Case/RetryArtifact', payload)
    },
    DeleteCase(payload) {
      return httpClient.post('Case/Delete', payload)
    },
    CreateOrUpdateCaseShare(payload) {
      return httpClient.post(
        'ExternalUserCase/CreateOrUpdateCaseShare',
        payload
      )
    },
    GetExternalUserByCase(payload) {
      return httpClient.post('ExternalUserCase/GetExternalUserByCase', payload)
    },
    DeleteCaseShare(payload) {
      return httpClient.post('ExternalUserCase/DeleteCaseShare', payload)
    },
  },
}
