import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      chargify: new window.Chargify(),
      paymentType: 'card',
      token: '',
      tokenError: '',
      selector: '#chargify-form',
    }
  },
  computed: {
    ...mapGetters('Environment', ['chargifySettings']),
    publicKey() {
      return this.chargifySettings.publicKey
    },
    serverHost() {
      return this.chargifySettings.endpoint
    },
    isTokenError() {
      return this.tokenError != ''
    },
  },
  methods: {
    ...mapMutations('Payment', ['setAddCardIsLoading']),
    ...mapActions('Payment', ['addNewCard']),
    async submitCardInfo(selector) {
      this.setAddCardIsLoading(true)
      return new Promise((resolve, reject) => {
        this.chargify.token(
          document.querySelector(`#${selector}`),
          (token) => {
            this.token = token
            resolve(token)
          },
          (error) => {
            this.tokenError = error
            reject(error)
          }
        )
      })
      //})
    },
    loadChargify(type) {
      let fields = {}
      this.paymentType = type
      if (this.paymentType == 'bank') {
        fields = {
          firstName: {
            selector: '#ach_firstName',
            placeholder: this.$t('dealer.chargify.achFormFields.firstName'),
            required: true,
            message: '',
            maxlength: '30',
            style: {
              label: { display: 'none' },
            },
          },
          lastName: {
            selector: '#ach_lastName',
            placeholder: this.$t('dealer.chargify.achFormFields.lastName'),
            required: true,
            message: '',
            maxlength: '30',
            style: {
              label: { display: 'none' },
            },
          },
          bankName: {
            selector: '#ach_bankName',
            label: this.$t('dealer.chargify.achFormFields.bankName'),
            placeholder: this.$t('dealer.chargify.achFormFields.bankName'),
            required: true,
            message: this.$t('dealer.chargify.achFormFields.invalidBankName'),
            maxlength: '40',
            style: {
              input: {
                pointer: 'cursor',
                backgroundColor: 'red !important',
                background: 'red !important',
                margin: '0px',
                color: 'black',
                padding: '0px 12px 0px 12px',
                height: '32px',
              },
              label: {
                display: 'none',
              },
            },
          },
          accountHolderType: {
            selector: '#ach_accountHolderType',
            label: this.$t('dealer.chargify.achFormFields.accountHolderType'),
            placeholder: this.$t(
              'dealer.chargify.achFormFields.accountHolderType'
            ),
            required: true,
            message: '',
            style: {
              input: {
                pointer: 'cursor',
                backgroundColor: 'red !important',
                background: 'red !important',
                margin: '0px',
                color: 'black',
                padding: '0px 4px 0px 4px',
                height: '32px',
              },
              field: {
                marginBottom: '0px',
                marginRight: '15px',
                height: '33px',
                backgroundColor: 'white',
              },
              label: {
                display: 'none',
              },
              message: {
                overflow: 'hidden',
              },
            },
          },
          routingNumber: {
            selector: '#ach_routingNumber',
            placeholder: this.$t('dealer.chargify.achFormFields.routingNumber'),
            required: true,
            message: this.$t(
              'dealer.chargify.achFormFields.invalidRoutingNumber'
            ),
            maxlength: '9',
            style: {
              label: { display: 'none' },
            },
          },
          accountNumber: {
            selector: '#ach_accountNumber',
            placeholder: this.$t('dealer.chargify.achFormFields.accountNumber'),
            required: true,
            message: this.$t(
              'dealer.chargify.achFormFields.invalidAccountNumber'
            ),
            maxlength: '17',
            style: {
              label: { display: 'none' },
            },
          },
        }
      } else {
        fields = {
          firstName: {
            selector: '#first_name',
            placeholder: this.$t('dealer.chargify.ccFormFields.firstName'),
            required: true,
            message: '',
            maxlength: '30',
            style: {
              label: { display: 'none' },
            },
          },
          lastName: {
            selector: '#last_name',
            placeholder: this.$t('dealer.chargify.ccFormFields.lastName'),
            required: true,
            message: '',
            maxlength: '30',
            style: {
              label: { display: 'none' },
            },
          },
          number: {
            selector: '#cc_number',
            label: 'Card Number',
            placeholder: this.$t('dealer.chargify.ccFormFields.cardNumber'),
            message: this.$t('dealer.chargify.ccFormFields.invalidCard'),
            required: true,
            style: {
              input: {
                padding: '8px 48px',
              },
              field: {
                marginBottom: '0px',
              },
            },
          },
          month: {
            selector: '#cc_month',
            label: '',
            placeholder: this.$t(
              'dealer.chargify.ccFormFields.monthPlaceholder'
            ),
            message: this.$t('dealer.chargify.ccFormFields.invalidMonth'),
            required: true,
            style: {
              field: {
                height: '60px',
                marginBottom: '0px',
                overflow: 'hidden',
              },
            },
          },
          year: {
            selector: '#cc_year',
            label: '',
            placeholder: this.$t(
              'dealer.chargify.ccFormFields.yearPlaceholder'
            ),
            message: this.$t('dealer.chargify.ccFormFields.invalidYear'),
            required: true,
            style: {
              field: {
                height: '60px',
                marginBottom: '0px',
                overflow: 'hidden',
              },
            },
          },
          cvv: {
            selector: '#cc_cvv',
            label: this.$t('dealer.chargify.ccFormFields.cvv'),
            placeholder: this.$t('dealer.chargify.ccFormFields.cvv'),
            message: this.$t('dealer.chargify.ccFormFields.invalidCVV'),
            required: true,
            style: {
              field: {
                height: '60px',
                marginBottom: '0px',
                overflow: 'hidden',
              },
            },
          },
          country: {
            selector: '#billing_country',
            label: this.$t('dealer.chargify.ccFormFields.country'),
            placeholder: this.$t('dealer.chargify.ccFormFields.selectCountry'),
            message: this.$t('dealer.chargify.ccFormFields.selectCountry'),
            required: true,
            maxlength: '2',
            style: {
              input: {
                pointer: 'cursor',
                backgroundColor: 'red !important',
                background: 'red !important',
                margin: '0px',
                color: 'black',
                //padding: '0px 12px 0px 12px',
                padding: '0px',
                height: '32px',
              },
              field: {
                marginBottom: '0px',
                marginRight: '15px',
                height: '33px',
                backgroundColor: 'white',
              },
              label: {
                display: 'none',
              },
            },
          },
          zip: {
            selector: '#billing_zip',
            placeholder: this.$t('admin.formFields.zipCode'),
            required: false,
            maxlength: '10',
            style: {
              field: {
                height: '60px',
                marginBottom: '0px',
                overflow: 'hidden',
              },
            },
          },
        }
      }
      this.chargify.load({
        //selector: this.selector, // tag selector goes here. need correct ID in template
        //selector: '#chargify1',
        publicKey: this.publicKey, // <- maybe a static public key? can probably keep this local. edit:nope, needs to come from api
        type: this.paymentType,
        serverHost: this.serverHost,
        optionalLabel: ' ',
        requiredLabel: '*',
        addressDropdowns: true,
        style: {
          field: {
            borderRadius: '2px',
            maxWidth: '100%',
            height: '50px',
          },
          input: {
            fontSize: '14px',
            borderRadius: '2px',
            width: '100%',
            padding: '8px 12px',
            height: '32px',
            placeholder: { color: '#666', fontWeight: '400' },
          },
          label: {
            display: 'none',
          },
          message: {
            paddingTop: '2px',
            paddingBottom: '1px',
          },
        },
        fields: fields,
      })
    },
  },
}
