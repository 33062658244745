import httpClient from './httpClient'

export default {
  ExternalUserCase: {
    Authenticate(payload) {
      return httpClient.post('ExternalUserCase/Authenticate', payload)
    },
    SendVerificationCode(payload) {
      return httpClient.post('ExternalUserCase/SendVerificationCode', payload)
    },
    GetSharedCases() {
      return httpClient.post('ExternalUserCase/GetSharedCases')
    },
    Logout(payload) {
      return httpClient.post('ExternalUserCase/Logout', payload)
    },
  },
}
