import router from '@/router'
import axios from 'axios'
import store from '@/store'

const apiRoot = '/api'

const httpClient = axios.create({
  baseURL: apiRoot,
  timeout: 30000,
  withCredentials: true,
})
// Record the API that is initiating the request
// to clear these APIs during routing jumps
window.__axiosPromiseArr = []
httpClient.interceptors.request.use(
  (config) => {
    // TODO: this should probably be reworked with better handling of cancel api
    if (
      config.url != '/Status' && //don't allow cancellation of /Status which hosts feature flags (refreshed from App.vue)
      config.url != '/Environment' && //hosts environment settings
      config.url != '/User/Logout' &&
      config.url != '/User/GetCurrent'
    ) {
      config.cancelToken = new axios.CancelToken((c) => {
        window.__axiosPromiseArr.push(c)
      })
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    let config = error.config
    // https://github.com/axios/axios/issues/164#issuecomment-327837467
    if (config && config.retry) {
      if (
        config.url === '/Device/FormatSDCard' &&
        error.response.data &&
        error.response.data.message != 'Unauthorized'
      ) {
        return Promise.reject(error)
      }
      config.__retryCount = config.__retryCount || 0
      if (config.__retryCount < config.retry) {
        config.__retryCount += 1
        let backoff = new Promise(function (resolve) {
          setTimeout(function () {
            resolve()
          }, config.retryDelay || 1)
        })
        return backoff.then(function () {
          return httpClient(config)
        })
      }
    }

    let statusCode = error.response.status
    if (statusCode == 404 || statusCode == 403) {
      if (
        router.currentRoute.name == 'Dashboard' ||
        router.currentRoute.name == 'Login'
      ) {
        return Promise.reject(error)
      }
      store.dispatch('Security/getOwnUser').then(() => {
        if (store.state.Security.user.isDealerUser) {
          router.push('/dashboard')
          let payload = {
            dealerViewingUser: false,
            dealerViewingLocation: '',
            dealerCustomerId: 0,
          }
          store.dispatch('Security/callSetDealerViewingUser', payload)
        } else if (store.state.Security.user.isInternalUser) {
          router.push('/dealers')
        } else {
          router.push('/dashboard')
        }
      })
    } else if (statusCode == 401) {
      if (
        router.currentRoute.name != 'Login' &&
        router.currentRoute.name != 'Logout' &&
        router.currentRoute.name != 'SharedCases'
      ) {
        router.push({
          name: 'Logout',
          query: { redirect: router.currentRoute.path, query: router.query },
        })
      } else {
        return Promise.reject(error)
      }
    } else if (statusCode == 503 || statusCode == 502) {
      // set outage mode
      store.dispatch('Security/callSetSystemStatus', false)
      router.push('/outage')
    }
    return Promise.reject(error)
  }
)

export default httpClient
