
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PrivacyPolicyComponent',
  props: {},
  methods: {},
  computed: {},
  mounted() {
    this.$vuetify.goTo(0)
  },
})
