import repository from '@/repository'

const getDefaultState = () => {
  return {
    usersList: [],
    usersListLoading: false,
    rolesList: [],
    addUserIsLoading: false,
    addUserError: false,
    addUserErrorMessage: '',
    userListErrorMessage: '',
    createdUserId: 0,
    getRolesError: '',
    userDeleteIsLoading: false,
    userDeleteSuccess: false,
    userDeleteErrorMessage: '',
    userEnableDisableIsLoading: false,
    userEnableDisableSuccess: false,
    userEnableDisableErrorMessage: '',
    rolePermissionUsersList: [],
    rolePermissionUsersListLoading: false,
    updateUserInfoIsLoading: false,
    updateUserInfoErrorMessage: '',
    updateUserInfoSuccess: false,
    rolePermissionsList: [],
    getRolePermissionsListErrorMessage: '',
    rolePermissionsListIsLoading: false,
    dealerDetailObject: {},
    dealerDetailObjectIsLoading: false,
    dealerDetailObjectError: '',
    countrysList: [],
  }
}

const state = getDefaultState()

const getters = {
  currentUser: (state) => (userId) => {
    let selectedUser = state.usersList.find((user) => {
      return user.userId === userId
    })
    if (typeof selectedUser === 'undefined') return 0
    else return selectedUser
  },
  primaryContact: (state) => {
    let primaryContact = state.usersList.find((user) => {
      return user.isPrimaryContact
    })
    if (typeof primaryContact === 'undefined') return 0
    else return primaryContact
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setUsersList(state, value) {
    state.usersList = value
  },
  setUsersListLoading(state, value) {
    state.usersListLoading = value
  },
  setRolesList(state, value) {
    state.rolesList = value
  },
  setAddUserIsLoading(state, value) {
    state.addUserIsLoading = value
  },
  setAddUserError(state, value) {
    state.addUserError = value
  },
  setAddUserErrorMessage(state, value) {
    state.addUserErrorMessage = value
  },
  setUserListErrorMessage(state, value) {
    state.userListErrorMessage = value
  },
  setCreatedUserId(state, value) {
    state.createdUserId = value
  },
  setGetRolesError(state, value) {
    state.getRolesError = value
  },
  setUserDeleteIsLoading(state, value) {
    state.userDeleteIsLoading = value
  },
  setUserDeleteSuccess(state, value) {
    state.userDeleteSuccess = value
  },
  setUserDeleteErrorMessage(state, value) {
    state.userDeleteErrorMessage = value
  },
  setUserEnableDisableIsLoading(state, value) {
    state.userEnableDisableIsLoading = value
  },
  setUserEnableDisableSuccess(state, value) {
    state.userEnableDisableSuccess = value
  },
  setUserEnableDisableErrorMessage(state, value) {
    state.userEnableDisableErrorMessage = value
  },
  setRolePermissionUsersList(state, value) {
    state.rolePermissionUsersList = value
  },
  setRolePermissionUsersListLoading(state, value) {
    state.rolePermissionUsersListLoading = value
  },
  setUpdateUserInfoIsLoading(state, value) {
    state.updateUserInfoIsLoading = value
  },
  setUpdateUserInfoErrorMessage(state, value) {
    state.updateUserInfoErrorMessage = value
  },
  setUpdateUserInfoSuccess(state, value) {
    state.updateUserInfoSuccess = value
  },
  setRolePermissionsList(state, value) {
    state.rolePermissionsList = value
  },
  setGetRolePermissionsListErrorMessage(state, value) {
    state.getRolePermissionsListErrorMessage = value
  },
  setRolePermissionsListIsLoading(state, value) {
    state.rolePermissionsListIsLoading = value
  },
  setDealerDetailObject(state, value) {
    state.dealerDetailObject = value
  },
  setDealerDetailObjectIsLoading(state, value) {
    state.dealerDetailObjectIsLoading = value
  },
  setDealerDetailObjectError(state, value) {
    state.dealerDetailObjectError = value
  },
  setPrimaryContact(state, value) {
    state.usersList.forEach((element) => {
      element.isPrimaryContact = false
      if (element.userId == value) {
        element.isPrimaryContact = true
      }
    })
    if (value == state.dealerDetailObject.userId) {
      state.dealerDetailObject.isPrimaryContact = true
    }
  },
  setDeleteUser(state, value) {
    state.usersList = state.usersList.filter((user) => {
      return user.userId !== value.userId
    })
  },
  setUpdateUserEnableDisable(state, value) {
    state.usersList.forEach((user) => {
      if (value.userId === user.userId) {
        user.isActive = !value.isActive
        if (user.isActive) {
          user.status = 'Enabled'
        } else {
          user.status = 'Disabled'
        }
      }
    })
  },
}

const actions = {
  getRoles({ commit }, payload) {
    return repository.Role.GetRoles(payload)
      .then((res) => {
        commit('setRolesList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.message)
      })
  },
  setPrimary({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.Dealer.SetPrimary(payload)
      .then(() => {
        commit('setPrimaryContact', payload.UserId)
        commit('setUpdateUserInfoSuccess', true)
      })
      .catch(() => {
        commit('setUpdateUserInfoSuccess', false)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  getRolesPermissions({ commit }, payload) {
    commit('setRolePermissionsListIsLoading', true)
    return repository.Role.GetRolesPermissions(payload)
      .then((res) => {
        commit('setRolePermissionsList', res.data.items)
      })
      .catch((err) => {
        commit(
          'setGetRolePermissionsListErrorMessage',
          err.response.data.message
        )
      })
      .finally(() => {
        commit('setRolePermissionsListIsLoading', false)
      })
  },
  addUser({ commit }, payload) {
    commit('setAddUserIsLoading', true)
    return repository.User.Add(payload)
      .then((res) => {
        commit('setCreatedUserId', res.data.userId)
      })
      .catch((err) => {
        commit('setAddUserError', true)
        commit('setAddUserErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddUserIsLoading', false)
      })
  },
  updateUserInfo({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.User.Update(payload)
      .then(() => {
        commit('setUpdateUserInfoSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateUserInfoSuccess', false)
        commit('setUpdateUserInfoErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  clearUpdateUserError({ commit }) {
    commit('setUpdateUserInfoErrorMessage', '')
    commit('setUpdateUserInfoSuccess', false)
  },
  getDealerAdminUsers({ commit }, payload) {
    commit('setUsersListLoading', true)
    commit('setUsersList', [])
    return repository.Dealer.GetUsers(payload)
      .then((res) => {
        let userListData = res.data.items
        userListData.forEach((user) => {
          if (user.isActive) {
            user.status = 'Enabled'
          } else {
            user.status = 'Disabled'
          }
        })
        commit('setUsersList', res.data.items)
      })
      .catch((err) => {
        commit('setUserListErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUsersListLoading', false)
      })
  },
  clearAddUserError({ commit }) {
    commit('setAddUserError', false)
  },
  deleteUser({ commit }, payload) {
    commit('setUserDeleteIsLoading', true)
    let apiPayload = {
      UserId: payload.userId,
    }
    return repository.User.DeleteUser(apiPayload)
      .then(() => {
        commit('setUserDeleteSuccess', true)
        commit('setDeleteUser', payload)
      })
      .catch((err) => {
        commit('setUserDeleteSuccess', false)
        commit('setUserDeleteErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserDeleteIsLoading', false)
      })
  },
  clearDeleteUserError({ commit }) {
    commit('setUserDeleteErrorMessage', '')
    commit('setUserDeleteSuccess', false)
  },
  enableDisableUser({ commit }, payload) {
    commit('setUserEnableDisableIsLoading', true)
    commit('setUpdateUserInfoIsLoading', true)
    let apiPayload = {
      UserId: payload.userId,
      IsEnabled: !payload.isActive,
    }
    return repository.User.EnableDisableUser(apiPayload)
      .then(() => {
        commit('setUserEnableDisableSuccess', true)
        commit('setUpdateUserEnableDisable', payload)
      })
      .catch((err) => {
        commit('setUserEnableDisableSuccess', false)
        commit('setUserEnableDisableErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserEnableDisableIsLoading', false)
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  clearEnableDisableUserError({ commit }) {
    commit('setUserEnableDisableErrorMessage', '')
    commit('setUserEnableDisableSuccess', false)
  },
  callResetPassword(_, payload) {
    return repository.User.ResetUserPassword(payload)
  },
  getRolePermissionUsers({ commit }, payload) {
    commit('setRolePermissionUsersListLoading', true)
    return repository.Role.GetRolesPermissionsUsers(payload)
      .then((res) => {
        let roleListData = res.data.items
        roleListData.forEach((role) => {
          role.roleType = 'default'
          let numberOfPermissionsAssigned = 0
          role.permissions.forEach((permission) => {
            if (permission.isAvailable) {
              numberOfPermissionsAssigned += 1
            }
          })
          role.numberOfPermissionsAssigned = numberOfPermissionsAssigned
          role.numberOfPeopleAssigned = role.users.length
          role.users.forEach((user) => {
            if (user.isActive) {
              user.status = 'Enabled'
            } else {
              user.status = 'Disabled'
            }
          })
        })
        commit('setRolePermissionUsersList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.message)
      })
      .finally(() => {
        commit('setRolePermissionUsersListLoading', false)
      })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
