export default [
  {
    path: '/dealers',
    name: 'SuperAdminDealerMGMT',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminDealerMGMT" */ '@/views/superAdmin/superAdminDealerManagement/SuperAdminDealerManagementContainer.vue'
      ),
    props: true,
    meta: {
      title: 'Dealer Management',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/dealers/:id',
    name: 'SuperAdminDealerDetail',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminDealerDetail" */ '@/views/superAdmin/superAdminDealerDetail/DealerDetail.vue'
      ),
    props: true,
    meta: {
      title: 'Dealer Detail',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/dealers/:id/dealer/:dealerUserId',
    name: 'SuperAdminDealerUserDetail',
    component: () =>
      import(
        /*webpackChunkName: "superAdminDealerUserDetail" */ '@/views/vigilUserDetail/VigilUserDetail.vue'
      ),
    meta: {
      title: 'Dealer User Detail',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
      superAdminUserDetail: false,
      customerUserDetail: false,
      dealerUserDetail: false,
      superAdminCustomerUserDetail: false,
      superAdminDealerUserDetail: true,
    },
  },
  {
    path: '/dealers/:dealerId/customers/:customerId',
    name: 'SuperAdminCustomerDetail',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminCustomerDetail" */ '@/views/dealerCustomerDetail/CustomerDetail.vue'
      ),
    props: true,
    meta: {
      title: 'Customer Details',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/dealers/:dealerId/customers/:customerId/user/:id',
    name: 'SuperAdminCustomerUserDetail',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminCustomerUserDetail" */ '@/views/vigilUserDetail/VigilUserDetail.vue'
      ),
    props: true,
    meta: {
      title: 'Customer User Details',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
      superAdminUserDetail: false,
      customerUserDetail: false,
      dealerUserDetail: false,
      superAdminCustomerUserDetail: true,
      superAdminDealerUserDetail: false,
    },
  },
  {
    path: '/dealers/:dealerId/customers/:customerId/location/:locationId',
    name: 'SuperAdminCustomerLocation',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminCustomerLocation" */ '@/views/dealerCustomerLocation/CustomerLocation.vue'
      ),
    props: true,
    meta: {
      title: 'Customer Location',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/admin-internal',
    name: 'SuperAdminAdmin',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminAdmin" */ '@/views/superAdmin/superAdminAdmin/SuperAdminAdmin.vue'
      ),
    props: true,
    meta: {
      title: 'Admin',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/admin-internal/detail/:id',
    name: 'SuperAdminUserDetail',
    component: () =>
      import(
        /*webpackChunkName: "SuperAdminUserDetail" */ '@/views/vigilUserDetail/VigilUserDetail.vue'
      ),
    props: true,
    meta: {
      title: 'Super Admin User Detail',
      allowAnonymous: false,
      internalOnly: true,
      showHeader: true,
      showFooter: true,
      superAdminUserDetail: true,
      customerUserDetail: false,
      dealerUserDetail: false,
      superAdminCustomerUserDetail: false,
      superAdminDealerUserDetail: false,
    },
  },
]
