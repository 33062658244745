import repository from '@/repository'
import { DeviceObject, Location } from '@/models'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): LocationsState => {
  return {
    cameraGridView: true,
    arrangeCameraMode: false,
    locationCamerasLoading: false,
    locationCameras: [],
    currentLocation: 0,
    locationsList: [],
    locationsListLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<LocationsState, RootState> = {
  currentCamera: (state) => (deviceId: number) => {
    const camera = state.locationCameras.find((device) => {
      return device['deviceId'] === deviceId
    })
    if (typeof camera === 'undefined') return 0
    else return camera
  },
  currentLocationObject: (state) => (locationID: number) => {
    const location = state.locationsList.find((loc) => {
      return loc['locationId'] === locationID
    })
    if (typeof location === 'undefined') return {}
    else return location
  },
}

const mutations: MutationTree<LocationsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCameraGridView(state, value) {
    state.cameraGridView = value
  },
  setLocationCamerasLoading(state, value) {
    state.locationCamerasLoading = value
  },
  setLocationCameras(state, value) {
    state.locationCameras = value
  },
  setArrangeCameraMode(state, value) {
    state.arrangeCameraMode = value
  },
  setCurrentLocationID(state, value) {
    state.currentLocation = value
  },
  setLocationsList(state, value) {
    state.locationsList = value
  },
  setLocationsListLoading(state, value) {
    state.locationsListLoading = value
  },
  updateCameraBookmarkStatus(state, value) {
    const deviceID = value.deviceId
    const currentDevice: DeviceObject = state.locationCameras.find(
      ({ deviceId }) => deviceId === deviceID
    )
    currentDevice.isBookMarked = value.isBookMarked
  },
  updateCameraUpdateStatus(state, value) {
    const deviceID = value.deviceId
    state.locationCameras = state.locationCameras.map((item) =>
      item.deviceId === deviceID ? value : item
    )
  },
  updateNVRCameraName(state, value) {
    const deviceID = value.deviceId
    return state.locationCameras.forEach((item) => {
      if (item.deviceId === deviceID) {
        item.deviceName = value.deviceName
      }
      return item
    })
  },
}
const actions: ActionTree<LocationsState, RootState> = {
  async getLocationsList({ commit }, payload) {
    commit('setLocationsListLoading', true)
    commit('setLocationsList', [])
    if (payload.customerId) {
      return repository.Location.GetCustomerLocationList(payload)
        .then((res) => {
          commit('setLocationsList', res.data.items)
        })
        .catch(() => {})
        .finally(() => {
          commit('setLocationsListLoading', false)
        })
    } else {
      return repository.Location.GetLocationList(payload)
        .then((res) => {
          commit('setLocationsList', res.data.items)
        })
        .catch(() => {})
        .finally(() => {
          commit('setLocationsListLoading', false)
        })
    }
  },
  async getCamerasByLocation({ commit }, payload) {
    commit('setLocationCamerasLoading', true)
    commit('setCurrentLocationID', payload.locationId)
    return repository.Device.GetDevicesByLocationID(payload)
      .then((res) => {
        commit('setLocationCameras', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setLocationCamerasLoading', false)
      })
  },
  updateLocationNVRCameraName({ commit }, payload) {
    commit('updateNVRCameraName', payload)
  },
  updateCameraInfo({ commit }, payload) {
    commit('updateCameraUpdateStatus', payload)
  },
  async getCameraUpdateStatus({ commit }, payload) {
    return repository.Device.GetDeviceByID(payload)
      .then((res) => {
        if (res.data) {
          commit('updateCameraUpdateStatus', res.data)
        }
      })
      .catch(() => {})
  },
  async getLocationsAndCameras({ dispatch, state }, payload) {
    dispatch('getLocationsList', payload)
    if (payload.locationId) {
      dispatch('getCamerasByLocation', payload)
    } else {
      const payload = {
        locationId: state.currentLocation,
        includeNVRItems: true,
        includeUnactivatedItems: false,
      }
      dispatch('getCamerasByLocation', payload)
    }
  },
  async bookmarkCamera({ commit }, payload) {
    return repository.Device.BookMarkDevice(payload)
      .then(() => {
        commit('updateCameraBookmarkStatus', payload)
      })
      .catch(() => {})
  },
  async playbackSourceCamera({ commit }, payload) {
    return repository.Device.UpdatePlaybackSource(payload)
      .then((res) => {
        if (res.data.item) {
          commit('updateCameraUpdateStatus', res.data.item)
        }
      })
      .catch(() => {})
  },
  getSingleLocation(_, payload) {
    return repository.Location.GetLocation(payload)
  },
}

const Locations: Module<LocationsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Locations

interface LocationsState {
  cameraGridView: boolean
  arrangeCameraMode: boolean
  locationCamerasLoading: boolean
  locationCameras: DeviceObject[]
  currentLocation: number
  locationsList: Location[]
  locationsListLoading: boolean
}
