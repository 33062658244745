
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import chargify from '@/mixins/chargify'

export default defineComponent({
  name: 'ACHEntryForm',
  mixins: [chargify],
  props: {
    titleText: {
      type: String,
      default: '',
    },
    subTitleText: {
      type: String,
      default: '',
    },
    showACHButtons: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardError: false,
      cardErrorMessage: '',
      localChargifyLoading: false,
      paymentLoading: false,
      herebyAuthorized: false,
    }
  },
  computed: {
    ...mapState('Payment', ['addCardError', 'addCardErrorMessage']),
    ccTitleText() {
      return this.$t('dealer.chargify.addPayment')
    },
    computedCardErrorMessage() {
      if (this.cardErrorMessage == '') {
        return this.addCardErrorMessage
      } else {
        if (Array.isArray(this.cardErrorMessage)) {
          let messageItem = this.cardErrorMessage[0]
          return messageItem.replace(':', '')
        } else {
          return this.cardErrorMessage
        }
      }
    },
    showACHHeader() {
      if (this.titleText == '' && this.subTitleText == '') {
        return false
      } else {
        return true
      }
    },
    dealerAdminOnly() {
      return true
    },
  },
  methods: {
    ...mapActions('Payment', [
      'addNewCard',
      'resetCardError',
      'callAddPaymentLoading',
      'callACHAuthorized',
    ]),
    decline() {
      if (this.showCloseButton) {
        this.closeDialog()
      } else {
        this.$emit('decline')
      }
    },
    ...mapActions('ToastMessage', ['callSuccessToast']),
    async addPaymentClick() {
      this.paymentLoading = true
      this.callAddPaymentLoading(this.paymentLoading)
      this.cardErrorMessage = ''
      this.cardError = false
      try {
        const token = await this.submitCardInfo('ach-chargify-form')
        let payload = {
          chargify_token: token,
        }
        await this.addNewCard(payload)
        if (!this.addCardError) {
          this.closeDialog()
          this.callSuccessToast({
            toastMessage: this.$t('dealer.chargify.paymentMethodAdded'),
          })
        }
        this.paymentLoading = false
        this.callAddPaymentLoading(this.paymentLoading)
      } catch (err) {
        if (err.errors) {
          this.cardError = true
          this.cardErrorMessage = err.errors
        }
        this.paymentLoading = false
        this.callAddPaymentLoading(this.paymentLoading)
      }
    },
    checkHerebyAuthorized(type) {
      this.callACHAuthorized(type)
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.chargify.unload()
    },
    reloadCard() {
      this.cardErrorMessage = ''
      this.cardError = false
      this.resetCardError()
      this.chargify.unload()
      this.loadChargifyWithLoading()
      this.herebyAuthorized = false
      this.callACHAuthorized(this.herebyAuthorized)
    },
    loadChargifyWithLoading() {
      this.localChargifyLoading = true
      this.loadChargify('bank')
      setTimeout(() => {
        this.localChargifyLoading = false
      }, 2000)
    },
  },
  mounted() {
    this.loadChargifyWithLoading()
    this.resetCardError()
  },
})
