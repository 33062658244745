import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): TimezoneState => {
  return {
    timezoneList: [],
    timezoneLoading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<TimezoneState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setTimezoneList(state, value) {
    state.timezoneList = value
  },
  setTimezoneLoading(state, value) {
    state.timezoneLoading = value
  },
}

const actions: ActionTree<TimezoneState, RootState> = {
  async getTimezoneList({ commit }, payload) {
    commit('setTimezoneLoading', true)
    commit('setTimezoneList', [])
    return repository.Timezone.getTimezone(payload)
      .then((res) => {
        commit('setTimezoneList', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setTimezoneLoading', false)
      })
  },
}

const Timezone: Module<TimezoneState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Timezone

interface TimezoneState {
  timezoneList: string[]
  timezoneLoading: boolean
}
