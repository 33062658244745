export default {
  data() {
    return {
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_too_many_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      valid_password: false,
    }
  },
  methods: {
    checkPassword() {
      this.password_length = this.password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password_length > 7) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }

      if (this.password_length >= 100) {
        this.contains_too_many_characters = true
      } else {
        this.contains_too_many_characters = false
      }

      this.contains_number = /\d/.test(this.password)
      this.contains_uppercase = /[A-Z]/.test(this.password)
      this.contains_lowercase = /[a-z]/.test(this.password)
      this.contains_special_character = format.test(this.password)

      let strengthArray = [
        this.contains_number,
        this.contains_uppercase,
        this.contains_lowercase,
        this.contains_special_character,
      ]

      let strengthArrayLength = strengthArray.filter((item) => item == true)
      let passesStrengthTest = strengthArrayLength.length >= 3

      if (
        this.contains_eight_characters &&
        !this.contains_too_many_characters &&
        passesStrengthTest
      ) {
        this.valid_password = true
      } else {
        this.valid_password = false
      }
    },
  },
}
