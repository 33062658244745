import repository from '@/repository'
import { RootState } from '@/store/types'
import { ActionTree, MutationTree } from 'vuex'

const getDefaultState = (): DashboardState => {
  return {
    dashboardIsLoading: false,
    deviceList: {
      inTrouble: [],
      offline: [],
      notActivated: [],
      deviceCount: 0,
    },
    customerList: [],
  }
}

const state = getDefaultState()

const mutations: MutationTree<DashboardState> = {
  setDealerDeviceList(state, value) {
    state.deviceList = value
  },
  setCustomerList(state, value) {
    state.customerList = value
  },
  setDashboardIsLoading(state, value) {
    state.dashboardIsLoading = value
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions: ActionTree<DashboardState, RootState> = {
  async getDealerDeviceList({ commit }, payload) {
    commit('setDashboardIsLoading', true)
    payload.includeCustomerList = true
    return repository.Dealer.GetDealerDeviceList(payload)
      .then((res) => {
        commit('setDealerDeviceList', res.data)
        commit('setCustomerList', res.data.customers)
      })
      .finally(() => {
        commit('setDashboardIsLoading', false)
      })
  },
  async getDealerDeviceListNoLoading({ commit }, payload) {
    payload.includeCustomerList = true
    return repository.Dealer.GetDealerDeviceList(payload).then((res) => {
      commit('setDealerDeviceList', res.data)
      commit('setCustomerList', res.data.customers)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
interface Customer {
  customerId: number
  customerName: string
}
interface DashboardDeviceList {
  inTrouble: DashboardDevice[]
  offline: DashboardDevice[]
  notActivated: DashboardDevice[]
  deviceCount: number
}
interface DashboardDevice {
  deviceId: number
  customerName: string
  deviceGroupID: number | null
  deviceGroupCode: string | null
  dealerViewable: boolean
  locationId: number
  location: string
  deviceName: string
  deviceStatus: string
}
interface DashboardState {
  dashboardIsLoading: boolean
  deviceList: DashboardDeviceList
  customerList: Customer[]
}
