import Vue from 'vue'
import { DateTime } from 'luxon'

export const formatCity = function (value: string) {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const formatDate = function (value: string) {
  if (!value) return ''
  const dt = DateTime.fromISO(value)
  if (!dt.isValid) return ''
  else return dt.toFormat('h:mm a, LLL d, yyyy')
}
export const formatLocalDate = function (value: string) {
  if (!value) return 'test'
  //utc to local
  const dt = DateTime.fromISO(value)
  if (!dt.isValid) return ''
  const local = dt.plus({ minutes: dt.offset })
  return local.toFormat('h:mm a, LLL d, yyyy')
}
export const formatShortDate = function (value: string) {
  if (!value) return ''
  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('M/d/yyyy')
}
export const formatShortDateUTC = function (value: string) {
  if (!value) return ''
  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('M/d/yyyy')
}
export const formatTime = function (value: string) {
  if (!value) return ''
  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('h:mm a')
}
export const formatTimeUTC = function (value: string) {
  if (!value) return ''
  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('h:mm a')
}
export const formatLocalTime = function (value: string) {
  if (!value) return ''
  const dt = DateTime.fromISO(value)
  return dt.toFormat('h:mm a')
}

export const formatDateAndTime = function (value: string) {
  if (!value) return ''
  const lastSpace = value.toString().lastIndexOf(' ')
  const timezone = value.substr(lastSpace + 1)

  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('D t ') + timezone
}
export const formatDateAndTimeNoTimeZone = function (value: string) {
  if (!value) return ''
  const dt = convertToDateTime(value)

  if (!dt.isValid) return ''
  else return dt.toFormat('MMM, d, y, h:mm a')
}
export const formatMilitaryTime = function (value: string) {
  if (value != '-') {
    const dt = DateTime.fromISO(value)
    return dt.toFormat('HH:mm')
  } else {
    return '-'
  }
}

export const trim = function (value: string) {
  if (!value) return ''
  value = value.toString()
  if (value.length > 28) return value.substring(0, 28) + '...'
  else return value
}

export const phone = function (value: string) {
  if (!value) return ''
  if (value.length < 10) return ''
  return value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const onlyNumericCharacters = function (value: string) {
  if (!value) return ''
  if (value.length < 10) return ''
  return value.replace(/[^0-9]/g, '')
}

export const convertToDateTime = function (value: string) {
  let dt = null
  if (!value || value == '') return null

  if (value.indexOf('AKST') >= 0 || value.indexOf('AKDT') >= 0)
    dt = DateTime.fromFormat(
      value.replace(' AKST', '').replace(' AKDT', ''),
      'dd MMM yyyy hh:mm:ss',
      { zone: 'America/Anchorage' }
    )
  else dt = DateTime.fromRFC2822(value, { setZone: true })
  return dt
}

export const capitalizeFunction = function (value: string) {
  if (!value) return
  if (value == 'SMS') return value
  if (!value) return ''
  return value
    .toString()
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const toLowerCaseFunction = function (value: string) {
  if (!value) return ''
  return value.toLowerCase()
}

Vue.filter('capitalizeFunction', capitalizeFunction)
Vue.filter('toLowerCaseFunction', toLowerCaseFunction)
Vue.filter('formatCity', formatCity)
Vue.filter('formatDate', formatDate)
Vue.filter('formatLocalDate', formatLocalDate)
Vue.filter('formatShortDate', formatShortDate)
Vue.filter('formatMilitaryTime', formatMilitaryTime)

Vue.filter('formatTime', formatTime)
Vue.filter('formatLocalTime', formatLocalTime)
Vue.filter('formatDateAndTime', formatDateAndTime)
Vue.filter('formatShortDateUTC', formatShortDateUTC)
Vue.filter('formatTimeUTC', formatTimeUTC)
Vue.filter('trim', trim)
Vue.filter('phone', phone)
// Vue.filter('_pluralize', pluralize)
Vue.filter('onlyNumericCharacters', onlyNumericCharacters)
Vue.filter('formatDateAndTimeNoTimeZone', formatDateAndTimeNoTimeZone)
