import { CCDetailObject } from '@/models'
import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): PaymentState => {
  return {
    addCardIsLoading: false,
    addCardError: false,
    ccDetailObject: { id: 0 },
    ccDetailIsLoading: false,
    showPaymentTab: false,
    addCardErrorMessage: '',
    addPaymentLoading: false,
    achAuthorized: false,
    isUpdatePaymentMethod: false,
    emailList: [],
    emailListSaveIsLoading: false,
    emailListUpdateSuccess: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<PaymentState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAddCardIsLoading(state, value) {
    state.addCardIsLoading = value
  },
  setCCDetailObject(state, value) {
    state.ccDetailObject = value
  },
  setCCDetailIsLoading(state, value) {
    state.ccDetailIsLoading = value
  },
  setShowPaymentTab(state, value) {
    state.showPaymentTab = value
  },
  setAddCardError(state, value) {
    state.addCardError = value
  },
  setAddCardErrorMessage(state, value) {
    state.addCardErrorMessage = value
  },
  setAddPaymentLoading(state, value) {
    state.addPaymentLoading = value
  },
  setACHAuthorized(state, value) {
    state.achAuthorized = value
  },
  setIsUpdatePaymentMethod(state, value) {
    state.isUpdatePaymentMethod = value
  },
  addEmail(state) {
    // @ts-ignore
    // TODO: fix
    state.emailList.push('')
  },
  removeEmail(state, value) {
    state.emailList.splice(value, 1)
  },
  updateEmailList(state, value) {
    state.emailList[value.index] = value.input
  },
  setEmailList(state, value) {
    state.emailList = value
  },
  setEmailListSaveIsLoading(state, value) {
    state.emailListSaveIsLoading = value
  },
  setEmailListUpdateSuccess(state, value) {
    state.emailListUpdateSuccess = value
  },
}

const actions: ActionTree<PaymentState, RootState> = {
  async addNewCard({ commit, dispatch }, payload) {
    commit('setAddCardError', false)
    return repository.Dealer.AddCard(payload)
      .then((res) => {
        commit('setCCDetailObject', res.data.payment_profile)
        dispatch('Security/updateDealerCardInfo', res.data.payment_profile, {
          root: true,
        })
      })
      .catch((err) => {
        commit('setAddCardError', true)
        commit('setAddCardErrorMessage', err.message)
      })
      .finally(() => {
        commit('setAddCardIsLoading', false)
      })
  },
  async getCardDetails({ commit }, payload) {
    commit('setCCDetailIsLoading', true)
    return repository.Dealer.GetPaymentProfile(payload)
      .then((res) => {
        commit('setCCDetailObject', res.data.paymentProfiles[0].payment_profile)
        commit('setEmailList', res.data.billingCarbonCopyEmails || [])
      })
      .finally(() => {
        commit('setCCDetailIsLoading', false)
      })
  },
  resetCardError({ commit }) {
    commit('setAddCardError', false)
    commit('setAddCardErrorMessage', '')
  },
  goToPaymentTab({ commit }, payload) {
    commit('setShowPaymentTab', payload)
  },
  callAddPaymentLoading({ commit }, payload) {
    commit('setAddPaymentLoading', payload)
  },
  callACHAuthorized({ commit }, payload) {
    commit('setACHAuthorized', payload)
  },
  callUpdatePaymentMethod({ commit }, payload) {
    commit('setIsUpdatePaymentMethod', payload)
  },
  async saveEmailList({ commit, state }) {
    commit('setEmailListSaveIsLoading', true)
    const payload = {
      additionalEmails: state.emailList,
    }
    return repository.Dealer.UpdateAdditionalBillingEmails(payload)
      .then(() => {
        commit('setEmailListUpdateSuccess', true)
      })
      .catch(() => {
        commit('setEmailListUpdateSuccess', false)
      })
      .finally(() => {
        commit('setEmailListSaveIsLoading', false)
      })
  },
}

const Payment: Module<PaymentState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Payment

interface PaymentState {
  addCardIsLoading: boolean
  addCardError: boolean
  ccDetailObject: CCDetailObject
  ccDetailIsLoading: boolean
  showPaymentTab: boolean
  addCardErrorMessage: string
  addPaymentLoading: boolean
  achAuthorized: boolean
  isUpdatePaymentMethod: boolean
  emailList: Email[]
  emailListSaveIsLoading: boolean
  emailListUpdateSuccess: boolean
}

interface Email {
  emailAddress?: string
}
