
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BrowserCompatDialog',
  props: {
    lightTheme: {
      type: Boolean,
    },
  },
  data() {
    return {
      notCompatible: false,
      showDialog: false,
      browsers: [
        {
          type: this.$t('global.chrome'),
          version: '77',
        },
        {
          type: this.$t('global.firefox'),
          version: '74',
        },
        {
          type: this.$t('global.edge'),
          version: '80',
        },
        {
          type: this.$t('global.safari'),
          version: '12.1',
        },
      ],
      headers: [
        {
          text: this.$t('global.supportedBrowsers'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('global.minimumVersion'),
          value: 'version',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
      this.$emit('closeDialog')
    },
    checkBrowser() {
      let browser = this.$browserDetect
      if (browser.isIOS) {
        return
      }
      if (browser.isChrome && this.browserVersion < 77) {
        this.showDialog = true
        this.notCompatible = true
      } else if (browser.isFirefox && this.browserVersion < 74) {
        this.showDialog = true
        this.notCompatible = true
      } else if (browser.isSafari && this.browserVersion < 12) {
        this.showDialog = true
        this.notCompatible = true
      } else if (browser.isEdge && this.browserVersion < 80) {
        this.showDialog = true
        this.notCompatible = true
      } else {
        return
      }
    },
  },
  created() {
    this.checkBrowser()
  },
  computed: {
    browserVersion() {
      return this.$browserDetect.meta.version
    },
    browserType() {
      return this.$browserDetect.meta.name
    },
  },
})
