import { Roles, User } from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): SuperAdminAdminState => {
  return {
    usersList: [],
    usersListLoading: false,
    userListErrorMessage: '',
    rolePermissionsListLoading: false,
    rolePermissionsList: false,
    getRolePermissionsListErrorMessage: '',
    updateUserInfoLoading: false,
    updateUserInfoSuccess: false,
    updateUserInfoErrorMessage: '',
    userEnableDisableLoading: false,
    userEnableDisableSuccess: false,
    userEnableDisableErrorMessage: '',
    userDeleteIsLoading: false,
    userDeleteSuccess: false,
    userDeleteErrorMessage: '',
    addUserIsLoading: false,
    addUserError: false,
    addUserErrorMessage: '',
    createdUserId: 0,
    rolesList: [],
    getRolesError: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<SuperAdminAdminState, RootState> = {
  currentUser: (state) => (userId: number) => {
    const selectedUser = state.usersList.find((user) => {
      return user.userId === userId
    })
    if (typeof selectedUser === 'undefined') return 0
    else return selectedUser
  },
}

const mutations: MutationTree<SuperAdminAdminState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setUsersList(state, value) {
    state.usersList = value
  },
  setUsersListLoading(state, value) {
    state.usersListLoading = value
  },
  setUserListErrorMessage(state, value) {
    state.userListErrorMessage = value
  },
  setRolePermissionsListLoading(state, value) {
    state.rolePermissionsListLoading = value
  },
  setRolePermissionsList(state, value) {
    state.rolePermissionsList = value
  },
  setGetRolePermissionsListErrorMessage(state, value) {
    state.getRolePermissionsListErrorMessage = value
  },
  setUpdateUserInfoLoading(state, value) {
    state.updateUserInfoLoading = value
  },
  setUpdateUserInfoSuccess(state, value) {
    state.updateUserInfoSuccess = value
  },
  setUpdateUserInfoErrorMessage(state, value) {
    state.updateUserInfoErrorMessage = value
  },
  setUserEnableDisableLoading(state, value) {
    state.userEnableDisableLoading = value
  },
  setUserEnableDisableSuccess(state, value) {
    state.userEnableDisableSuccess = value
  },
  setUserEnableDisableErrorMessage(state, value) {
    state.userEnableDisableErrorMessage = value
  },
  setAddUserIsLoading(state, value) {
    state.addUserIsLoading = value
  },
  setAddUserError(state, value) {
    state.addUserError = value
  },
  setAddUserErrorMessage(state, value) {
    state.addUserErrorMessage = value
  },
  setCreatedUserId(state, value) {
    state.createdUserId = value
  },
  setUserDeleteIsLoading(state, value) {
    state.userDeleteIsLoading = value
  },
  setUserDeleteSuccess(state, value) {
    state.userDeleteSuccess = value
  },
  setUserDeleteErrorMessage(state, value) {
    state.userDeleteErrorMessage = value
  },
  setRolesList(state, value) {
    state.rolesList = value
  },
  setGetRolesError(state, value) {
    state.getRolesError = value
  },
}

const actions: ActionTree<SuperAdminAdminState, RootState> = {
  async getSuperAdminUsers({ commit }, payload) {
    commit('setUsersListLoading', true)
    return repository.InternalAdmin.GetUsers(payload)
      .then((res) => {
        const userListData = res.data.items
        userListData.forEach((user: User) => {
          if (user.isActive) {
            user.status = 'Enabled'
          } else {
            user.status = 'Disabled'
          }
        })
        commit('setUsersList', res.data.items)
      })
      .catch((err) => {
        commit('setUserListErrorMessage', err)
      })
      .finally(() => {
        commit('setUsersListLoading', false)
      })
  },
  clearUpdateUserError({ commit }) {
    commit('setUpdateUserInfoErrorMessage', '')
    commit('setUpdateUserInfoSuccess', false)
  },
  async getRolesPermissions({ commit }, payload) {
    commit('setRolePermissionsListLoading', true)
    return repository.Role.GetRolesPermissions(payload)
      .then((res) => {
        commit('setRolePermissionsList', res.data.items)
      })
      .catch((err) => {
        commit(
          'setGetRolePermissionsListErrorMessage',
          err.response.data.message
        )
      })
      .finally(() => {
        commit('setRolePermissionsListLoading', false)
      })
  },
  async getRoles({ commit }, payload) {
    return repository.Role.GetRoles(payload)
      .then((res) => {
        commit('setRolesList', res.data.items)
      })
      .catch((err) => {
        commit('setGetRolesError', err.response.data.message)
      })
  },
  clearEnableDisableUserError({ commit }) {
    commit('setUserEnableDisableErrorMessage', '')
    commit('setUserEnableDisableSuccess', false)
  },
  callResetPassword(_, payload) {
    return repository.User.ResetUserPassword(payload)
  },
  async enableDisableUser({ commit }, payload) {
    commit('setUserEnableDisableLoading', true)
    return repository.User.EnableDisableUser(payload)
      .then(() => {
        commit('setUserEnableDisableSuccess', true)
      })
      .catch((err) => {
        commit('setUserEnableDisableSuccess', false)
        commit('setUserEnableDisableErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserEnableDisableLoading', false)
      })
  },
  async updateUserInfo({ commit }, payload) {
    commit('setUpdateUserInfoLoading', true)
    return repository.User.Update(payload)
      .then(() => {
        commit('setUpdateUserInfoSuccess', true)
      })
      .catch((err) => {
        commit('setUpdateUserInfoSuccess', false)
        commit('setUpdateUserInfoErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUpdateUserInfoLoading', false)
      })
  },
  async addUser({ commit }, payload) {
    commit('setAddUserIsLoading', true)
    return repository.User.Add(payload)
      .then((res) => {
        commit('setCreatedUserId', res.data.userId)
      })
      .catch((err) => {
        commit('setAddUserError', true)
        commit('setAddUserErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddUserIsLoading', false)
      })
  },
  async getDealerAdminUsers({ commit }, payload) {
    commit('setUsersListLoading', true)
    return repository.Dealer.GetUsers(payload)
      .then((res) => {
        const userListData = res.data.items
        userListData.forEach((user: User) => {
          if (user.isActive) {
            user.status = 'Enabled'
          } else {
            user.status = 'Disabled'
          }
        })
        commit('setUsersList', res.data.items)
      })
      .catch((err) => {
        commit('setUserListErrorMessage', err)
      })
      .finally(() => {
        commit('setUsersListLoading', false)
      })
  },
  clearAddUserError({ commit }) {
    commit('setAddUserError', false)
  },
  async deleteUser({ commit }, payload) {
    commit('setUserDeleteIsLoading', true)
    return repository.User.DeleteUser(payload)
      .then(() => {
        commit('setUserDeleteSuccess', true)
      })
      .catch((err) => {
        commit('setUserDeleteSuccess', false)
        commit('setUserDeleteErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserDeleteIsLoading', false)
      })
  },
  clearDeleteUserError({ commit }) {
    commit('setUserDeleteErrorMessage', '')
    commit('setUserDeleteSuccess', false)
  },
}
const SuperAdminAdmin: Module<SuperAdminAdminState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default SuperAdminAdmin

interface SuperAdminAdminState {
  usersList: User[]
  usersListLoading: boolean
  userListErrorMessage: string
  rolePermissionsListLoading: boolean
  rolePermissionsList: boolean
  getRolePermissionsListErrorMessage: string
  updateUserInfoLoading: boolean
  updateUserInfoSuccess: boolean
  updateUserInfoErrorMessage: string
  userEnableDisableLoading: boolean
  userEnableDisableSuccess: boolean
  userEnableDisableErrorMessage: string
  userDeleteIsLoading: boolean
  userDeleteSuccess: boolean
  userDeleteErrorMessage: string
  addUserIsLoading: boolean
  addUserError: boolean
  addUserErrorMessage: string
  createdUserId: number
  rolesList: Roles[]
  getRolesError: string
}
