import repository from '@/repository'
import { extractLastTimeStamp, timeIntervalsSort } from '@/utils/tools'
import { ActionTree, Module, MutationTree } from 'vuex'
import i18n from '../../i18n'
import { RootState } from '../types'

const getDefaultState = (): TimelineState => {
  return {
    timeIntervals: null,
    timelineIsLoading: false,
    timelinePlaybackAvailability: true,
    timelineSession: 0,
    timelineLock: false,
    timelineLastTimestamp: Infinity,
    timelineReason: null,
    cameraApiError: false,
    cameraApiErrorMessage: '',
    isSmartSearchTimeline: false,
    smartSearchTimelineError: false,
    smartSearchCounter: 0,
  }
}

const state = getDefaultState()

const mutations: MutationTree<TimelineState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setTimeIntervals(state, value) {
    state.timeIntervals = timeIntervalsSort(value)
  },
  setTimelineIsLoading(state, value) {
    state.timelineIsLoading = value
  },
  setTimelinePlaybackAvailability(state, value) {
    state.timelinePlaybackAvailability = value
  },
  setTimelineSession(state, value) {
    state.timelineSession = value
  },
  setTimelineLock(state, value) {
    state.timelineLock = value
  },
  setCameraApiError(state, value) {
    state.cameraApiError = value
  },
  setCameraApiErrorMessage(state, value) {
    state.cameraApiErrorMessage = value
  },
  setIsSmartSearchTimeline(state, value) {
    state.isSmartSearchTimeline = value
  },
  setIncrementSmartSearchCounter(state) {
    state.smartSearchCounter++
  },
  setTimelineLastTimestamp(state, value) {
    state.timelineLastTimestamp = value
  },
  setTimelineReason(state, value) {
    state.timelineReason = value
  },
  setSmartSearchTimelineError(state, value) {
    state.smartSearchTimelineError = value
  },
}

const actions: ActionTree<TimelineState, RootState> = {
  // can be removed
  async getTimeline({ commit }, payload) {
    commit('setTimelineIsLoading', true)
    return repository.Timeline.GetTimeline(payload.parm, payload.config)
      .then((res) => {
        commit('setTimeIntervals', res.data.timeIntervals)
        commit('setTimelineSession', res.data.session)
        const lastTimestamp = extractLastTimeStamp(res.data.timeIntervals)
        commit('setTimelineLastTimestamp', lastTimestamp)
        commit('setIsSmartSearchTimeline', false)
      })
      .catch(() => {
        commit('setTimeIntervals', [])
        commit('setCameraApiError', true)
        commit('setCameraApiErrorMessage', i18n.t('dealer.cameraFootageError'))
      })
      .finally(() => {
        commit('setTimelineIsLoading', false)
        commit('setCameraApiError', false)
      })
  },
  // can be removed
  async getTimelineLive({ commit }, payload) {
    return repository.Timeline.GetTimeline(payload.parm, payload.config)
      .then((res) => {
        commit('setTimeIntervals', res.data.timeIntervals)
        commit('setTimelineSession', res.data.session)
        const lastTimestamp = extractLastTimeStamp(res.data.timeIntervals)
        commit('setTimelineLastTimestamp', lastTimestamp)
        commit('setIsSmartSearchTimeline', false)
      })
      .catch(() => {
        commit('setTimeIntervals', [])
        commit('setCameraApiError', true)
        commit('setCameraApiErrorMessage', i18n.t('dealer.cameraFootageError'))
      })
      .finally(() => {
        commit('setCameraApiError', false)
      })
  },
  // can be removed
  async getSmartSearchTimeline({ commit }, payload) {
    commit('setTimelineIsLoading', true)
    commit('setSmartSearchTimelineError', false)
    return repository.Timeline.GetSmartSearchTimeline(
      payload.parm,
      payload.config
    )
      .then((res) => {
        commit('setTimeIntervals', res.data.timeIntervals)
        commit('setTimelineSession', res.data.session)
        const lastTimestamp = extractLastTimeStamp(res.data.timeIntervals)
        commit('setTimelineLastTimestamp', lastTimestamp)
        commit('setIsSmartSearchTimeline', true)
        commit('setIncrementSmartSearchCounter')
      })
      .catch(() => {
        commit('setTimeIntervals', [])
        commit('setCameraApiError', true)
        commit('setSmartSearchTimelineError', true)
        commit('setCameraApiErrorMessage', i18n.t('dealer.cameraFootageError'))
      })
      .finally(() => {
        commit('setTimelineIsLoading', false)
        commit('setCameraApiError', false)
      })
  },
  // can be removed
  async getTimelinePlaybackAvailability({ commit }, payload) {
    return repository.Timeline.GetExistance(payload.parm, payload.config)
      .then((res) => {
        commit('setTimelinePlaybackAvailability', res.data)
        return res
      })
      .catch((err) => {
        commit('setTimelinePlaybackAvailability', false)
        commit('setCameraApiError', true)
        commit(
          'setCameraApiErrorMessage',
          i18n.t('dealer.cameraSDFootageError')
        )
        throw err
      })
      .finally(() => {
        commit('setCameraApiError', false)
      })
  },
  callTimelineLock({ commit }, payload) {
    commit('setTimelineLock', payload)
  },
  callResetTimelineLastTimestamp({ commit }) {
    commit('setTimelineLastTimestamp', Infinity)
  },
  callReset({ commit }) {
    commit('reset')
  },
  callSetTimelineReason({ commit }, payload) {
    commit('setTimelineReason', payload)
  },
}
const Timeline: Module<TimelineState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Timeline

interface TimelineState {
  timeIntervals: any
  timelineIsLoading: false
  timelinePlaybackAvailability: boolean
  timelineSession: number
  timelineLock: boolean
  timelineLastTimestamp: any
  timelineReason: null
  cameraApiError: false
  cameraApiErrorMessage: string
  isSmartSearchTimeline: boolean
  smartSearchTimelineError: boolean
  smartSearchCounter: number
}
