
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'

export default defineComponent({
  name: 'DealerUserMobileNav',
  props: {
    dealerViewingUser: {
      type: Boolean,
      required: true,
    },
    showDealerMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AccountMenu,
  },
  methods: {
    routeToCustomers() {
      this.$router.push('/customers')
    },
  },
})
