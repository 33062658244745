
import './scss/video_overrides.scss'
import './scss/vuetify_overrides.scss'
import { defineComponent } from 'vue'
import Header from '@/components/global/Header.vue'
import Footer from '@/components/global/Footer.vue'
import BrowserCompatDialog from '@/components/global/BrowserCompatDialog.vue'
import GlobalToastMessage from '@/components/global/GlobalToastMessage.vue'
import TermsDialog from '@/components/global/termsDialog/TermsDialog.vue'
import DealerCCRequiredDialog from '@/components/banners/DealerCCRequiredDialog.vue'
import { initClient } from '@/utils/webSockets'

import { mapActions, mapState } from 'vuex'

const FEATURE_UPDATE_INTERVAL_MS = 5 * 60 * 1000 // 5 minutes

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Footer,
    DealerCCRequiredDialog,
    GlobalToastMessage,
    BrowserCompatDialog,
    TermsDialog,
  },
  data() {
    return {
      featureFlagRefreshHandle: null,
    }
  },
  computed: {
    ...mapState('Security', ['isLoggedIn', 'globalLoading', 'user']),
    ...mapState('ExternalCases', ['externalUserIsLoggedIn']),
    showHeader() {
      return this.$route.meta.showHeader != null &&
        this.$route.meta.showHeader === false
        ? false
        : true
    },
    showFooter() {
      return this.$route.meta.showFooter != null &&
        this.$route.meta.showFooter === false
        ? false
        : true
    },
    browserCompatLightTheme() {
      return !this.isLoggedIn || !this.externalUserIsLoggedIn
    },
  },
  methods: {
    ...mapActions('Security', ['checkSystemStatus', 'getOwnUser']),
    ...mapActions('Environment', ['refreshFeatures', 'refreshSettings']),
    openBrowserCompatDialog() {
      this.$refs.browserCompat.openDialog()
    },
    openTermsDialog() {
      this.$refs.terms.userClick()
    },
    setupFeatureFlagLoader() {
      this.refreshFeatures()
      this.featureFlagRefreshHandle = setInterval(() => {
        this.refreshFeatures()
        if (this.isLoggedIn) this.refreshSettings()
      }, FEATURE_UPDATE_INTERVAL_MS)
    },
  },
  created() {
    this.checkSystemStatus()
    this.setupFeatureFlagLoader()
    if (this.$route.name == 'Login') {
      this.getOwnUser().then(() => {
        if (this.isLoggedIn) {
          if (this.user.isDealerUser) {
            this.$router.push('/customers')
          } else if (this.user.isInternalUser) {
            this.$router.push('/dealers')
          } else {
            this.$router.push('/dashboard')
          }
        }
      })
    }
  },
  destroyed() {
    clearInterval(this.featureFlagRefreshHandle)
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        initClient()
      }
    },
    externalUserIsLoggedIn() {
      if (this.externalUserIsLoggedIn) {
        initClient()
      }
    },
  },
})
