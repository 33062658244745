import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): DealerDeviceState => {
  return {
    addDeviceError: false,
    addDeviceErrorMessage: '',
    addDeviceIsLoading: false,
    dealerDeviceIsLoading: false,
    dealerDeviceList: [],
    updateDeviceDetailedInfoResult: {
      //@ts-ignore
      resultType: Number,
      //@ts-ignore
      message: String,
      item: null,
    },
    addVigilNVRLoading: false,
    addVigilNVRError: false,
    addVigilNVRErrorMessage: '',
    vigilNVRDeviceList: [],
    vigilNVRObject: { deviceGroupID: 0 },
    activateNVRCamerasLoading: false,
    activateNVRCamerasError: false,
    activateNVRCamerasErrorMessage: '',
    refreshCamerasLoading: false,
    deviceNVRErrorMessage: null,
    deviceMACErrorMessage: '',
    deviceMACError: false,
    deviceMACIsLoading: false,
  }
}
const state = getDefaultState()

const mutations: MutationTree<DealerDeviceState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAddDeviceIsLoading(state, value) {
    state.addDeviceIsLoading = value
  },
  setAddDeviceError(state, value) {
    state.addDeviceError = value
  },
  setAddDeviceErrorMessage(state, value) {
    state.addDeviceErrorMessage = value
  },
  setDealerDeviceIsLoading(state, value) {
    state.dealerDeviceIsLoading = value
  },
  setDealerDeviceList(state, value) {
    state.dealerDeviceList = value
  },
  setUpdateDeviceResult(state, value) {
    state.updateDeviceDetailedInfoResult = value
  },
  setAddVigilNVRLoading(state, value) {
    state.addVigilNVRLoading = value
  },
  setAddVigilNVRError(state, value) {
    state.addVigilNVRError = value
  },
  setAddVigilNVRErrorMessage(state, value) {
    state.addVigilNVRErrorMessage = value
  },
  setVigilNVRDeviceList(state, value) {
    state.vigilNVRDeviceList = value
  },
  setVigilNVRObject(state, value) {
    state.vigilNVRObject = value
  },
  setActivateNVRCamerasLoading(state, value) {
    state.activateNVRCamerasLoading = value
  },
  setActivateNVRCamerasError(state, value) {
    state.activateNVRCamerasError = value
  },
  setActivateNVRCamerasErrorMessage(state, value) {
    state.activateNVRCamerasErrorMessage = value
  },
  setRefreshCamerasLoading(state, value) {
    state.refreshCamerasLoading = value
  },
  setDeviceNVRErrorMessage(state, value) {
    state.deviceNVRErrorMessage = value
  },
  setDeviceMACErrorMessage(state, value) {
    state.deviceMACErrorMessage = value
  },
  setDeviceMACError(state, value) {
    state.deviceMACError = value
  },
  setDeviceMACIsLoading(state, value) {
    state.deviceMACIsLoading = value
  },
}

const getters: GetterTree<DealerDeviceState, RootState> = {
  vigilNVRCompatibleDeviceList(state) {
    return state.vigilNVRDeviceList.filter((device) => {
      if (device.isSupported && !device.isAdded) return device
    })
  },
}

const actions: ActionTree<DealerDeviceState, RootState> = {
  async addDevice({ commit, dispatch }, payload) {
    commit('setAddDeviceIsLoading', true)
    return repository.Device.AddDevice(payload)
      .then(() => {
        //This is where we need to update location object.
        dispatch('CustomerLocations/updateLocationTimezone', payload, {
          root: true,
        })
        commit('setAddDeviceError', false)
      })
      .catch((err) => {
        commit('setAddDeviceError', true)
        commit('setAddDeviceErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddDeviceIsLoading', false)
      })
  },
  async updateDeviceDetailedInfo({ commit }, payload) {
    return repository.Device.UpdateDeviceDetailedInfo(payload)
      .then((res) => {
        commit('setUpdateDeviceResult', res.data)
      })
      .catch((err) => {
        const obj = {
          resultType: err.response.status,
          message: err.response.data.message,
          item: null,
        }
        commit('setUpdateDeviceResult', obj)
      })
  },
  async addVigilNVR({ commit }, payload) {
    commit('setAddVigilNVRLoading', true)
    commit('setAddVigilNVRError', false)
    commit('setAddVigilNVRErrorMessage', '')
    return repository.Device.GetNVRDetails(payload)
      .then((res) => {
        commit('setVigilNVRDeviceList', res.data.devices)
        commit('setVigilNVRObject', {
          code: res.data.code,
          timeZoneID: res.data.timeZoneID,
        })
      })
      .catch((err) => {
        commit('setAddVigilNVRError', true)
        commit('setAddVigilNVRErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setAddVigilNVRLoading', false)
      })
  },
  async activateNVRCameras({ commit }, payload) {
    commit('setActivateNVRCamerasLoading', true)
    commit('setActivateNVRCamerasError', false)
    commit('setActivateNVRCamerasErrorMessage', '')
    return repository.Device.AddNVR(payload)
      .then(() => {})
      .catch((err) => {
        commit('setActivateNVRCamerasError', true)
        commit('setActivateNVRCamerasErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setActivateNVRCamerasLoading', false)
      })
  },
  async updateNVRCameraStatus(_, payload) {
    return repository.Device.UpdateNVRCameraStatus(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch(() => {
        return Promise.resolve(null)
      })
      .finally(() => {})
  },
  async activateNVRDevice({ commit }, payload) {
    return repository.Device.ActivateNVRCameras(payload)
      .then(() => {
        commit('setDeviceNVRErrorMessage', null)
      })
      .catch((err) => {
        commit('setDeviceNVRErrorMessage', err.response.data.message)
      })
      .finally(() => {})
  },
  async deactivateNVRDevice({ commit }, payload) {
    return repository.Device.DeactivateNVRCameras(payload)
      .then(() => {
        commit('setDeviceNVRErrorMessage', null)
      })
      .catch((err) => {
        commit('setDeviceNVRErrorMessage', err.response.data.message)
      })
      .finally(() => {})
  },
  async deleteNVRDevice({ commit }, payload) {
    return repository.Device.DeleteNVR(payload)
      .then(() => {
        commit('setDeviceNVRErrorMessage', null)
      })
      .catch((err) => {
        commit('setDeviceNVRErrorMessage', err.response.data.message)
      })
      .finally(() => {})
  },
  resetAddNVR({ commit }) {
    commit('setActivateNVRCamerasError', false)
    commit('setActivateNVRCamerasErrorMessage', '')
    commit('setAddVigilNVRError', false)
    commit('setAddVigilNVRErrorMessage', '')
  },
  getNVRCameras({ commit }, payload) {
    commit('setVigilNVRDeviceList', [])
    commit('setVigilNVRObject', {})
    commit('setAddVigilNVRLoading', true)
    repository.Device.GetNVRDetails(payload)
      .then((res) => {
        commit('setVigilNVRDeviceList', res.data.devices)
        commit('setVigilNVRObject', {
          code: res.data.code,
          name: res.data.name,
          timeZoneID: res.data.timeZoneID,
        })
      })
      .finally(() => {
        commit('setAddVigilNVRLoading', false)
      })
  },
  async refreshNVRCameras({ commit }, payload) {
    commit('setVigilNVRDeviceList', [])
    commit('setVigilNVRObject', {})
    commit('setAddVigilNVRLoading', true)
    commit('setRefreshCamerasLoading', true)
    repository.Device.GetRefreshedNVRDetails(payload)
      .then((res) => {
        commit('setVigilNVRDeviceList', res.data.devices)
        commit('setVigilNVRObject', {
          code: res.data.code,
          name: res.data.name,
          timeZoneID: res.data.timeZoneID,
        })
        commit('setAddVigilNVRLoading', false)
        commit('setRefreshCamerasLoading', false)
      })
      .catch(() => {})
      .finally(() => {})
  },
  initNVRCameraRefresh({ commit }, payload) {
    commit('setRefreshCamerasLoading', true)
    commit('setAddVigilNVRLoading', true)
    return repository.Device.RefreshNVRDetails(payload)
  },
  resetNVRCamerasRefresh({ commit }) {
    commit('setRefreshCamerasLoading', false)
    commit('setAddVigilNVRLoading', false)
  },
  async createNVRSignalingChannels(_, payload) {
    return repository.Device.CreateNVRSignalingChannels(payload)
  },
  async checkDeviceMAC({ commit }, payload) {
    commit('setDeviceMACIsLoading', true)
    return repository.Device.CheckMACAddress(payload)
      .then(() => {
        commit('setDeviceMACError', false)
      })
      .catch((err) => {
        commit('setDeviceMACErrorMessage', err.response.data.message)
        commit('setDeviceMACError', true)
      })
      .finally(() => {
        commit('setDeviceMACIsLoading', false)
      })
  },
  resetCheckMACError({ commit }) {
    commit('setDeviceMACError', false)
    commit('setDeviceMACErrorMessage', '')
    commit('setAddDeviceError', false)
  },
}

const DealerDevice: Module<DealerDeviceState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default DealerDevice

import { VigilNVRDevice, VigilNVRObject } from '@/models'

interface DealerDeviceState {
  addDeviceError: boolean
  addDeviceErrorMessage: string
  addDeviceIsLoading: boolean
  dealerDeviceIsLoading: boolean
  dealerDeviceList: any[]
  updateDeviceDetailedInfoResult: UpdateDeviceDetailedInfoResult
  addVigilNVRLoading: boolean
  addVigilNVRError: boolean
  addVigilNVRErrorMessage: string
  vigilNVRDeviceList: VigilNVRDevice[]
  vigilNVRObject: VigilNVRObject
  activateNVRCamerasLoading: boolean
  activateNVRCamerasError: boolean
  activateNVRCamerasErrorMessage: string
  refreshCamerasLoading: boolean
  deviceNVRErrorMessage: null
  deviceMACErrorMessage: string
  deviceMACError: boolean
  deviceMACIsLoading: boolean
}

interface UpdateDeviceDetailedInfoResult {
  resultType: number
  message: string
  item: any
}
