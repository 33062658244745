
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AudioComponent',
  props: {
    termsAgreed: {
      type: Boolean,
      required: true,
    },
    termsPage: {
      type: Boolean,
      required: false,
    },
    userClickOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    acceptTerms() {
      this.$emit('acceptTerms', !this.termsAgreed)
    },
  },
  computed: {
    agreeText() {
      return this.$t('terms.audio.iHaveReviewed')
    },
  },
  mounted() {
    this.$vuetify.goTo('.terms-component', {
      container: '.text-card',
      duration: 0,
    })
  },
})
