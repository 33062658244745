
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { VIEW_CASE_CLICK } from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'GlobalToastMessage',
  data() {
    return {}
  },
  computed: {
    ...mapState('ToastMessage', [
      'toastMessage',
      'showToastMessage',
      'toastType',
      'toastLink',
      'toastMessageLineTwo',
      'toastDownloadLink',
    ]),
    titleClass() {
      if (this.toastMessageLineTwo) return true
      else return false
    },
    alert: {
      get() {
        return this.showToastMessage
      },
      set(oldValue, newValue) {
        if (!newValue) this.callCloseToast()
      },
    },
  },
  methods: {
    ...mapActions('ToastMessage', ['callCloseToast']),
    viewCaseClick() {
      this.$mixpanelUserTrack(VIEW_CASE_CLICK)
    },
  },
})
