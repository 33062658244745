
import { defineComponent } from 'vue'
import { DateTime } from 'luxon'
import { formatDateTimeDisplay } from '@/utils/tools'
import NotificationTypeIcons from './NotificationTypeIcons.vue'
import { DEVICE_STATUS_UPDATED } from '@/constants/notificationTypes.js'

export default defineComponent({
  name: 'SingleNotification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    display24HourTime: {
      type: Boolean,
      required: true,
    },
    showDeleteIcon: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  components: {
    NotificationTypeIcons,
  },
  methods: {
    markItemAsRead(notification) {
      if (!this.isSelected) {
        this.$emit('markItemAsRead', notification)
      }
    },
    formattedDate(date) {
      let dt = DateTime.fromISO(date)
      return formatDateTimeDisplay(
        dt,
        this.display24HourTime,
        ' at ',
        false,
        false
      )
    },
    changeNotification(data) {
      let payload = {
        notificationId: this.notification.notificationLogId,
      }
      if (data.length === 0) {
        payload.type = 'remove'
        this.$emit('updateSelectedNotifications', payload)
      } else {
        payload.type = 'add'
        this.$emit('updateSelectedNotifications', payload)
      }
    },
    addSelected() {
      this.selected = [this.notification.notificationLogId]
    },
    removeSelected() {
      this.selected = []
    },
    deleteNotification(notification) {
      this.$emit('deleteSingleNotification', notification.notificationLogId)
    },
  },
  computed: {
    payload() {
      return JSON.parse(this.notification.notificationPayload)
    },
    deviceStatusUpdated() {
      return DEVICE_STATUS_UPDATED
    },
  },
})
