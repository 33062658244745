
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import {
  FORGOT_PASSWORD_PAGE_VIEWED,
  SEND_RESET_PASSWORD_EMAIL,
  BACK_TO_LOGIN_CLICK,
} from '@/constants/mixpanelEvents'
import formFieldValidation from '@/mixins/formFieldValidation'

export default defineComponent({
  name: 'ForgotPassword',
  mixins: [formFieldValidation],
  data() {
    return {
      emailAddress: '',
      emailAddressForm: false,
    }
  },
  components: {
    TwoColumnLayout,
  },
  computed: {
    ...mapState('ForgotPassword', [
      'forgotPasswordIsLoading',
      'forgotPasswordSuccess',
      'forgotPasswordError',
      'forgotPasswordErrorMessage',
    ]),
    resetBtnActive() {
      if (this.emailAddress != '') {
        return true
      } else {
        return false
      }
    },
    enterEmailLabel() {
      return this.$t('forgotPasswordView.enterEmail')
    },
  },
  methods: {
    ...mapActions('ForgotPassword', [
      'callForgotPassword',
      'resetForgotPasswordFlow',
    ]),
    validate() {
      return this.$refs.form.validate()
    },
    clickTrack() {
      this.$mixpanelTrack(BACK_TO_LOGIN_CLICK)
    },
    returnToLogin() {
      this.$mixpanelTrack(BACK_TO_LOGIN_CLICK)
      this.resetForgotPasswordFlow()
    },
    clickResetBtn() {
      this.$mixpanelTrack(SEND_RESET_PASSWORD_EMAIL)
      if (this.validate() && this.emailAddress != '') {
        let payload = {
          UserEmail: this.emailAddress,
        }
        this.callForgotPassword(payload)
      }
    },
  },
  created() {
    this.resetForgotPasswordFlow()
    this.$mixpanelTrack(FORGOT_PASSWORD_PAGE_VIEWED)
  },
})
