import httpClient from './httpClient'

export default {
  Role: {
    GetRoles(payload) {
      return httpClient.post('/Role/GetRoles', payload)
    },
    GetRolesPermissionsUsers(payload) {
      return httpClient.post('/Role/GetRolesPermissionsUsers', payload)
    },
    GetRolesPermissions(payload) {
      return httpClient.post('/Role/GetRolesPermissions', payload)
    },
  },
}
