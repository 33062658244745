import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'
import routeHandler from './routeHandler'

import anonymousRoutes from './anonymousRoutes'
import customerRoutes from './customerRoutes'
import dealerRoutes from './dealerRoutes'
import internalAdminRoutes from './internalAdminRoutes'
import externalUserRoutes from './externalUserRoutes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  ...anonymousRoutes,
  ...customerRoutes,
  ...dealerRoutes,
  ...internalAdminRoutes,
  ...externalUserRoutes,
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "Dashboard" */ '@/views/dashboard/Dashboard.vue'
      ),
    meta: {
      title: 'Dashboard',
      allowAnonymous: false,
      showHeader: true,
      showFooter: true,
      dealerAndCustomer: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(
        /*webpackChunkName: "UserProfile" */ '@/views/userProfile/UserProfile.vue'
      ),
    props: true,
    meta: {
      title: 'Profile',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: true,
      showFooter: true,
      allUsers: true,
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: () =>
      import(/*webpackChunkName: "Help" */ '@/views/help/Help.vue'),
    meta: {
      title: 'Help',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: true,
      showFooter: true,
      allUsers: true,
    },
  },
  {
    path: '/admin',
    name: 'Administration',
    component: () =>
      import(
        /*webpackChunkName: "Administration" */ '@/views/vigilAdmin/AdminContainer.vue'
      ),
    props: true,
    meta: {
      title: 'Admin',
      allowAnonymous: false,
      //customerOnly: true,
      showHeader: true,
      showFooter: true,
      //internalOnly: false,
      dealerAndCustomer: true,
    },
  },
  {
    path: '/notifications',
    name: 'NotificationsList',
    component: () =>
      import(
        /*webpackChunkName: "NotificationsList" */ '@/views/notifications/NotificationsList.vue'
      ),
    meta: {
      title: 'Notifications',
      allowAnonymous: false,
      loggedInOnly: true,
      showHeader: true,
      showFooter: true,
      dealerAndCustomer: true,
      internalOnly: false,
    },
  },
  // Commenting out this logic for now. We may be covered in the beforeEach section below.
  //{
  //  path: '*',
  //  beforeEnter: (to, from, next) => {
  //    if (store.getters['Security/user'].isDealerUser) {
  //      next({ path: '/customers' })
  //    } else if (store.getters['Security/user'].isInternalUser) {
  //      next({ path: '/dealers' })
  //    } else if (store.getters['Security/userID'] != 0) {
  //      next({ path: '/dashboard' })
  //    } else {
  //      next({ path: '/login' })
  //    }
  //  },
  //},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function reloadAppIfStale(path, force) {
  if (force || store.state.Environment.needsUpdate) {
    //trigger app refresh - page should reload
    window.location.href = path
  }
}

let lastToPath = '/'

router.onError((error) => {
  //when stale, lazy loaded routes will fail - force an update
  reloadAppIfStale(
    lastToPath,
    error.name && error.name.startsWith('ChunkLoadError')
  )
})

router.beforeEach((to, from, next) => {
  // Cancel all inflight API (subject to exclusions) requests when the route jumps
  if (window.__axiosPromiseArr) {
    window.__axiosPromiseArr.forEach((cancel) => {
      cancel()
    })
    window.__axiosPromiseArr = []
  }

  //following a deployment, the app will be stale
  lastToPath = to.path
  reloadAppIfStale(to.path)

  if (to.meta.title) {
    document.title = 'VIGIL CLOUD - ' + to.meta.title
  } else {
    document.title = 'VIGIL CLOUD'
  }

  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next()
  } else {
    if (store.state.Security.isLoggedIn) {
      routeHandler(to, from, next)
    } else {
      store.dispatch('Security/getOwnUser').then(() => {
        if (store.state.Security.isLoggedIn) {
          routeHandler(to, from, next)
        } else {
          if (to.query.view) {
            next({
              name: 'Login',
              query: { redirect: to.path, view: to.query.view },
            })
          } else {
            next({
              name: 'Login',
              query: { redirect: to.path },
            })
          }
        }
      })
    }
  }
  //where we swap routes and display a success toast we need to reinstantiate the timeout
  if (store.getters['ToastMessage/toastType'] == 'success') {
    setTimeout(() => {
      store.commit('ToastMessage/clearToast')
    }, 3500)
  }
})

export default router
