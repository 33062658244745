
import { defineComponent } from 'vue'
import DealerUserDesktopNav from './DealerUserDesktopNav.vue'
import DealerUserMobileNav from './DealerUserMobileNav.vue'

export default defineComponent({
  name: 'DealerUserNav',
  props: {
    dealerViewingUser: {
      type: Boolean,
      required: true,
    },
    showDealerMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DealerUserDesktopNav,
    DealerUserMobileNav,
  },
})
