export const LOCATION_TYPE = 1
export const DEVICE_TYPE = 2
export const USER_TYPE = 4

export const ADD_EVENT = 4
export const EDIT_EVENT = 5
export const CAMERA_SETTINGS_VIEWED = 11
export const SINGLE_CAMERA_VIEWED = 10
export const CAMERA_SETTINGS_CHANGED = 12

// Gap between videojs segment and KVS fragment
export const KVS_AND_VIDEO_GAP = 48
// Per camera side require
export const SD_AND_VIDEO_GAP = 1000

//Smart Search sensitivity default value 30
export const SMART_SEARCH_SENSITIVITY = 35
