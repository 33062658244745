const requireModule = require.context('.', false, /\.store\.(ts|js)$/)
const modules = {}

requireModule.keys().forEach((filename) => {
  const moduleName = filename
    .replace(/(\.\/|\.store\.(ts|js))/g, '')
    .replace(/^\w/, (c) => c.toUpperCase())
  modules[moduleName] =
    requireModule(filename).default || requireModule(filename)
})

export default modules
