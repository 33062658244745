import { DateTime } from 'luxon'

export const getQuery = (param: string) => {
  const ret = {}
  let str = param
  const SOURCE1 = '[source1]'
  const SOURCE2 = '[source2]'
  if (str.indexOf(SOURCE2) > -1) {
    str = str.substring(
      str.indexOf(SOURCE1) + SOURCE1.length,
      str.indexOf(SOURCE2)
    )
  }
  const pairs = str
    .replace(/\r/g, '')
    .replace('[source=1]', '')
    .replace(SOURCE1, '')
    .replace(/;/g, '')
    .split(/[\n]/g)

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    if (pair[1]) {
      ret[pair[0]] = !isNaN(Number(pair[1])) ? Number(pair[1]) : 0
    }
  }
  return ret
}

export const formatToDateAndTime = function (value: DateTime) {
  if (!value) return ''
  return value.toISODate() + ' ' + value.toFormat('HH:mm:ss')
}

export const formatDateTimeDisplay = function (
  value: DateTime,
  display24HourTime: boolean,
  splitter: string,
  hasZone = false,
  hasSecond = true,
  dateOnly = false
) {
  let format12 = DateTime.TIME_SIMPLE
  let format24 = 'HH:mm'
  if (hasZone) {
    format12 = DateTime.TIME_WITH_SHORT_OFFSET
    format24 = 'ZZZZ HH:mm:ss'
  } else if (hasSecond) {
    format12 = DateTime.TIME_WITH_SECONDS
    format24 = 'HH:mm:ss'
  } else if (dateOnly) {
    format12 = DateTime.DATE_SHORT
    format24 = 'dd/MM/yyyy'
  }

  if (display24HourTime) {
    if (dateOnly) {
      return value.toLocaleString()
    } else {
      return value.toLocaleString() + splitter + value.toFormat(format24)
    }
  } else {
    if (dateOnly) {
      return value.toLocaleString()
    } else {
      return value.toLocaleString() + splitter + value.toLocaleString(format12)
    }
  }
}

export const formatTimeDisplay = function (
  value: DateTime,
  display24HourTime: boolean
) {
  if (display24HourTime) {
    return value.toFormat('HH:mm:ss')
  } else {
    return value.toLocaleString(DateTime.TIME_WITH_SECONDS)
  }
}

export const numberComparatorDesc = (a: number, b: number) => {
  return b - a
}

export const extractLastTimeStamp = function (timeIntervals: { to: string }[]) {
  if (!timeIntervals || timeIntervals.length == 0) {
    return Infinity
  }

  let lastTimestamp = Math.max(
    Number(DateTime.fromISO(timeIntervals[0].to).toSeconds()),
    Number(
      DateTime.fromISO(timeIntervals[timeIntervals.length - 1].to).toSeconds()
    )
  )

  timeIntervals.forEach((interval) => {
    const to = DateTime.fromISO(interval.to).toSeconds()
    lastTimestamp = Math.max(lastTimestamp, Number(to))
  })

  return lastTimestamp
}

export const timeIntervalsSort = function (
  value: {
    to: string
    from: string
  }[]
) {
  let timeIntervals = []
  if (value && value.length > 0) {
    timeIntervals = [...new Set(value)]
    timeIntervals.sort((a, b) => {
      //@ts-ignore
      return DateTime.fromISO(a.from) - DateTime.fromISO(b.from)
    })
  }
  return timeIntervals
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const vector2Pixel = (
  dewarpCenterX: number,
  dewarpCenterY: number,
  dewarpRadiusByHeight: number,
  width: number,
  height: number,
  videoWidth: number,
  videoHeight: number
) => {
  if (
    dewarpCenterX != null &&
    dewarpCenterY != null &&
    dewarpRadiusByHeight &&
    width &&
    height &&
    videoWidth &&
    videoHeight
  ) {
    let currentWidth = width
    let currentHeight = height
    const displayRatio = width / height
    const videoRatio = videoWidth / videoHeight
    if (videoRatio > 1) {
      if (displayRatio > videoRatio) {
        currentWidth = currentHeight * videoRatio
      } else {
        currentHeight = currentWidth / videoRatio
      }
    } else {
      if (displayRatio > videoRatio) {
        currentWidth = currentHeight * displayRatio
      } else {
        currentHeight = currentWidth / displayRatio
      }
    }
    height = currentHeight
    return {
      x: Math.round((dewarpCenterX * width) / 100),
      y: Math.round((dewarpCenterY * height) / 100),
      radius: Math.round((dewarpRadiusByHeight * height) / 100),
    }
  } else {
    return null
  }
}

export const pixel2Vector = (
  dewarpCenterX: number,
  dewarpCenterY: number,
  dewarpRadiusByHeight: number,
  width: number,
  height: number
) => {
  return {
    x: Math.round((dewarpCenterX / width) * 100 * 100) / 100,
    y: Math.round((dewarpCenterY / height) * 100 * 100) / 100,
    radius: Math.round((dewarpRadiusByHeight / height) * 100 * 100) / 100,
  }
}

export const getRoundTime = (
  currentTimeInSeconds: number,
  timelineUnit: string
) => {
  const currentTime = DateTime.fromSeconds(currentTimeInSeconds)
  const minute = currentTime.minute
  let interval = 0
  if (timelineUnit == 'H') {
    if (minute >= 30) {
      interval = 90
    } else {
      interval = 30
    }
  } else if (timelineUnit == 'M') {
    if (minute >= 45) {
      interval = 75
    } else if (currentTime.minute >= 15) {
      interval = 45
    } else {
      interval = 15
    }
  } else if (timelineUnit == 'S') {
    const integerMinutes = Math.round(minute / 10) * 10
    interval = integerMinutes + 5
  }

  return currentTime
    .set({ second: 0 })
    .plus({ minute: interval - minute })
    .toSeconds()
}

export const getTimeBiasBetweenLocalAndCameraInMinutes = (
  time: any,
  timezoneOffset: number,
  isDst: boolean
) => {
  const offset = time.offset
  if (isDst) {
    timezoneOffset += 1
  }
  return timezoneOffset * 60 - offset
}

export const getCameraNow = (timezoneOffset: number, isDst: boolean) => {
  const now = DateTime.local()
  const minutes = getTimeBiasBetweenLocalAndCameraInMinutes(
    now,
    timezoneOffset,
    isDst
  )
  return now.plus({ minutes: minutes })
}

export const getCameraDateTimeFromLocalMillis = (
  device: { timezoneID: number; isDstReplay: boolean; isDst: boolean },
  datetime: number,
  isReplay = false
) => {
  const isDst = isReplay ? device.isDstReplay : device.isDst
  const time = DateTime.fromMillis(Number(datetime))
  const minutes = getTimeBiasBetweenLocalAndCameraInMinutes(
    time,
    device.timezoneID - 12,
    isDst
  )
  return time.plus({ minutes: minutes })
}
