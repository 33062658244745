import repository from '@/repository'

const getDefaultState = () => {
  return {
    recentlyViewedLocations: [],
    recentlyViewedLocationsIsLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  recentlyViewedLocations: (state) => {
    return state.recentlyViewedLocations
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setRecentlyViewedLocations(state, value) {
    state.recentlyViewedLocations = value
  },
  setRecentlyViewedLocationsIsLoading(state, value) {
    state.recentlyViewedLocationsIsLoading = value
  },
}

const actions = {
  getRecentlyViewedLocations({ commit }, payload) {
    commit('setRecentlyViewedLocationsIsLoading', true)
    return repository.RecentlyViewed.GetLocations(payload)
      .then((res) => {
        commit('setRecentlyViewedLocations', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setRecentlyViewedLocationsIsLoading', false)
      })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
