// Page Views
export const LIBRARY_PAGE_VIEWED = 'Library Page Viewed'

// User Actions
export const USER_LOGIN = 'User Login'
export const HELP_BUTTON_CLICK = 'Help Button Clicked'
export const CAMERA_TILE_CLICK = 'Camera Tile Clicked'

// Unauthenticated actions/views
export const LOGIN_PAGE_VIEWED = 'Login Page Viewed'
export const FORGOT_PASSWORD_PAGE_VIEWED = 'Forgot Password Page Viewed'
export const RESEND_WELCOME_EMAIL = 'Resend Welcome Email Click'
export const SIGNUP_PAGE_VIEWED = 'Signup Page Viewed'
export const CREATE_ACCOUNT = 'Create Account'
export const TWO_FACTOR_PAGE_VIEWED = 'Two Factor Page Viewed'
export const ENTERED_2FA_CODE = 'Entered Two Factor Code'
export const REMEMBER_THIS_DEVICE = 'Remember This Device Checked'

export const FORGOT_PASSWORD_LINK_CLICK =
  'Forgot Username or Password Link Click'
export const RESET_PASSWORD_PAGE_VIEWED = 'Reset Password Page Viewed'
export const SEND_RESET_PASSWORD_EMAIL = 'Send Reset Password Email Click'
export const BACK_TO_LOGIN_CLICK = 'Back to Login Page Click'

export const RESET_PASSWORD_REQUEST = 'Request Reset Password'
export const FAILED_TWO_FACTOR_CODE = 'Failed Two Factor Code'

export const EULA_VIEWED = 'EULA Viewed'
// This event is also a property
export const EULA_ACCEPTED = 'EULA Accepted'

// Footer Events
export const PRIVACY_POLICY_CLICK = 'Privacy Policy Click'
export const BROWSER_COMPAT_CLICK = 'Browser Compatibility Click'

// Dashboard Events
export const DASHBOARD_PAGE_VIEWED = 'Dashboard Page Viewed'
export const BOOKMARKED_CAMERA_CLICK = 'Bookmarked Camera Click'
export const OPEN_CASES_CLICK = 'Open Cases By Location Click'
export const RECENT_CASE_ACTIVITY_CLICK = 'Recent Case Activity Click'
export const RECENT_CASE_CLICK = 'Recent Cases Click'
export const RECENTLY_VIEWED_LOCATIONS_CLICK = 'Recently Viewed Locations Click'
export const DASHBOARD_CAMERA_TROUBLE_CLICK =
  'Dashboard Camera In Trouble Click'
export const DASHBOARD_CAMERA_OFFLINE_CLICK = 'Dashboard Camera Offline Click'

// Dashboard Onboarding Events
export const DASHBOARD_ONBOARDING_VIEWED = 'Dashboard Onboarding Viewed'
export const DASHBOARD_ONBOARDING_FINISH_CLICK =
  'Dashboard Onboarding Finish Click'
export const DASHBOARD_ONBOARDING_CANCEL_CLICK =
  'Dashboard Onboarding Cancel Click'

// Location List Events
export const LOCATION_LIST_PAGE_VIEWED = 'Location List Page Viewed'
export const LOCATION_CLICK = 'Location Click'

// Location Overview Events
export const LOCATION_OVERVIEW_PAGE_VIEWED = 'Location Overview Page Viewed'
export const LOCATION_OVERVIEW_OPEN_CASES_CLICK =
  'Location Overview Open Cases Button Click'
export const LOCATION_OVERVIEW_PICKER_CLICK = 'Location Overview Picker Click'
export const LOCATION_OVERVIEW_LOCATION_CLICK =
  'Location Overview Location Click'
export const LOCATION_OVERVIEW_VIEW_MODE_CLICK =
  'Location Overview View Mode Click'

//Case Detail View
export const CASE_DETAIL_PAGE_VIEWED = 'Case Detail Page Viewed'
export const CASE_DETAIL_BACK_BUTTON = 'Case Detail Back Button Clicked'
export const CASE_DETAIL_LOCATION_LINK =
  'Case Detail Location Name Link Clicked'
export const CASE_DETAIL_DOWNLOAD_CLICK = 'Case Detail Download Clicked'
export const CASE_ARTIFACT_CLICK = 'Case Detail Artifact Clicked'
export const CASE_ARTIFACT_EDIT_CLICK = 'Case Detail Artifact Edit Clicked'
export const CASE_ARTIFACT_EDIT_CONFIRMED =
  'Case Detail Artifact Edit Confirmed'
export const CASE_ARTIFACT_EDIT_CANCELLED =
  'Case Detail Artifact Edit Cancelled'
export const CASE_ARTIFACT_REMOVE_CLICK = 'Case Detail Artifact Remove Click'
export const CASE_ARTIFACT_REMOVE_CANCELLED =
  'Case Detail Artifact Remove Cancelled'
export const CASE_ARTIFACT_REMOVE_CONFIRMED =
  'Case Detail Artifact Remove Confirmed'
export const CLIP_PLAY_BUTTON_CLICKED = 'Clip Play Button Clicked'
export const UPDATE_CLIP_PENCIL_ICON = 'Update Clip Pencil Icon'
export const UPDATE_SCREENSHOT_PENCIL_ICON = 'Update Screenshot Pencil Icon'
export const EDIT_CLIP_MODEL_ON_MAIN_PAGE = 'Edit Clip Modal On Main Page'
export const EDIT_SCREENSHOT_MODEL_ON_MAIN_PAGE =
  'Edit Screenshot Modal On Main Page'
export const UPDATE_CASE_DETAILS_PENCIL_ICON = 'Update Case Details Pencil Icon'
export const CASE_DETAILS_MODEL_UPDATE__BUTTON_CLICKED =
  'Case Details Modal Update Button Clicked'
export const CASE_DETAILS_MODEL_CANCEL__BUTTON_CLICKED =
  'Case Details Modal Cancel Button Clicked'
export const CASE_ADD_COMMENTS = 'Case Add Comments'

// Navbar Click Events
export const DASHBOARD_NAV_CLICK = 'Dashboard Navigation Click'
export const LOCATIONS_NAV_CLICK = 'Locations Navigation Click'
export const LIBRARY_NAV_CLICK = 'Library Navigation Click'
export const ADMIN_NAV_CLICK = 'Administration Navigation Click'

//Single Camera View Events
export const SINGLE_CAMERA_PAGE_VIEWED = 'Single Camera Page Viewed'
export const LOCATION_NAME_LINK_CLICK = 'Location Name Link Click'
export const SINGLE_CAMERA_BOOKMARK_CLICK = 'Single Camera Bookmark Click'
export const ALL_CAMERAS_BUTTON_CLICK = 'All Cameras Button Click'
export const SINGLE_CAMERA_ELLIPSIS_CAMERA_INFO_CLICK =
  'Single Camera Ellipsis Camera Info Click'
export const CAMERA_INFO_MODAL_VIEWED = 'Camera Info Modal Viewed'
export const SINGLE_CAMERA_ELLIPSIS_HELP_CLICK =
  'Single Camera Ellipsis Help Click'
export const SD_HD_TOGGLE_BUTTON = 'SD/HD Toggle Button'
export const CAMERA_ZOOM_IN = 'Camera Zoom In'
export const CAMERA_ZOOM_OUT = 'Camera Zoom Out'

// Administration (Users Tab)
export const ADMINISTRATION_PAGE_VIEWED = 'Administration page Viewed'
export const ADD_USER_BUTTON_CLICKED = 'Add User Button clicked'
export const SEND_WELCOME_EMAIL_CLICK = 'Send Welcome Email click'
export const ADD_USER_MODAL_ADD_USER_BUTTON_CLICK =
  'Add User Modal Add User button click'
export const ADD_USER_MODAL_CANCEL_BUTTON_CLICK =
  'Add User Modal Cancel button click'
export const FIND_A_USER_SEARCH_BAR = 'Find a User Search Bar'
export const DISABLE_USER_ELLIPSIS_MENU_ITEM = 'Disable User Ellipsis Menu Item'
export const ENABLE_USER_ELLIPSIS_MENU_ITEM = 'Enable User Ellipsis Menu Item'
export const DELETE_USER_ELLIPSIS_MENU_ITEM = 'Delete User Ellipsis Menu Item'
export const RESET_USER_ELLIPSIS_MENU_ITEM = 'Reset Password Ellipsis Menu Item'
export const USER_DISABLED_CONFIRMED = 'User Disabled Confirmed'
export const USER_DISABLED_CANCELLED = 'User Disabled Cancelled'
export const USER_ENABLED_CONFIRMED = 'User Enabled Confirmed'
export const USER_ENABLED_CANCELLED = 'User Enabled Cancelled'
export const USER_DELETED_CONFIRMED = 'User Deleted Confirmed'
export const USER_DELETED_CANCELLED = 'User Deleted Cancelled'
export const USER_PASSWORD_RESET_CONFIRMED = 'User Password Reset Confirmed'
export const USER_PASSWORD_RESET_CANCELLED = 'User Password Reset Cancelled'

// Administration (User Detail)
export const USER_DETAIL_PAGE_VIEWED = 'User Detail Page Viewed'
export const USER_DETAIL_PENCIL_EDIT_ICON_CLICKED =
  'User Detail Pencil Edit Icon clicked'
export const EDIT_USER_MODAL_SAVE_BUTTON_CLICKED =
  'Edit User Modal Save Button clicked'
export const EDIT_USER_MODAL_CANCEL_BUTTON_CLICKED =
  'Edit User Modal Cancel Button clicked'
export const DISABLE_ACCESS_LINK = 'Disable Access Link'
export const ENABLE_ACCESS_LINK = 'Enable Access Link'
export const RESET_ACCESS_LINK = 'Reset Password Link'
export const DELETE_USER_BUTTON_CLICK = 'Delete User Button click'

// Administration (User Detail Assigned Locations Tab)
export const EDIT_LOCATIONS_BUTTON_CLICK =
  'Customer Admin - Edit Locations Button Click'
export const USER_ASSIGN_ALL_LOCATIONS_BUTTON_CLICK =
  'Customer Admin - Assign to All Locations Button click'
export const SELECT_LOCATIONS_MANUALLY_BUTTON_CLICK =
  'Customer Admin - Select Locations Manually click'
export const SAVE_LOCATIONS_BUTTON_CLICK =
  'Customer Admin - Locations Assigned Save button click'
export const SAVE_LOCATIONS_CANCEL_BUTTON_CLICK =
  'Customer Admin - Locations Assigned Cancel button click'

// Administration (User Detail Role and Permission Tab)
export const EDIT_ROLE_BUTTON_CLICK = 'Edit Role Button click'
export const SELECT_USER_ROLE_DROPDOWN_CLICKED = 'Select a User Role'
export const SAVE_ROLE_BUTTON_CLICK = 'User Role Save button click'
export const SAVE_ROLE_CANCEL_BUTTON_CLICK = 'User Role Cancel button click'

// Administration (Dealer Users Tab)
export const DEALER_USER_TAB_VIEWED = 'Dealer Users Tab Viewed'
export const DEALER_STATUS_ENABLED = 'Dealer Status Enabled'
export const DEALER_USER_ENABLE_CONFIRM = 'Dealer User Enabled Confirmed'
export const DEALER_USER_ENABLE_CANCEL = 'Dealer User Enabled Cancelled'
export const DEALER_STATUS_DISABLED = 'Dealer Status Disabled'
export const DEALER_USER_DISABLE_CONFIRM = 'Dealer User Disabled Confirmed'
export const DEALER_USER_DISABLE_CANCEL = 'Dealer User Disabled Cancelled'

// Administration (Roles and Permissions Tab)
export const ROLES_AND_PERMISSIONS_TAB_VIEWED =
  'Roles and Permissions Tab Viewed'
export const ROLES_NUM_OF_PERMISSIONS_HOVER_CLICKED =
  'Hover Over Role Number of Permissions click'
export const ROLES_NUM_OF_USERS_ASSIGNED_HOVER_CLICKED =
  'Hover Over Number of Users Assigned click'

export const VAR_USER_ROLES_AND_PERMISSIONS_TAB_VIEWED =
  'Var User Roles and Permissions Tab Viewed'
export const VAR_USER_VIEWS_USERS_ASSIGNED_TO_A_ROLE =
  'Var User Views Users Assigned to a Role'

//Single Camera Orientation Overlays
export const SINGLE_CAMERA_VIEWED = 'Single Camera Orientation Overlay Viewed'
export const SINGLE_CAMERA_FINISH_BUTTON_CLICKED =
  'Single Camera Orientation Overlay Finish Button Clicked'

// Create Clip Events
export const CLIP_BUTTON_CLICKED = 'Clip Button Clicked'
export const VIDEO_CLIP_BUTTON = 'Video Clip Button Clicked'
export const SCREENSHOT_BUTTON = 'Screenshot Button Clicked'
export const SAVE_CLIP = 'Save Clip Button Clicked'
export const SELECT_EXISTING_CASE = 'Select Existing Case'
export const ADD_ARTIFACT_NEXT = 'Add Artifact to Case Next Clicked'
export const ADD_ARTIFACT_CANCEL = 'Add Artifact to Case Cancel Clicked'
export const ADD_ARTIFACT_TO_EXISTING_CASE = 'Add Artifact to Existing Case'
export const ADD_ARTIFACT_TO_NEW_CASE = 'Add Artifact to New Case'
export const VIEW_CASE_CLICK = 'View Case Button Click'

//Case Library Page
export const CASE_LIBRARY_PAGE_VIEWED = 'Case Library Page Viewed'
export const CASE_STATUS_FILTERED_CLICK = 'Case Status Filtered Click'
export const LOCATION_SEARCH = 'Location Search'
export const INCIDENT_TYPE = 'Incident Type'
export const DATE_CREATED = 'Date Created'
export const FIND_CASE_ENTRY = 'Find a Case Entry'
export const OPEN_CASE_CLICK = 'Open a Case Click'
export const OPEN_MOST_RECENTLY_VIEWED_CLICK = 'Open Most Recently Viewed Click'
export const PAGINATION_BUTTON_CLICK = 'Pagination button Click'

//My Account Page
export const MY_ACCOUNT_PAGE_VIEWED = 'My Account Page Viewed'
export const MY_ACCOUNT_HELP_MENU_VIEWED = 'Help Page Viewed'
export const MY_ACCOUNT_LOGOUT_CLICKED = 'Logout click'
export const MY_ACCOUNT_SAVE_CHANGES_BUTTON_CLICK =
  'My Details Save Changes Button click'
export const MY_ACCOUNT_PASSWORD_SAVE_CHANGES_BUTTON_CLICK =
  'Change Password Save Changes Button click'

// Dealer Events
export const DEALER_CUSTOMER_LIST_VIEWED = 'Customer Management List Viewed'
export const DEALER_CUSTOMER_ADDED = 'Customer Added'
export const DEALER_CUSTOMER_EDIT = 'Customer Edit Saved'
export const DEALER_VIEWED_CUSTOMER_SIDE = 'Login to Customer App'

export const DEALER_CUSTOMER_LOCATIONS_VIEWED = 'Customer Locations List Viewed'
export const DEALER_CUSTOMER_USERS_VIEWED = 'Single Customer Users List viewed'
export const DEALER_CUSTOMER_DEALERS_LIST_VIEWED =
  'Single Customer Dealer Users List Viewed'
export const DEALER_CUSTOMER_LOCATION_ADDED = 'Customer Location Added'
export const DEALER_CUSTOMER_LOCATION_EDIT = 'Customer Location Edit Saved'

export const DEALER_CUSTOMER_CAMERA_LIST_VIEWED =
  'Customer Location Cameras List Viewed'
export const DEALER_CUSTOMER_CAMERA_ADDED = 'Customer Camera Added'
export const DEALER_CUSTOMER_CAMERA_AUTHORIZED = 'Customer Camera Authorized'
export const DEALER_CUSTOMER_CAMERA_ACTIVATED = 'Customer Camera Activated'
export const DEALER_REFRESH_CAMERAS = 'Refresh Cameras (Customer Record)'
export const DEALER_CAMERA_CHECK_CONNECTION = 'Check for Connection (Camera)'
export const DEALER_CAMERA_SETTINGS_SAVED = 'Camera Settings Saved'

export const DEALER_MY_USERS_LIST_VIEWED = 'Var My Users List viewed'
export const DEALER_CREATE_DEALER_USER = 'Var User Creates User'
export const DEALER_USER_MODIFY_USER = 'Var User Modifies User'
export const DEALER_USER_DELETE_USER = 'Var User Deletes User'
export const DEALER_USER_DISABLE_USER = 'Var User Disables User'
export const DEALER_USER_ENABLE_USER = 'Var User Enables User'
export const DEALER_USER_RESET_USER_PASSWORD = 'Var User Resets User Password'
export const DEALER_USER_MODIFY_USER_ROLE = 'Var User Modifies User Role'
export const DEALER_USER_SET_PRIMARY_CONTACT =
  'Var User Makes User Primary Contact'
