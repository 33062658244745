//import repository from '@/repository'

import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): AccessTokensState => {
  return {
    accessKeysIsLoading: false,
    accessKeys: [],
    locationsDeviceList: [],
    singleTokenLocationsList: [],
    newKeyString: '',
    newKeyLoading: false,
    deleteKeyLoading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<AccessTokensState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setAccessKeysIsLoading(state, value) {
    state.accessKeysIsLoading = value
  },
  setAccessKeys(state, value) {
    state.accessKeys = value
  },
  setLocationsDeviceList(state, value) {
    state.locationsDeviceList = value
  },
  setSingleTokenLocationsList(state, value) {
    state.singleTokenLocationsList = value
  },
  setNewKeyString(state, value) {
    state.newKeyString = value
  },
  setNewKeyLoading(state, value) {
    state.newKeyLoading = value
  },
  setDeleteKeyLoading(state, value) {
    state.deleteKeyLoading = value
  },
}

const actions: ActionTree<AccessTokensState, RootState> = {
  getCustomerAccessKeys({ commit }) {
    commit('setAccessKeysIsLoading', true)
    const accessKeysApi = [
      {
        id: 2,
        keyName: 'Test Key',
        enabled: true,
        createdOn: '3-31-2022',
        expiryDate: '4-24-2023',
      },
    ]
    commit('setAccessKeys', accessKeysApi)
    const locationsDeviceList = []
    const singleTokenLocationsList = []
    commit('setLocationsDeviceList', locationsDeviceList)
    commit('setSingleTokenLocationsList', singleTokenLocationsList)
    setTimeout(() => {
      commit('setAccessKeysIsLoading', false)
    }, 1500)
  },
  async createCustomerAccessKey({ commit }) {
    commit('setNewKeyLoading', true)
    const newKeyString = 'newKey123'

    await new Promise((resolve) => setTimeout(resolve, 1500))

    commit('setNewKeyString', newKeyString)
    commit('setNewKeyLoading', false)
  },
  resetNewKey({ commit }) {
    commit('setNewKeyString', '')
  },
  async deleteCustomerAccessKey({ commit }) {
    commit('setDeleteKeyLoading', true)

    await new Promise((resolve) => setTimeout(resolve, 1500))

    commit('setDeleteKeyLoading', false)
  },
}

const AccessTokens: Module<AccessTokensState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default AccessTokens

interface AccessTokensState {
  accessKeysIsLoading: boolean
  accessKeys: AccessKey[]
  locationsDeviceList: any[]
  singleTokenLocationsList: any[]
  newKeyString: string
  newKeyLoading: boolean
  deleteKeyLoading: boolean
}

interface AccessKey {
  id: number
}
