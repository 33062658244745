
import { defineComponent } from 'vue'
import { RESEND_WELCOME_EMAIL } from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'ResendWelcomeEmail',
  data() {
    return {
      resendLoading: false,
      showAlmostDone: false,
    }
  },
  methods: {
    clickResendEmailBtn() {
      this.$mixpanelTrack(RESEND_WELCOME_EMAIL)
      this.resendLoading = true
      this.$emit('sendWelcomeEmail')
      setTimeout(() => {
        this.resendLoading = false
        this.showAlmostDone = true
      }, 750)
    },
  },
})
