import { render, staticRenderFns } from "./gearIcon.vue?vue&type=template&id=08baf6e3&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports