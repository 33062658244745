import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): MultiViewState => {
  return {
    multiViewList: [],
    multiViewListIsLoading: false,
    locationsAndCameras: [],
    locationsAndCamerasIsLoading: false,
    saveViewIsLoading: false,
    deleteMultiViewError: false,
    singleMultiView: {
      id: -1,
      devices: [],
    },
    singleMultiViewIsLoading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<MultiViewState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setSingleMultiView(state, value) {
    state.singleMultiView = value
  },
  setSingleMultiViewIsLoading(state, value) {
    state.singleMultiViewIsLoading = value
  },
  setMultiViewList(state, value) {
    const sortedDeviceList = [...value]
    sortedDeviceList.forEach((view) =>
      view.devices.sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    )
    state.multiViewList = sortedDeviceList
  },
  addMultiView(state, value) {
    const viewIndex = state.multiViewList.findIndex(
      (view) => view.id == value.id
    )
    if (viewIndex == -1) {
      state.multiViewList.push(value)
    } else {
      state.multiViewList[viewIndex] = value
      state.multiViewList = [...state.multiViewList]
    }
  },
  removeMultiView(state, value) {
    state.multiViewList = state.multiViewList.filter((view) => {
      return view.id != value.id
    })
  },
  setMultiViewListIsLoading(state, value) {
    state.multiViewListIsLoading = value
  },
  setLocationsAndCameras(state, value) {
    state.locationsAndCameras = value
  },
  setLocationsAndCamerasIsLoading(state, value) {
    state.locationsAndCamerasIsLoading = value
  },
  setUpdateMultiViewIsLoading(state, value) {
    state.saveViewIsLoading = value
  },
  setDeleteMultiViewError(state, value) {
    state.deleteMultiViewError = value
  },
}

const actions: ActionTree<MultiViewState, RootState> = {
  async getMultiViewList({ commit }, payload) {
    commit('setMultiViewListIsLoading', true)
    return repository.MultiView.GetAllUserProfiles(payload)
      .then((res) => {
        commit('setMultiViewList', res.data)
      })
      .catch(() => {
        commit('setMultiViewList', [])
      })
      .finally(() => {
        commit('setMultiViewListIsLoading', false)
      })
  },
  async createUpdateMultiView({ commit }, payload) {
    commit('setUpdateMultiViewIsLoading', true)
    return repository.MultiView.CreateOrUpdate(payload)
      .then((res) => {
        payload.id = res.data.id
        commit('addMultiView', payload)
      })
      .finally(() => {
        commit('setUpdateMultiViewIsLoading', false)
      })
  },
  async deleteMultiView({ commit }, payload) {
    commit('setUpdateMultiViewIsLoading', true)
    commit('setDeleteMultiViewError', false)

    return repository.MultiView.Delete(payload)
      .then(() => {
        commit('removeMultiView', payload)
      })
      .catch(() => {
        commit('setDeleteMultiViewError', true)
      })
      .finally(() => {
        commit('setUpdateMultiViewIsLoading', false)
      })
  },
  async getLocationsAndCameras({ commit }, payload) {
    commit('setLocationsAndCamerasIsLoading', true)
    return repository.Device.GetLocationDeviceTags(payload)
      .then((res) => {
        commit(
          'setLocationsAndCameras',
          res.data.filter((location) => {
            return location.devices.length > 0
          })
        )
      })
      .finally(() => {
        commit('setLocationsAndCamerasIsLoading', false)
      })
  },
  async getSingleMultiView({ commit }, payload) {
    commit('setSingleMultiViewIsLoading', true)
    return repository.MultiView.GetUserProfileById(payload)
      .then((res) => {
        commit('setSingleMultiView', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setSingleMultiViewIsLoading', false)
      })
  },
}
const MultiView: Module<MultiViewState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default MultiView

interface MultiViewState {
  multiViewList: MultiView[]
  multiViewListIsLoading: boolean
  locationsAndCameras: any[]
  locationsAndCamerasIsLoading: boolean
  saveViewIsLoading: boolean
  deleteMultiViewError: boolean
  singleMultiView: MultiView
  singleMultiViewIsLoading: boolean
}

interface MultiView {
  id: number
  devices: any[]
}
