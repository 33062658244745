import { DealerUser } from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): SuperAdminDealerUsersState => {
  return {
    dealerUserList: [],
    dealerUserListIsLoading: false,
    userEnableDisableIsLoading: false,
    userEnableDisableSuccess: false,
    userEnableDisableErrorMessage: '',
    userDeleteIsLoading: false,
    userDeleteSuccess: false,
    userDeleteErrorMessage: '',
  }
}

const state = getDefaultState()

const getters: GetterTree<SuperAdminDealerUsersState, RootState> = {
  currentUser: (state) => (userId: number) => {
    const selectedUser = state.dealerUserList.find((user) => {
      return user.userId === userId
    })
    if (typeof selectedUser === 'undefined') return 0
    else return selectedUser
  },
}

const mutations: MutationTree<SuperAdminDealerUsersState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDealerUserList(state, value) {
    state.dealerUserList = value
  },
  setDealerUserListIsLoading(state, value) {
    state.dealerUserListIsLoading = value
  },
  setUserDeleteIsLoading(state, value) {
    state.userDeleteIsLoading = value
  },
  setUserDeleteSuccess(state, value) {
    state.userDeleteSuccess = value
  },
  setUserDeleteErrorMessage(state, value) {
    state.userDeleteErrorMessage = value
  },
  setUserEnableDisableIsLoading(state, value) {
    state.userEnableDisableIsLoading = value
  },
  setUserEnableDisableSuccess(state, value) {
    state.userEnableDisableSuccess = value
  },
  setUserEnableDisableErrorMessage(state, value) {
    state.userEnableDisableErrorMessage = value
  },
  setPrimaryContact(state, value) {
    state.dealerUserList.forEach((element) => {
      element.isPrimaryContact = false
      if (element.userId == value) {
        element.isPrimaryContact = true
      }
    })
  },
  setDeleteUser(state, value) {
    state.dealerUserList = state.dealerUserList.filter((user) => {
      return user.userId !== value.userId
    })
  },
  setUpdateUserEnableDisable(state, value) {
    state.dealerUserList.forEach((user) => {
      if (value.userId === user.userId) {
        user.isActive = !value.isActive
        if (user.isActive) {
          user.status = 'Enabled'
        } else {
          user.status = 'Disabled'
        }
      }
    })
  },
}

const actions: ActionTree<SuperAdminDealerUsersState, RootState> = {
  async getDealerUserList({ commit }, payload) {
    commit('setDealerUserListIsLoading', true)
    commit('setDealerUserList', [])
    return repository.Dealer.GetUsersByDealer(payload)
      .then((res) => {
        commit('setDealerUserList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setDealerUserListIsLoading', false)
      })
  },
  async setPrimary({ commit }, payload) {
    commit('setUpdateUserInfoIsLoading', true)
    return repository.Dealer.SetPrimary(payload)
      .then(() => {
        commit('setPrimaryContact', payload.UserId)
        commit('setUpdateUserInfoSuccess', true)
      })
      .catch(() => {
        commit('setUpdateUserInfoSuccess', false)
      })
      .finally(() => {
        commit('setUpdateUserInfoIsLoading', false)
      })
  },
  clearUpdateUserError({ commit }) {
    commit('setUpdateUserInfoErrorMessage', '')
    commit('setUpdateUserInfoSuccess', false)
  },
  async deleteUser({ commit }, payload) {
    commit('setUserDeleteIsLoading', true)
    const apiPayload = {
      UserId: payload.userId,
    }
    return repository.User.DeleteUser(apiPayload)
      .then(() => {
        commit('setUserDeleteSuccess', true)
        commit('setDeleteUser', payload)
      })
      .catch((err) => {
        commit('setUserDeleteSuccess', false)
        commit('setUserDeleteErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserDeleteIsLoading', false)
      })
  },
  clearDeleteUserError({ commit }) {
    commit('setUserDeleteErrorMessage', '')
    commit('setUserDeleteSuccess', false)
  },
  async enableDisableUser({ commit }, payload) {
    commit('setUserEnableDisableIsLoading', true)
    const apiPayload = {
      UserId: payload.userId,
      IsEnabled: !payload.isActive,
    }
    return repository.User.EnableDisableUser(apiPayload)
      .then(() => {
        commit('setUserEnableDisableSuccess', true)
        commit('setUpdateUserEnableDisable', payload)
      })
      .catch((err) => {
        commit('setUserEnableDisableSuccess', false)
        commit('setUserEnableDisableErrorMessage', err.response.data.message)
      })
      .finally(() => {
        commit('setUserEnableDisableIsLoading', false)
      })
  },
  clearEnableDisableUserError({ commit }) {
    commit('setUserEnableDisableErrorMessage', '')
    commit('setUserEnableDisableSuccess', false)
  },
  callResetPassword(_, payload) {
    return repository.User.ResetUserPassword(payload)
  },
}
const SuperAdminDealerUsers: Module<SuperAdminDealerUsersState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default SuperAdminDealerUsers

interface SuperAdminDealerUsersState {
  dealerUserList: Partial<DealerUser>[]
  dealerUserListIsLoading: boolean
  userEnableDisableIsLoading: boolean
  userEnableDisableSuccess: boolean
  userEnableDisableErrorMessage: string
  userDeleteIsLoading: boolean
  userDeleteSuccess: boolean
  userDeleteErrorMessage: string
}
