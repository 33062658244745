import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr'
import store from '@/store'

const webSocketRoot = '/api/serverhub'

const localClient = new HubConnectionBuilder()
  .withUrl(webSocketRoot, {
    transport: HttpTransportType.WebSockets,
    skipNegotiation: true,
  })
  .withAutomaticReconnect()
  .configureLogging(LogLevel.None)
  .build()

export function initClient() {
  console.log('calling initClient')
  localClient.on('stateChanged', (oldState, newState) => {
    if (oldState !== newState && newState !== 'Connected')
      store.dispatch('WebSocket/connectionClosed')
    else store.dispatch('WebSocket/connectionOpened')
  })

  localClient.on('ReceivedMessage', (message) => {
    console.log('ReceivedMessage', message)
    store.dispatch('WebSocket/callAddMessage', message)
  })
  localClient.on('DownloadReady', (message) => {
    store.dispatch('WebSocket/callDownloadReady', message)
  })
  localClient.on('Error', (message) => {
    console.log('websockets error', message)
    store.dispatch('WebSocket/callDownloadError', message)
  })
  localClient.on('AllWebNotificationReceived', (message) => {
    store.dispatch('Notifications/setPreviousNotifications', message)
  })
  localClient.on('NewWebNotificationReceived', (message) => {
    if (message.length > 0)
      store.dispatch('Notifications/addNewNotifications', message)
  })

  localClient
    .start()
    .then(() => {
      store.dispatch('WebSocket/connectionOpened')
      console.log('Connection started')
    })
    .catch((err) => {
      console.log('Error while starting connection', err)
      store.dispatch('WebSocket/connectionError', err)
    })
}

export function stopClient() {
  localClient.stop()
}

export function sendDownload(payload: any) {
  return localClient.invoke('Case_CaseDownload', payload)
}

export function getAllNotifications(payload: any) {
  return localClient.invoke('Notification_GetAllNotifications', payload)
}

export function getNewNotifications(payload: any) {
  return localClient.invoke('Notification_GetNewNotifications', payload)
}
