import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): VideoState => {
  return {
    downloading: false,
  }
}

const state = getDefaultState()

const mutations: MutationTree<VideoState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDownloading(state, value) {
    state.downloading = value
  },
}

const actions: ActionTree<VideoState, RootState> = {
  async getDownloadVideoClip(_, payload) {
    return repository.Video.DownloadVideoClip(payload)
  },
  async getProgress(_, payload) {
    return repository.Video.GetProgress(payload)
  },
}

const Video: Module<VideoState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Video

interface VideoState {
  downloading: boolean
}
