import repository from '@/repository'
import { getQuery } from '@/utils/tools'
import i18n from '../../i18n'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import {
  DeviceObject,
  DeviceAdvancedSetting,
  DeviceAdvancedAnalytics,
  DeviceBurntInAnnotation,
  VCAEvent,
} from '@/models'

const getDefaultState = (): DeviceState => {
  return {
    devicesStatus: [],
    devicesStatusIsLoading: false,
    device: {},
    deviceLoading: false,
    currentClip: [],
    currentClipLoading: false,
    sessionTokens: [],
    updateCameraSettingsError: false,
    updateCameraSettingsSuccess: false,
    deviceDefaultSetting: null,
    deviceAdvancedSetting: null,
    deviceAdvancedAnalytics: null,
    deviceBurntInAnnotation: {},
    vcaEvents: null,
    deviceMotionSetting: null,
    restoreDeviceSettingError: false,
    isAdjustingSettings: false,
    activateCameraError: false,
    connectCloudError: false,
    cameraApiError: false,
    cameraApiErrorMessage: '',
    currentVideoWidth: 0,
    currentVideoHeight: 0,
    deviceStartPlaybackLoading: false,
    deviceErrorMessage: null,
    advancedAnalyticsRuleTypes: null,
    advancedAnalyticsCustomTypes: null,
    advancedAnalyticsRuleObjects: null,
    advancedAnalyticsRuleSetOptions: null,
    advancedAnalyticsRuleIsLoading: false,
    deleteVCARuleIsLoading: false,
    loadVcaRulesError: false,
    updateVcaRuleSetError: false,
    updateVcaRuleSetErrorMessage: '',
    currentVcaRuleTemplateInfo: null,
    deviceNoFootage: { deviceId: 0 },
    singleVCAEvent: { eventId: 0 },
    updateDeviceTimezoneByIdError: false,
    updateDeviceTimezoneByIdLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<DeviceState, RootState> = {
  devicesStatus: (state) => {
    return state.devicesStatus
  },
  device: (state) => {
    return state.device
  },
}

const mutations: MutationTree<DeviceState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDevicesStatus(state, value) {
    state.devicesStatus = value
  },
  setDevicesStatusIsLoading(state, value) {
    state.devicesStatusIsLoading = value
  },
  setDevice(state, value) {
    state.device = value
  },
  setDeviceLoading(state, value) {
    state.deviceLoading = value
  },
  updateDeviceBookmarkStatus(state, value) {
    state.device.isBookMarked = value.isBookMarked
  },
  updateIsDstReplay(state, value) {
    state.device.isDstReplay = value
  },
  setCurrentClip(state, value) {
    state.currentClip = value
  },
  setCurrentClipLoading(state, value) {
    state.currentClipLoading = value
  },
  setVideoToken(state, payload) {
    state.sessionTokens[payload.mac] = payload.token
  },
  updatePlaybackSourceStatus(state, value) {
    state.device.playbackSource = value
  },
  setUpdateCameraSettingsError(state, value) {
    state.updateCameraSettingsError = value
  },
  setUpdateCameraSettingsSuccess(state, value) {
    state.updateCameraSettingsSuccess = value
  },
  setActivateCameraError(state, value) {
    state.activateCameraError = value
  },
  setConnectCloudError(state, value) {
    state.connectCloudError = value
  },
  setCameraApiError(state, value) {
    state.cameraApiError = value
  },
  setCameraApiErrorMessage(state, value) {
    state.cameraApiErrorMessage = value
  },
  setDeviceDefaultSetting(state, value) {
    if (value && state.deviceDefaultSetting) {
      const obj = JSON.parse(JSON.stringify(state.deviceDefaultSetting))
      state.deviceDefaultSetting = Object.assign(obj, value)
    } else {
      state.deviceDefaultSetting = value
    }
  },
  setDeviceAdvancedSetting(state, value) {
    state.deviceAdvancedSetting = value
  },
  setDeviceMotionSetting(state, value) {
    state.deviceMotionSetting = value
  },
  updateDeviceAdvancedSetting(state, value) {
    if (state.deviceAdvancedSetting != null) {
      Object.keys(value).forEach((key) => {
        state.deviceAdvancedSetting[key] = value[key]
      })
    }
  },
  setDeviceAdvancedAnalytics(state, value) {
    state.deviceAdvancedAnalytics = value
  },
  updateDeviceAdvancedAnalytics(state, value) {
    if (state.deviceAdvancedAnalytics != null) {
      state.deviceAdvancedAnalytics = state.deviceAdvancedAnalytics.map(
        (item) => (item.zone.id == value.zone.id ? value : item)
      )
    }
  },
  setDeviceBurntInAnnotation(state, value) {
    state.deviceBurntInAnnotation = value
  },
  updateDeviceBurntInAnnotation(state, value) {
    state.deviceBurntInAnnotation = {
      ...state.deviceBurntInAnnotation,
      ...value,
    }
  },
  setVCAEvents(state, value) {
    state.vcaEvents = value
  },
  setRestoreDeviceSettingError(state, value) {
    state.restoreDeviceSettingError = value
  },
  setIsAdjustingSettings(state, value) {
    state.isAdjustingSettings = value
  },
  setCurrentVideoWidth(state, value) {
    state.currentVideoWidth = value
  },
  setCurrentVideoHeight(state, value) {
    state.currentVideoHeight = value
  },
  setDeviceStartPlaybackLoading(state, value) {
    state.deviceStartPlaybackLoading = value
  },
  setDeviceErrorMessage(state, value) {
    state.deviceErrorMessage = value
  },
  updateNVRCameraName(state, value) {
    if (state.device != null && state.device.deviceId == value.deviceId) {
      state.device.deviceName = value.deviceName
    }
  },
  updateDewarpDeviceInfo(state, value) {
    if (state.device != null && state.device.deviceId == value.deviceId) {
      state.device.dewarpMount = value.dewarpMount
      state.device.dewarpCenterX = value.dewarpCenterX
      state.device.dewarpCenterY = value.dewarpCenterY
      state.device.dewarpRadiusByHeight = value.dewarpRadiusByHeight
    }
  },
  setAdvancedAnalyticsRuleTypes(state, value) {
    state.advancedAnalyticsRuleTypes = value
  },
  setAdvancedAnalyticsCustomTypes(state, value) {
    state.advancedAnalyticsCustomTypes = value
  },
  setAdvancedAnalyticsRuleObjects(state, value) {
    state.advancedAnalyticsRuleObjects = value
  },
  setAdvancedAnalyticsRuleSetOptions(state, value) {
    state.advancedAnalyticsRuleSetOptions = value
  },
  setAdvancedAnalyticsRuleIsLoading(state, value) {
    state.advancedAnalyticsRuleIsLoading = value
  },
  setUpdateVcaRuleSetError(state, value) {
    state.updateVcaRuleSetError = value
  },
  setUpdateDeviceTimezoneByIdError(state, value) {
    state.updateDeviceTimezoneByIdError = value
  },
  setUpdateDeviceTimezoneByIdLoading(state, value) {
    state.updateDeviceTimezoneByIdLoading = value
  },
  setLoadVcaRulesError(state, value) {
    state.loadVcaRulesError = value
  },
  setUpdateVcaRuleSetErrorMessage(state, value) {
    state.updateVcaRuleSetErrorMessage = value
  },
  setCurrentVcaRuleTemplateInfo(state, value) {
    state.currentVcaRuleTemplateInfo = value
  },
  setDeleteVCARuleIsLoading(state, value) {
    state.deleteVCARuleIsLoading = value
  },
  setDeviceNoFootage(state, value) {
    state.deviceNoFootage = value
  },
  setSingleVCAEvent(state, value) {
    state.singleVCAEvent = value
  },
}

const actions: ActionTree<DeviceState, RootState> = {
  async getDevicesStatus({ commit }, payload) {
    commit('setDevicesStatusIsLoading', true)
    return repository.Device.GetDevicesStatus(payload)
      .then((res) => {
        commit('setDevicesStatus', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setDevicesStatusIsLoading', false)
      })
  },
  async getDeviceByID({ commit }, payload) {
    commit('setDeviceLoading', true)

    return repository.Device.GetDeviceByID(payload)
      .then((res) => {
        commit('setDevice', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setDeviceLoading', false)
      })
  },
  async getDevicePlayback({ commit }, payload) {
    //commit('setDeviceLoading', true)

    return repository.Device.GetDevicePlayback(payload).then((res) => {
      if (res.data.deviceStatus == '') {
        res.data.deviceStatus = payload.deviceStatus
      }
      commit('setDevice', res.data)
    })
    //.catch(() => {})
    //.finally(() => {
    //  commit('setDeviceLoading', false)
    //})
  },
  async bookmarkDevice({ commit }, payload) {
    return repository.Device.BookMarkDevice(payload)
      .then(() => {
        commit('updateDeviceBookmarkStatus', payload)
      })
      .catch(() => {})
  },
  async getCurrentClip({ commit }, payload) {
    commit('setCurrentClipLoading', true)

    return repository.Device.GetDeviceVideoOnDemandSegment(payload)
      .then((res) => {
        commit('setCurrentClip', res.data)
        if (res.data.isDstReplay) {
          commit('updateIsDstReplay', res.data.isDstReplay)
        }
      })
      .catch(() => {})
      .finally(() => {
        commit('setCurrentClipLoading', false)
      })
  },
  rebootDevice(_, payload) {
    return repository.Device.RebootDevice(payload)
  },
  configDevice(_, payload) {
    return repository.Device.ConfigDevice(payload)
  },
  async updateDeviceStatus({ commit }, payload) {
    return repository.Device.UpdateDeviceStatus(payload)
      .then(() => {})
      .catch(() => {
        commit('setActivateCameraError', true)
      })
  },
  async updateVideoStreamStatus(_, payload) {
    return repository.Device.UpdateVideoStreamStatus(
      payload.parm,
      payload.config
    )
      .then(() => {})
      .catch(() => {})
  },
  async postActivateDevice({ commit }, payload) {
    return repository.Device.PostActivateDevice(payload)
      .then(() => {})
      .catch(() => {
        commit('setActivateCameraError', true)
      })
  },
  async upgradeDevice({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.UpgradeDevice(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateCameraSettingsError', false)
        } else {
          commit('setUpdateCameraSettingsError', true)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setDeviceResolution({ commit }, payload) {
    return repository.Device.SetDeviceDefaultResolution(payload)
      .then((res) => {
        if (!res.data) {
          commit('setUpdateCameraSettingsError', true)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
      })
  },
  async setDeviceBitrate({ commit, state }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceBitrate(payload)
      .then((res) => {
        if (res.data) {
          const userSetting =
            JSON.parse(JSON.stringify(state.deviceDefaultSetting)) || {}
          userSetting.bitrate = payload.bitrate
          commit('setDeviceDefaultSetting', userSetting)
          commit('setUpdateCameraSettingsSuccess', true)
          return Promise.resolve(true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          commit('setRestoreDeviceSettingError', true)
          return Promise.resolve(false)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setDeviceFpsGop({ commit, state }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceFpsGop(payload)
      .then((res) => {
        if (res.data) {
          const userSetting =
            JSON.parse(JSON.stringify(state.deviceDefaultSetting)) || {}
          commit('setDeviceDefaultSetting', { ...userSetting, ...payload })
          commit('setUpdateCameraSettingsSuccess', true)
          return Promise.resolve(true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          commit('setRestoreDeviceSettingError', true)
          return Promise.resolve(false)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setDeviceAudioEnabled({ commit, state }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceAudioEnabled(payload)
      .then((res) => {
        if (res.data) {
          const userSetting =
            JSON.parse(JSON.stringify(state.deviceDefaultSetting)) || {}
          commit('setDeviceDefaultSetting', { ...userSetting, ...payload })
          commit('setUpdateCameraSettingsSuccess', true)
          return Promise.resolve(true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          commit('setRestoreDeviceSettingError', true)
          return Promise.resolve(false)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async createSignalingChannel({ commit }, payload) {
    return repository.Device.CreateSignalingChannel(payload)
      .then((res) => {
        if (!res.data) {
          commit('setConnectCloudError', true)
        }
      })
      .catch(() => {
        commit('setConnectCloudError', true)
      })
  },
  async activateDevice({ commit }, payload) {
    return repository.Device.ActivateDevice(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch(() => {
        commit('setConnectCloudError', true)
        return Promise.resolve(null)
      })
  },
  async deactivateDevice({ commit }, payload) {
    return repository.Device.DeactivateDevice(payload)
      .then((res) => {
        if (res) {
          commit('setDeviceErrorMessage', null)
        } else {
          commit(
            'setDeviceErrorMessage',
            i18n.t('errorMessages.camera.cameraDeactivationFailed')
          )
        }
      })
      .catch(() => {
        commit(
          'setDeviceErrorMessage',
          i18n.t('errorMessages.camera.cameraDeactivationFailed')
        )
      })
      .finally(() => {})
  },
  async deleteDevice({ commit }, payload) {
    return repository.Device.DeleteDevice(payload)
      .then((res) => {
        if (res) {
          commit('setDeviceErrorMessage', null)
        } else {
          commit(
            'setDeviceErrorMessage',
            i18n.t('errorMessages.camera.removeCameraError')
          )
        }
      })
      .catch(() => {
        commit(
          'setDeviceErrorMessage',
          i18n.t('errorMessages.camera.removeCameraError')
        )
      })
      .finally(() => {})
  },
  async changeDevicesPassword({ commit }, payload) {
    return repository.Device.ChangeDevicesPassword(payload)
      .then((res) => {
        if (!res.data) {
          commit('setConnectCloudError', true)
        }
      })
      .catch(() => {
        commit('setConnectCloudError', true)
      })
  },
  async updateSdStorageLimitDays({ commit }, payload) {
    return repository.Device.UpdateSdStorageLimitDays(payload)
      .then((res) => {
        if (!res.data) {
          commit('setConnectCloudError', true)
        }
      })
      .catch(() => {
        commit('setConnectCloudError', true)
      })
  },
  getDevicesPassword(_, payload) {
    return repository.Device.GetDevicesPassword(payload)
  },
  getFirmwareUpdateStatus(_, payload) {
    return repository.Device.GetFirmwareUpdateStatus(payload)
  },
  getDeviceFlipMirror(_, payload) {
    return repository.Device.GetDeviceFlipMirror(payload)
  },
  async updatePackage({ commit, state }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.UpdatePackage(payload)
      .then((res) => {
        if (res.data && res.data.resultType == 0) {
          const userSetting =
            JSON.parse(JSON.stringify(state.deviceDefaultSetting)) || {}
          userSetting.package = payload.key
          if (payload.bitrate) {
            userSetting.bitrate = payload.bitrate
          }
          if (payload.fps) {
            userSetting.fps = payload.fps
            userSetting.gop = payload.gop
            userSetting.isAudioEnabled = payload.isAudioEnabled
          }
          commit('setDeviceDefaultSetting', userSetting)
          commit('setUpdateCameraSettingsSuccess', true)
          return Promise.resolve(res.data.item)
        } else {
          commit('setUpdateCameraSettingsError', true)
          return Promise.resolve(null)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        return Promise.resolve(null)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setDeviceFlipMirror({ commit, state }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceFlipMirror(payload)
      .then((res) => {
        if (res.data) {
          const userSetting =
            JSON.parse(JSON.stringify(state.deviceDefaultSetting)) || {}
          if (payload.key === 'flip') {
            userSetting.isFlipImage = payload.value === '1'
          } else if (payload.key === 'mirror') {
            userSetting.isMirrorImage = payload.value === '1'
          }
          commit('setDeviceDefaultSetting', userSetting)
          commit('setUpdateCameraSettingsSuccess', true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          commit('setRestoreDeviceSettingError', true)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setConfigDewarping({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetConfigDewarping(payload)
      .then((res) => {
        if (res) {
          commit('updateDewarpDeviceInfo', payload)
          commit('setUpdateCameraSettingsSuccess', true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          commit('setRestoreDeviceSettingError', true)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async deviceWebRTCRestart(_, payload) {
    return repository.Device.DeviceWebRTCRestart(payload).catch(() => {
      //error message here
    })
  },
  addDevice(_, payload) {
    return repository.Device.AddDevice(payload) //TODO : Catch the error here.
  },
  setCurrentVideoWidthHeight({ commit }, payload) {
    commit('setCurrentVideoWidth', payload.videoWidth)
    commit('setCurrentVideoHeight', payload.videoHeight)
  },
  updateDeviceNVRCameraName({ commit }, payload) {
    commit('updateNVRCameraName', payload)
  },
  async deviceStartPlayback({ commit }, payload) {
    return repository.Device.DeviceStartPlayback(payload.parm, payload.config)
      .catch(() => {
        commit('setCameraApiError', true)
        commit(
          'setCameraApiErrorMessage',
          i18n.t('dealer.cameraVideoPlayError')
        )
      })
      .finally(() => {
        commit('setCameraApiError', false)
      })
  },
  async deviceStartSmartSearchPlayback({ commit }, payload) {
    commit('setDeviceStartPlaybackLoading', true)
    return repository.Device.DeviceStartSmartSearchPlayback(
      payload.parm,
      payload.config
    )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch(() => {
        commit('setCameraApiError', true)
        commit(
          'setCameraApiErrorMessage',
          i18n.t('dealer.cameraVideoPlayError')
        )
      })
      .finally(() => {
        commit('setCameraApiError', false)
        commit('setDeviceStartPlaybackLoading', false)
      })
  },
  async deviceStopPlayback({ commit }, payload) {
    return repository.Device.DeviceStopPlayback(payload.parm, payload.config)
      .catch(() => {
        commit('setCameraApiError', true)
        commit(
          'setCameraApiErrorMessage',
          i18n.t('dealer.cameraVideoPlayError')
        )
      })
      .finally(() => {
        commit('setCameraApiError', false)
      })
  },
  async devicePausePlayback({ commit }, payload) {
    return repository.Device.DevicePausePlayback(payload.parm, payload.config)
      .catch(() => {
        commit('setCameraApiError', true)
        commit(
          'setCameraApiErrorMessage',
          i18n.t('dealer.cameraVideoPlayError')
        )
      })
      .finally(() => {
        commit('setCameraApiError', false)
      })
  },
  async setDeviceTimezone({ commit }, payload) {
    return repository.Device.SetDeviceTimezone(payload)
      .then((res) => {
        if (!res.data) {
          commit('setConnectCloudError', true)
        }
      })
      .catch(() => {
        commit('setConnectCloudError', true)
      })
  },
  async formatSDCard(_, payload) {
    const parm = payload.parm ? payload.parm : payload
    const config = payload.config ? payload.config : null
    return repository.Device.FormatSDCard(parm, config)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch(() => {
        return Promise.resolve(null)
      })
  },
  factoryReset(_, payload) {
    return repository.Device.FactoryReset(payload)
  },
  async fetchVideoToken({ commit, state }, payload) {
    const mac = payload.cameraSerialNumber
    if (
      state.sessionTokens &&
      state.sessionTokens[mac] &&
      state.sessionTokens[mac].Token &&
      state.sessionTokens[mac].Token.accessKey &&
      state.sessionTokens[mac].Token.secretKey &&
      state.sessionTokens[mac].Token.sessionToken &&
      state.sessionTokens[mac].Expire &&
      Date.now() < state.sessionTokens[mac].Expire
    ) {
      return Promise.resolve(state.sessionTokens[mac])
    }

    return repository.User.GetVideoToken(payload)
      .then((res) => {
        if (
          !res.data ||
          !res.data.accessKey ||
          !res.data.secretKey ||
          !res.data.sessionToken
        ) {
          return {}
        }
        const payload = {
          mac: mac,
          token: {
            Token: res.data,
            // set expiration to 55 minutes
            Expire: Date.now() + 55 * 60 * 1000,
          },
        }
        commit('setVideoToken', payload)
        return payload.token
      })
      .catch(() => {
        return {}
      })
      .finally(() => {})
  },
  async updatePlaybackSource({ commit }, payload) {
    return repository.Device.UpdatePlaybackSource(payload)
      .then((res) => {
        if (res.data.item) {
          commit('setDevice', res.data.item)
        }
      })
      .catch(() => {})
  },
  resetUpdateCameraSettingsError({ commit }) {
    commit('setUpdateCameraSettingsError', false)
  },
  resetUpdateCameraSettingsSuccess({ commit }) {
    commit('setUpdateCameraSettingsSuccess', false)
  },
  resetActivateCameraError({ commit }) {
    commit('setActivateCameraError', false)
  },
  resetConnectCloudError({ commit }) {
    commit('setConnectCloudError', false)
  },
  setAdjustingSettingsStatus({ commit }, payload) {
    commit('setIsAdjustingSettings', payload)
  },
  async getDeviceAdvancedSetting({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.GetDeviceAdvancedSetting(
      payload.parm,
      // @ts-expect-error
      // TODO: fix
      payload.config
    )
      .then((res) => {
        if (res.data) {
          commit('setDeviceAdvancedSetting', getQuery(res.data))
        } else {
          commit('setDeviceAdvancedSetting', null)
          commit('setUpdateCameraSettingsError', true)
        }
      })
      .catch(() => {
        commit('setDeviceAdvancedSetting', null)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setDeviceAdvancedSetting({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceAdvancedSetting(payload)
      .then(() => {
        commit('setUpdateCameraSettingsSuccess', true)
        if (payload.parm) {
          commit('updateDeviceAdvancedSetting', payload.parm)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        commit('setRestoreDeviceSettingError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  setVCASetting({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetVCASetting(payload)
      .then((res) => {
        commit('updateDeviceAdvancedAnalytics', res.data)
        commit('setUpdateCameraSettingsSuccess', true)
        return Promise.resolve(true)
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  deleteVCASetting({ commit }, payload) {
    commit('setDeleteVCARuleIsLoading', true)
    return repository.Device.DeleteVCASetting(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateCameraSettingsSuccess', true)
          return Promise.resolve(true)
        } else {
          commit('setUpdateCameraSettingsError', true)
          return Promise.resolve(false)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setDeleteVCARuleIsLoading', false)
      })
  },
  async setDeviceNotificationsGracePeriod({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetDeviceNotificationsGracePeriod(payload)
      .then(() => {
        commit('setUpdateCameraSettingsSuccess', true)
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async getDeviceDefaultSetting({ commit }, payload) {
    commit('setDeviceDefaultSetting', null)
    commit('setIsAdjustingSettings', true)
    return repository.Device.GetDeviceDefaultSetting(
      payload.parm,
      payload.config
    )
      .then((res) => {
        commit('setDeviceDefaultSetting', res.data)
      })
      .catch(() => {
        commit('setDeviceDefaultSetting', null)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async getVCASetting({ commit }, payload) {
    commit('setDeviceAdvancedAnalytics', null)
    commit('setIsAdjustingSettings', true)
    commit('setLoadVcaRulesError', false)
    return repository.Device.GetVCASetting(payload.parm, payload.config)
      .then((res) => {
        commit('setDeviceAdvancedAnalytics', res.data)
      })
      .catch(() => {
        commit('setDeviceAdvancedAnalytics', null)
        commit('setLoadVcaRulesError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async getVCAEvents({ commit }, payload) {
    return repository.Device.GetVCAEvents(payload)
      .then((res) => {
        const sortedVCAEvents = res.data.items.sort(
          (a: VCAEvent, b: VCAEvent) => {
            //@ts-ignore
            return new Date(a.eventCreatedOn) - new Date(b.eventCreatedOn)
          }
        )
        commit('setVCAEvents', sortedVCAEvents)
      })
      .catch(() => {
        commit('setVCAEvents', null)
      })
      .finally(() => {})
  },
  async getSingleVCAEvent({ commit }, payload) {
    return repository.Device.GetSingleVCAEvent(payload).then((res) => {
      commit('setSingleVCAEvent', res.data)
    })
  },
  async getVCABurntInAnnotation({ commit }, payload) {
    commit('setDeviceBurntInAnnotation', {})
    commit('setIsAdjustingSettings', true)
    return repository.Device.GetVCABurntInAnnotation(payload)
      .then((res) => {
        commit('setDeviceBurntInAnnotation', res.data)
        return Promise.resolve(true)
      })
      .catch(() => {
        return Promise.resolve(false)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setVCABurntInAnnotation({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    return repository.Device.SetVCABurntInAnnotation(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateCameraSettingsSuccess', true)
          commit('updateDeviceBurntInAnnotation', payload)
        } else {
          commit('setUpdateCameraSettingsError', true)
        }
      })
      .catch(() => {
        commit('setUpdateCameraSettingsError', true)
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async getVCARuleTypes({ commit }) {
    commit('setIsAdjustingSettings', true)
    commit('setAdvancedAnalyticsRuleIsLoading', true)
    return repository.Device.GetVCARuleTypes()
      .then((res) => {
        commit('setAdvancedAnalyticsRuleTypes', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setIsAdjustingSettings', false)
        commit('setAdvancedAnalyticsRuleIsLoading', false)
      })
  },
  async getVcaRuleCustomTypes({ commit }) {
    commit('setIsAdjustingSettings', true)
    commit('setAdvancedAnalyticsRuleIsLoading', true)
    return repository.Device.GetVcaRuleCustomTypes()
      .then((res) => {
        commit('setAdvancedAnalyticsCustomTypes', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setIsAdjustingSettings', false)
        commit('setAdvancedAnalyticsRuleIsLoading', false)
      })
  },
  async getVCARuleObjects({ commit }) {
    commit('setIsAdjustingSettings', true)
    commit('setAdvancedAnalyticsRuleIsLoading', true)
    return repository.Device.GetVCARuleObjects()
      .then((res) => {
        commit('setAdvancedAnalyticsRuleObjects', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setIsAdjustingSettings', false)
        commit('setAdvancedAnalyticsRuleIsLoading', false)
      })
  },
  async getVcaRuleTemplates({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    commit('setAdvancedAnalyticsRuleIsLoading', true)
    return repository.Device.GetVcaRuleTemplates(payload)
      .then((res) => {
        commit('setAdvancedAnalyticsRuleSetOptions', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setIsAdjustingSettings', false)
        commit('setAdvancedAnalyticsRuleIsLoading', false)
      })
  },
  async applyVcaRuleTemplate({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    commit('setUpdateVcaRuleSetError', false)
    return repository.Device.ApplyVcaRuleTemplate(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit('setUpdateVcaRuleSetError', true)
        commit(
          'setUpdateVcaRuleSetErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async setVcaRuleTemplate({ commit }, payload) {
    commit('setIsAdjustingSettings', true)
    commit('setUpdateVcaRuleSetError', false)
    return repository.Device.SetVcaRuleTemplate(payload)
      .then((res) => {
        commit('setCurrentVcaRuleTemplateInfo', res.data)
      })
      .catch((err) => {
        commit('setUpdateVcaRuleSetError', true)
        commit(
          'setUpdateVcaRuleSetErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setIsAdjustingSettings', false)
      })
  },
  async deleteVcaRuleTemplate({ commit }, payload) {
    commit('setAdvancedAnalyticsRuleIsLoading', true)
    commit('setUpdateVcaRuleSetError', false)
    return repository.Device.DeleteVcaRuleTemplate(payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateVcaRuleSetError', false)
        } else {
          commit('setUpdateVcaRuleSetError', true)
        }
      })
      .catch((err) => {
        commit('setUpdateVcaRuleSetError', true)
      })
      .finally(() => {
        commit('setAdvancedAnalyticsRuleIsLoading', false)
      })
  },
  resetUpdateVcaRuleSet({ commit }) {
    commit('setUpdateVcaRuleSetError', false)
    commit('setUpdateVcaRuleSetErrorMessage', '')
  },
  async getDeviceHardwareInfo({ commit }, payload) {
    return repository.Device.GetDeviceHardwareInfo(payload)
      .then((res) => {
        if (res.data) {
          commit('setDeviceAdvancedSetting', getQuery(res.data))
        } else {
          commit('setDeviceAdvancedSetting', null)
        }
      })
      .catch(() => {})
      .finally(() => {})
  },
  async updateDeviceTimezoneById({ commit }, payload) {
    commit('setUpdateDeviceTimezoneByIdError', false)
    commit('setUpdateDeviceTimezoneByIdLoading', true)
    return repository.Device.UpdateDeviceTimezoneById(payload)
      .then((res) => {
        if (!res || !res.data) {
          commit('setDeviceAdvancedSetting', null)
        }
      })
      .catch(() => {
        commit('setUpdateDeviceTimezoneByIdError', true)
      })
      .finally(() => {
        commit('setUpdateDeviceTimezoneByIdLoading', false)
      })
  },
  callDeviceMotionSetting({ commit }, payload) {
    commit('setDeviceMotionSetting', payload)
  },
  async getWebRTCPeerCount(_, payload) {
    return repository.Device.GetWebRTCPeerCount(payload).catch(() => {
      // catch exception
    })
  },
  getNVRPluginDownloadURL() {
    return repository.Device.GetNVRPluginDownloadURL()
  },
  async getDeviceNoFootage({ commit }, payload) {
    return repository.Device.GetDeviceNoFootage(payload).then((res) => {
      commit('setDeviceNoFootage', res.data)
    })
  },
  updateDeviceNoFootage({ commit }, payload) {
    commit('setDeviceNoFootage', payload)
  },
}
const Device: Module<DeviceState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Device

interface DeviceState {
  devicesStatus: DevicesStatus[]
  devicesStatusIsLoading: boolean
  device: Partial<DeviceObject>
  deviceLoading: boolean
  currentClip: CurrentClip[]
  currentClipLoading: boolean
  sessionTokens: SessionTokens[]
  updateCameraSettingsError: boolean
  updateCameraSettingsSuccess: boolean
  deviceDefaultSetting: null
  deviceAdvancedSetting: DeviceAdvancedSetting | null
  deviceAdvancedAnalytics: DeviceAdvancedAnalytics[] | null
  deviceBurntInAnnotation: Partial<DeviceBurntInAnnotation>
  vcaEvents: null
  deviceMotionSetting: null
  restoreDeviceSettingError: boolean
  isAdjustingSettings: boolean
  activateCameraError: boolean
  connectCloudError: boolean
  cameraApiError: boolean
  cameraApiErrorMessage: string
  currentVideoWidth: number
  currentVideoHeight: number
  deviceStartPlaybackLoading: boolean
  deviceErrorMessage: null
  advancedAnalyticsRuleTypes: null
  advancedAnalyticsCustomTypes: null
  advancedAnalyticsRuleObjects: null
  advancedAnalyticsRuleSetOptions: null
  advancedAnalyticsRuleIsLoading: boolean
  deleteVCARuleIsLoading: boolean
  loadVcaRulesError: boolean
  updateVcaRuleSetError: boolean
  updateVcaRuleSetErrorMessage: string
  currentVcaRuleTemplateInfo: null
  deviceNoFootage: Partial<DeviceObject>
  singleVCAEvent: Partial<VCAEvent>
  updateDeviceTimezoneByIdError: false
  updateDeviceTimezoneByIdLoading: false
}

interface DevicesStatus {
  deviceStatus: string
}

interface CurrentClip {
  hlsStreamingSessionURL: string
}

interface SessionTokens {
  Token?: Token
  Expire?: number
}

interface Token {
  accessKey: string
  secretKey: string
  sessionToken: string
}
