import httpClient from './httpClient'
export default {
  User: {
    Authenticate(payload) {
      let newPayload = {
        EmailAddress: payload.emailAddress,
        Password: btoa(payload.password),
      }
      return httpClient.post('/User/Authenticate', newPayload)
    },
    VerifyTwoFactor(payload) {
      payload.Password = btoa(payload.Password)
      return httpClient.post('/User/VerifyTwoFactorAuthenticationCode', payload)
    },
    GetTwoFactorCode(payload) {
      payload.Password = btoa(payload.Password)
      return httpClient.post('/User/GetTwoFactorAuthenticationCode', payload)
    },
    GetCurrentUser() {
      return httpClient.post('/User/GetCurrent')
    },
    Logout() {
      return httpClient.post('/User/Logout')
    },
    GetVideoToken(payload) {
      return httpClient.post('/User/GetVideoToken', payload)
    },
    GetUsersTest() {
      return httpClient.post('/User/GetUsersTest', {
        LocationId: 1,
      })
    },
    Update(payload) {
      return httpClient.post('/User/Update', payload)
    },
    UpdateMyAccount(payload) {
      return httpClient.post('/User/UpdateMyAccount', payload)
    },
    UpdatePassword(payload) {
      return httpClient.post('/User/UpdatePassword', payload)
    },
    UpdateTerms(payload) {
      return httpClient.post('/User/UpdateTerms', payload)
    },
    Add(payload) {
      return httpClient.post('/User/Add', payload)
    },
    DeleteUser(payload) {
      return httpClient.post('User/DeleteUser', payload)
    },
    EnableDisableUser(payload) {
      return httpClient.post('User/EnableDisableUser', payload)
    },
    ResetUserPassword(payload) {
      return httpClient.post('User/ResetUserPassword', payload)
    },
    ResetPassword(payload) {
      return httpClient.post('User/ResetPassword', payload)
    },
    ForgotPassword(payload) {
      return httpClient.post('User/ForgotPassword', payload)
    },
    ValidateVerificationToken(payload) {
      return httpClient.post('User/ValidateVerificationToken', payload)
    },
    SendWelcomeEmail(payload) {
      return httpClient.post('User/SendWelcomeEmail', payload)
    },
  },
}
