
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import {
  USER_LOGIN,
  FORGOT_PASSWORD_LINK_CLICK,
} from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'UserForm',
  props: {
    copyrightDate: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loginForm: '',
      emailAddress: '',
      password: '',
      loginBtnActive: false,
      autofillFix: false,
      showRegionText: false,
    }
  },
  computed: {
    ...mapState('Security', [
      'loginErrorMessage',
      'loginLoading',
      'isLoggedIn',
      'user',
      'requiresTwoFactor',
    ]),
    emailLabel() {
      return this.$t('loginView.email')
    },
    passwordLabel() {
      return this.$t('loginView.password')
    },
    loginError() {
      return this.loginErrorMessage != ''
    },
    currentRegion() {
      let originDomain = window.location.origin
      if (process.env.VUE_APP_DEV_INSTANCE === 'true') {
        if (originDomain.includes('qa')) {
          return 'eu'
        } else {
          return 'na'
        }
      } else {
        if (originDomain.includes('eu')) {
          return 'eu'
        } else {
          return 'na'
        }
      }
    },
    naRegion() {
      return this.currentRegion === 'na'
    },
    euRegion() {
      return this.currentRegion === 'eu'
    },
    naLink() {
      if (process.env.VUE_APP_DEV_INSTANCE === 'true') {
        return 'https://dev.vigilcloud.3xlogic.com'
      } else {
        return 'https://vigilcloud.3xlogic.com'
      }
    },
    euLink() {
      if (process.env.VUE_APP_DEV_INSTANCE === 'true') {
        return 'https://vigilcloudqa.3xlogic.com'
      } else {
        return 'https://vigilcloud.3xlogic.eu'
      }
    },
  },
  methods: {
    ...mapActions('Security', [
      'authenticate',
      'logout',
      'clearLoginErrorMessage',
    ]),
    next() {
      let payload = { email: this.emailAddress, password: this.password }
      this.$emit('nextStep', payload)
    },
    login() {
      if (this.emailAddress == '' || this.password == '') return
      const { emailAddress, password } = this

      this.authenticate({ emailAddress, password }).then(() => {
        if (this.isLoggedIn) {
          //VAR user check
          let redirectURL = this.$route.query.redirect
          if (redirectURL != null) {
            if (this.$route.query.view != null) {
              this.$router.push({
                path: redirectURL.slice(1),
                query: { view: this.$route.query.view },
              })
              return
            } else {
              this.$router.push(redirectURL)
              return
            }
          }
          if (this.user.isDealerUser) {
            this.$router.push('/dashboard')
          } else if (this.user.isInternalUser) {
            this.$router.push('/dealers')
          } else {
            this.$router.push('/dashboard')
          }
          this.$mixpanelUserTrack(USER_LOGIN, { 'Login Success': true })
        } else if (this.requiresTwoFactor) {
          this.next()
        } else {
          this.clearLoginForm()
          // failed login track
          this.$mixpanelTrack(USER_LOGIN, { 'Login Success': false })
        }
      })
    },
    setLoginBtnActive() {
      this.loginBtnActive = true
    },
    clearLoginForm() {
      this.password = ''
    },
    mixpanelAction() {
      this.$mixpanelTrack(FORGOT_PASSWORD_LINK_CLICK)
    },
    checkAutofill() {
      let detectInterval = setInterval(() => {
        if (
          document.querySelectorAll('input[type="password"]:-webkit-autofill')
            .length > 0
        ) {
          this.autofillFix = true
          clearInterval(detectInterval)
        }
      }, 100)

      setTimeout(() => {
        if (detectInterval) {
          clearInterval(detectInterval)
          detectInterval = null
        }
      }, 2000)
    },
  },
  mounted() {
    this.clearLoginErrorMessage()
    this.checkAutofill()
  },
})
