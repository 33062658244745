
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { MY_ACCOUNT_LOGOUT_CLICKED } from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'AccountMenu',
  data() {
    return {}
  },
  computed: {
    ...mapState('Security', ['user']),
  },
  methods: {
    ...mapActions('Security', ['test', 'logout']),
    async logoutClick() {
      this.$mixpanelUserTrack(MY_ACCOUNT_LOGOUT_CLICKED)
      await this.logout()
      this.$router.push('/logout')
    },
    menuClick() {
      this.test()
    },
  },
})
