
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DealerTechCC',
  props: {
    localLogoutLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    decline() {
      this.$emit('decline')
    },
  },
})
