import Login from '@/views/login/Login.vue'
import Logout from '@/views/logout/Logout.vue'
import ResetPassword from '@/views/resetPassword/ResetPassword.vue'
import ForgotPassword from '@/views/forgotPassword/ForgotPassword.vue'
import SignUpContainer from '@/views/signUp/SignUpContainer.vue'
import Outage from '@/views/outage/Outage.vue'
import TermsOfUse from '@/views/terms/TermsOfUse.vue'
import PrivacyPolicy from '@/views/terms/PrivacyPolicy.vue'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
    beforeEnter: (to, from, next) => {
      if (to.query.redirect) {
        if (
          to.query.redirect.includes('logout') ||
          to.query.redirect.includes('login') ||
          to.query.redirect == '/'
        ) {
          next({
            name: 'Login',
          })
        } else {
          next()
        }
      } else {
        next()
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: 'Logout',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
    beforeEnter: (to, from, next) => {
      if (to.query.redirect) {
        if (
          to.query.redirect.includes('logout') ||
          to.query.redirect.includes('login') ||
          to.query.redirect == '/'
        ) {
          next({
            name: 'Logout',
          })
        } else {
          next()
        }
      } else {
        next()
      }
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpContainer,
    meta: {
      title: 'Sign Up',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
  {
    path: '/outage',
    name: 'Outage',
    component: Outage,
    meta: {
      title: 'Outage',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsOfUse,
    meta: {
      title: 'Terms of Use',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
  {
    path: '/vigil-cloud-privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
      allowAnonymous: true,
      showHeader: false,
      showFooter: false,
    },
  },
]
