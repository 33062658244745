import store from '@/store/'
import * as Permissions from '@/constants/permissions'
export default [
  {
    path: '/customers',
    name: 'Customers',
    component: () =>
      import(
        /*webpackChunkName: "CustomerManagementContainer" */ '@/views/dealerCustomerManagement/CustomerManagementContainer.vue'
      ),
    meta: {
      title: 'Customers',
      allowAnonymous: false,
      dealerOnly: true,
      showHeader: true,
      showFooter: true,
    },
    beforeEnter: (to, from, next) => {
      let payload = {
        dealerViewingUser: false,
        dealerViewingLocation: '',
        dealerCustomerId: 0,
      }
      store.dispatch('Security/callSetDealerViewingUser', payload)
      if (store.state.Security.user.isDealerUser) {
        next()
      } else if (store.state.Security.user.isInternalUser) {
        next('/dealers')
      } else {
        next('/dashboard')
      }
    },
  },
  {
    path: '/admin/users/:id',
    name: 'DealerUserDetail',
    component: () =>
      import(
        /*webpackChunkName: "DealerUserDetail" */ '@/views/vigilUserDetail/VigilUserDetail.vue'
      ),
    meta: {
      title: 'Dealer User Detail',
      allowAnonymous: false,
      dealerOnly: true,
      showHeader: true,
      showFooter: true,
      customerUserDetail: false,
      dealerUserDetail: true,
      superAdminUserDetail: false,
      superAdminCustomerUserDetail: false,
      superAdminDealerUserDetail: false,
    },
    beforeEnter: (to, from, next) => {
      if (
        store.getters['Security/hasPermission'](
          Permissions.CREATE_EDIT_DEALER_USERS
        )
      ) {
        next()
      } else {
        next('/dashboard')
      }
    },
  },
  {
    path: '/customers/:id',
    name: 'CustomerDetail',
    component: () =>
      import(
        /*webpackChunkName: "CustomerDetail" */ '@/views/dealerCustomerDetail/CustomerDetail.vue'
      ),
    props: true,
    meta: {
      title: 'Customer Details',
      allowAnonymous: false,
      dealerOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/customers/location/:locationId-:id',
    name: 'CustomerLocation',
    component: () =>
      import(
        /*webpackChunkName: "CustomerLocation" */ '@/views/dealerCustomerLocation/CustomerLocation.vue'
      ),
    props: true,
    meta: {
      title: 'Customer Location',
      allowAnonymous: false,
      dealerOnly: true,
      showHeader: true,
      showFooter: true,
    },
  },
]
