import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import modules from './modules/index'

import { RootState } from './types'

Vue.use(Vuex)

const newStore: StoreOptions<RootState> = {
  //@ts-ignore
  modules,
  strict: process.env.NODE_ENV !== 'production',
  actions: {
    resetStore({ commit }) {
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/reset`)
      })
    },
  },
}

const store = new Vuex.Store<RootState>(newStore)

export default store
