export const CASE_CREATE = 1
export const CASE_COMMENT_ADDED = 2
export const ARTIFACT_CREATED = 3
export const CASE_UPDATED = 4
export const CREATED_TIME = 5
export const CAMERA_MOTION_DETECTED = 6
export const NO_DATA = 7
export const FIRMWARE_UPDATE_BEGIN = 8
export const FIRMWARE_UPDATE_SUCCESSFUL = 9
export const FIMWARE_UPDATE_FAILED = 10
export const PLUGIN_UPDATE_BEGIN = 11
export const PLUGIN_UPDATE_SUCCESSFUL = 12
export const PLUGIN_UPDATE_FAILED = 13
export const DEVICE_STATUS_UPDATED = 14
export const DIGITAL_INPUT = 16
export const VCA_EVENT = 18
