import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): SmartSearchState => {
  return {
    smartSearchGrid: '',
    motionSensitivity: 1,
    smartSearchLoading: false,
    smartSearchMask: '',
    isSmartSearchTesting: false,
    nowFrame: 0,
    videoSearchMetadata: {},
    videoSearchMetadataLoading: false,
    hasBlock: false,
    isApplied: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<SmartSearchState, RootState> = {
  smartSearchArea: (state) => {
    return state.smartSearchGrid
  },
  motionSensitivity: (state) => {
    return state.motionSensitivity
  },
  smartSearchMask: (state) => {
    return state.smartSearchMask
  },
  videoSearchMetadata: (state) => {
    return state.videoSearchMetadata
  },
  videoSearchMetadataLoading: (state) => {
    return state.videoSearchMetadataLoading
  },
  isSmartSearchTesting: (state) => {
    return state.isSmartSearchTesting
  },
  nowFrame: (state) => {
    return state.nowFrame
  },
  hasBlock: (state) => {
    return state.hasBlock
  },
  isFilterApplied: (state) => {
    return {
      // this might be a bug
      // @ts-ignore
      // TODO: fix
      smartSearchArea: state.smartSearchArea,
      motionSensitivity: state.motionSensitivity,
      isApplied: state.isApplied,
    }
  },
}

const mutations: MutationTree<SmartSearchState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setSmartSearchGrid(state, value) {
    state.smartSearchGrid = value
  },
  setSmartSearchLoading(state, value) {
    state.smartSearchLoading = value
  },
  setMotionSensitivity(state, value) {
    state.motionSensitivity = value
  },
  setSmartSearchMask(state, value) {
    state.smartSearchMask = value
  },
  setVideoSearchMetadata(state, value) {
    state.videoSearchMetadata = value
  },
  setVideoSearchMetadataLoading(state, value) {
    state.videoSearchMetadataLoading = value
  },
  setIsSmartSearchTesting(state, value) {
    state.isSmartSearchTesting = value
  },
  setNowFrame(state, value) {
    state.nowFrame = value
  },
  setHasBlock(state, value) {
    state.hasBlock = value
  },
  setIsApplied(state) {
    state.isApplied = !state.isApplied
  },
}

const actions: ActionTree<SmartSearchState, RootState> = {
  resetSmartSearchGrid({ commit }) {
    commit('reset')
  },
  callSmartSearchGrid({ commit }, payload) {
    commit('setSmartSearchMask', payload.smartSearchMask)
    commit('setSmartSearchGrid', payload.grid)
    commit('setMotionSensitivity', payload.motionSensitivity)
    commit('setIsApplied')
  },
  removeSmartSearchTesting({ commit }, payload) {
    commit('setVideoSearchMetadata', {})
    commit('setIsSmartSearchTesting', payload.flag)
  },
  updateHasBlock({ commit }, payload) {
    commit('setHasBlock', payload.hasBlock)
  },
  updateNowFrame({ commit }, payload) {
    commit('setNowFrame', payload.nowFrame)
  },
  async getVideoSearchMetadata({ commit }, payload) {
    commit('setVideoSearchMetadataLoading', true)
    if (!payload.parm.hideLoading) {
      commit('setIsSmartSearchTesting', false)
      commit('setVideoSearchMetadata', {})
    }
    commit('setNowFrame', 0)
    return repository.Timeline.VideoSearchMetadata(payload.parm, payload.config)
      .then((res) => {
        commit('setVideoSearchMetadata', res.data)
        commit('setIsSmartSearchTesting', true)
      })
      .catch(() => {})
      .finally(() => {
        commit('setVideoSearchMetadataLoading', false)
      })
  },
}
const SmartSearch: Module<SmartSearchState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default SmartSearch

interface SmartSearchState {
  smartSearchGrid: string
  motionSensitivity: number
  smartSearchLoading: boolean
  smartSearchMask: string
  isSmartSearchTesting: boolean
  nowFrame: number
  videoSearchMetadata: Partial<Metadata>
  videoSearchMetadataLoading: boolean
  hasBlock: boolean
  isApplied: boolean
}

interface Metadata {
  id: number
}
