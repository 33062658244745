
import { defineComponent } from 'vue'
import TwoColumnLayout from '@/components/layout/TwoColumnLayout.vue'
import { stopClient } from '@/utils/webSockets'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'Logout',
  components: {
    TwoColumnLayout,
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('Security', ['logout']),
    login() {
      let redirect = this.$route.query.redirect
      if (redirect !== null || redirect !== '') {
        this.$router.push({
          name: 'Login',
          query: {
            redirect: this.$route.query.redirect,
          },
        })
      } else {
        this.$router.push('/login')
      }
    },
  },
  mounted() {
    this.$store.dispatch('resetStore')
    this.logout()
    stopClient()
  },
})
