
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'

export default defineComponent({
  name: 'InternalUserDesktopNav',
  components: {
    AccountMenu,
  },
  props: {
    showMyUsers: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    routeToDealers() {
      this.$router.push('/dealers')
    },
  },
  computed: {
    tabsModel: {
      /* eslint-disable vue/return-in-computed-property */
      // TODO: fix this computed prop
      set() {},
      get() {
        if (this.navTabsOptional) return ''
      },
    },
    /* eslint-enable vue/return-in-computed-property */
  },
})
