
import { defineComponent } from 'vue'
import TermsComponent from '@/components/global/termsDialog/TermsComponent.vue'
export default defineComponent({
  name: 'TermsOfUse',
  components: {
    TermsComponent,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
