import httpClient from './httpClient'

export default {
  OnboardingMessages: {
    GetOnboardingMessageById(payload) {
      return httpClient.post(
        '/OnboardingMessages/GetOnboardingMessageById',
        payload
      )
    },
    SetOnboardingMessageViewed(payload) {
      return httpClient.post(
        '/OnboardingMessages/SetOnboardingMessageViewed',
        payload
      )
    },
  },
}
