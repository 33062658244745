
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default defineComponent({
  name: 'DealerCCBanner',
  data() {
    return {
      showBanner: false,
    }
  },
  computed: {
    ...mapGetters('Security', [
      'dealerCCExpiring',
      'isDealerAdmin',
      'dealerIsBillable',
      'dealerIsPastDue',
      'dealerIsInvoiceNonExempt',
    ]),
    ...mapGetters('Environment', ['hasChargify']),
  },
  methods: {
    ...mapActions('Payment', ['goToPaymentTab']),
    goToPayment() {
      if (this.$route.name == 'Administration') {
        this.goToPaymentTab(true)
      } else {
        this.$router.push({ name: 'Administration', params: { tab: 2 } })
      }
    },
    showCCBanner() {
      if (
        this.hasChargify &&
        (this.dealerIsBillable || this.dealerIsInvoiceNonExempt) &&
        ((this.dealerCCExpiring && this.isDealerAdmin) || this.dealerIsPastDue)
      ) {
        this.showBanner = true
      } else {
        this.showBanner = false
      }
    },
  },
  mounted() {
    this.showCCBanner()
  },
  watch: {
    dealerCCExpiring() {
      this.showCCBanner()
    },
    dealerIsPastDue() {
      this.showCCBanner()
    },
  },
})
