import repository from '@/repository'

const getDefaultState = () => {
  return {
    recentActivity: [],
    recentActivityIsLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  recentActivity: (state) => {
    return state.recentActivity
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setRecentActivity(state, value) {
    state.recentActivity = value
  },
  setRecentActivityIsLoading(state, value) {
    state.recentActivityIsLoading = value
  },
}

const actions = {
  getRecentActivity({ commit }, payload) {
    commit('setRecentActivityIsLoading', true)
    return repository.Case.GetRecentActivity(payload)
      .then((res) => {
        commit('setRecentActivity', res.data)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setRecentActivityIsLoading', false)
      })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
