import { User } from '@/models'
import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): CustomerUsersState => {
  return {
    customerUserList: [],
    customerUserListIsLoading: false,
    dealerUserList: [],
    dealerUserListIsLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<CustomerUsersState, RootState> = {
  customers: (state) => {
    return state.customerUserList
  },
}

const mutations: MutationTree<CustomerUsersState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCustomerUserList(state, value) {
    state.customerUserList = value
  },
  setCustomerUserListIsLoading(state, value) {
    state.customerUserListIsLoading = value
  },
  setDealerUserList(state, value) {
    state.dealerUserList = value
  },
  setDealerUserListIsLoading(state, value) {
    state.dealerUserListIsLoading = value
  },
}

const actions: ActionTree<CustomerUsersState, RootState> = {
  async getCustomerUserList({ commit }, payload) {
    commit('setCustomerUserListIsLoading', true)
    return repository.Dealer.GetUsersByCustomer(payload)
      .then((res) => {
        commit('setCustomerUserList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomerUserListIsLoading', false)
      })
  },
  async getCustomerUserListInternalAdmin({ commit }, payload) {
    commit('setCustomerUserListIsLoading', true)
    return repository.InternalAdmin.GetUsersByCustomer(payload)
      .then((res) => {
        commit('setCustomerUserList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomerUserListIsLoading', false)
      })
  },
  async getDealerUserList({ commit }, payload) {
    commit('setDealerUserListIsLoading', true)
    return repository.Dealer.GetUsers(payload)
      .then((res) => {
        commit('setDealerUserList', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setDealerUserListIsLoading', false)
      })
  },
  async getDealerUserListInternalAdmin({ commit }, payload) {
    commit('setDealerUserListIsLoading', true)
    return repository.Dealer.GetUsersByDealer(payload)
      .then((res) => {
        commit('setDealerUserList', res.data.items)
      })
      .catch(() => {})
      .finally(() => {
        commit('setDealerUserListIsLoading', false)
      })
  },
}
const CustomerUsers: Module<CustomerUsersState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default CustomerUsers

interface CustomerUsersState {
  customerUserList: User[]
  customerUserListIsLoading: boolean
  dealerUserList: User[]
  dealerUserListIsLoading: boolean
}
