import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/types'

const getDefaultState = (): ExternalCaseState => {
  return {
    verificationCodeIsLoading: false,
    verificationCode: '',
    singleFactorAuthIsLoading: false,
    singleFactorAuthError: false,
    externalUserAuthError: false,
    externalUserAuthErrorMessage: '',
    externalUserIsLoggedIn: false,
    externalCases: [],
    externalCasesError: false,
    externalCasesIsLoading: false,
    encryptedVerificationCode: '',
    externalUserIsLocked: false,
    noExternalCasesShared: false,
    shareQueryString: '',
  }
}

const state = getDefaultState()

const mutations: MutationTree<ExternalCaseState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setVerificationCodeIsLoading(state, value) {
    state.verificationCodeIsLoading = value
  },
  setVerificationCode(state, value) {
    state.verificationCode = value
  },
  setExternalUserIsLoggedIn(state, value) {
    state.externalUserIsLoggedIn = value
  },
  setExternalUserIsLocked(state, value) {
    state.externalUserIsLocked = value
  },
  setSingleFactorAuthIsLoading(state, value) {
    state.singleFactorAuthIsLoading = value
  },
  setSingleFactorAuthError(state, value) {
    state.singleFactorAuthError = value
  },
  setExternalUserAuthError(state, value) {
    state.externalUserAuthError = value
  },
  setNoExternalCasesShared(state, value) {
    state.noExternalCasesShared = value
  },
  setShareQueryString(state, value) {
    state.shareQueryString = value
  },
  setExternalUserAuthErrorMessage(state, value) {
    state.externalUserAuthErrorMessage = value
  },
  setExternalCases(state, value) {
    state.externalCases = value
  },
  setExternalCasesIsLoading(state, value) {
    state.externalCasesIsLoading = value
  },
  setEncryptedVerificationCode(state, value) {
    state.encryptedVerificationCode = value
  },
  setExternalCasesError(state, value) {
    state.externalCasesError = value
  },
}

const actions: ActionTree<ExternalCaseState, RootState> = {
  async sendVerificationCode({ commit }, payload) {
    commit('setVerificationCodeIsLoading', true)
    commit('setExternalUserAuthError', false)
    commit('setNoExternalCasesShared', false)
    commit('setShareQueryString', payload.emailVerificationCode)
    return repository.ExternalUserCase.SendVerificationCode(payload)
      .then((res) => {
        if (res.data.externalUserAccountLocked) {
          //account is locked, hide all buttons
          //res.data.messageLocalizable
          commit('setExternalUserIsLocked', true)
        } else {
          commit(
            'setEncryptedVerificationCode',
            res.data.encryptedVerificationCode
          )
        }
      })
      .catch(() => {
        commit('setNoExternalCasesShared', true)
      })
      .finally(() => {
        commit('setVerificationCodeIsLoading', false)
      })
  },
  async authenticateExternalUser({ commit }, payload) {
    commit('setSingleFactorAuthIsLoading', true)
    commit('setExternalUserIsLoggedIn', false)
    commit('setExternalUserAuthError', false)
    return repository.ExternalUserCase.Authenticate(payload)
      .then((res) => {
        if (res.data.externalUserAccountLocked) {
          // account is locked, hide all buttons
          //res.data.messageLocalizable
          commit('setExternalUserIsLocked', true)
        } else {
          commit('setExternalUserIsLoggedIn', true)
        }
      })
      .catch((err) => {
        commit('setExternalUserAuthError', true)
        commit(
          'setExternalUserAuthErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setSingleFactorAuthIsLoading', false)
      })
  },
  async getSharedCases({ commit }) {
    commit('setExternalCasesIsLoading', true)
    commit('setExternalCasesError', false)
    return repository.ExternalUserCase.GetSharedCases()
      .then((res) => {
        commit('setExternalCases', res.data)
        commit('setExternalUserIsLoggedIn', true)
      })
      .catch(() => {
        commit('setExternalCasesError', true)
        commit('setExternalCases', [])
        commit('setExternalUserIsLoggedIn', false)
      })
      .finally(() => {
        commit('setExternalCasesIsLoading', false)
      })
  },
  clearSharedCases({ commit }) {
    commit('setExternalCases', [])
    commit('setExternalUserIsLoggedIn', false)
  },
  async logout({ dispatch }) {
    dispatch('clearSharedCases')
    return repository.ExternalUserCase.Logout({})
  },
}

const ExternalCases: Module<ExternalCaseState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default ExternalCases

interface ExternalCaseState {
  verificationCodeIsLoading: boolean
  verificationCode: string
  singleFactorAuthIsLoading: boolean
  singleFactorAuthError: boolean
  externalUserAuthError: boolean
  externalUserAuthErrorMessage: string
  externalUserIsLoggedIn: boolean
  externalCases: ExternalCase[]
  externalCasesIsLoading: boolean
  encryptedVerificationCode: string
  externalCasesError: boolean
  externalUserIsLocked: boolean
  noExternalCasesShared: boolean
  shareQueryString: string
}

interface ExternalCase {
  caseId: number
  isDownloadable: boolean
}
