import repository from '@/repository'

const getDefaultState = () => {
  return {
    locationDeviceTags: [],
    locationDeviceTagsIsLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  locationDeviceTags: (state) => {
    return state.locationDeviceTags
  },
}

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setLocationDeviceTags(state, value) {
    state.locationDeviceTags = value
  },
  setLocationDeviceTagsIsLoading(state, value) {
    state.locationDeviceTagsIsLoading = value
  },
}

const actions = {
  getLocationDeviceTags({ commit }, payload) {
    commit('setLocationDeviceTagsIsLoading', true)
    return repository.Device.GetLocationDeviceTags(payload)
      .then((res) => {
        commit('setLocationDeviceTags', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setLocationDeviceTagsIsLoading', false)
      })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
