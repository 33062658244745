
import { defineComponent } from 'vue'
import AccountMenu from './../AccountMenu.vue'
import NotificationsIcon from '../notifications/NotificationsIcon.vue'
import {
  DASHBOARD_NAV_CLICK,
  LOCATIONS_NAV_CLICK,
  LIBRARY_NAV_CLICK,
  ADMIN_NAV_CLICK,
} from '@/constants/mixpanelEvents'

export default defineComponent({
  name: 'CustomerUserMobileNav',
  props: {
    showAdmin: {
      type: Boolean,
      required: true,
    },
    showCaseLibrary: {
      type: Boolean,
      required: true,
    },
    navTabsOptional: {
      type: Boolean,
      required: true,
    },
    hasMultiView: {
      type: Boolean,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    queryString: {
      type: String,
      required: false,
    },
  },
  components: {
    AccountMenu,
    NotificationsIcon,
  },
  computed: {
    sharedCasesLink() {
      return { name: 'SharedCases', query: { h: this.queryString } }
    },
  },
  methods: {
    routeToDashboard() {
      this.$router.push('/dashboard')
      this.$mixpanelUserTrack(DASHBOARD_NAV_CLICK)
    },
    clickTrack(navItem) {
      if (navItem === 'dashboard') {
        this.$mixpanelUserTrack(DASHBOARD_NAV_CLICK)
      } else if (navItem === 'locations') {
        this.$mixpanelUserTrack(LOCATIONS_NAV_CLICK)
      } else if (navItem === 'library') {
        this.$mixpanelUserTrack(LIBRARY_NAV_CLICK)
      } else if (navItem === 'admin') {
        this.$mixpanelUserTrack(ADMIN_NAV_CLICK)
      }
    },
  },
})
